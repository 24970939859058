import React, { useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import GlobalFilter from "./GlobalFilter";
import GlobalFilter2 from "./GlobalFilter2";
import GlobalFilter3 from "./GlobalFilter3";
import GlobalFilter4 from "./GlobalFilter4";
import Avatar from "./Avatar";
import { useCustomPagination, DOTS } from "../Diagrams/useCustomPagination";
import { useAuth } from "../../contexts/AuthContext";
import ImageAvatar from "./ImageAvatar";

const DataTable = ({
  columns,
  data,
  addButtonLink,
  addButtonLabel,
  GlobalFilterNumber,
  backButtonLink,
  photoUrl,
  manageLabel,
  manageLink,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable({ columns, data }, useGlobalFilter, usePagination, useRowSelect);

  const { isDarkmode } = useAuth();
  const { globalFilter } = state;
  const { pageIndex } = state;
  const paginationRange = useCustomPagination({
    totalPageCount: pageCount,
    currentPage: pageIndex,
  });

  useEffect(() => {
    setPageSize(8);
  }, [setPageSize]);

  return (
    <div className="mt-2 flex flex-col">
      {GlobalFilterNumber === 2 ? (
        <GlobalFilter2
          addButtonLink={addButtonLink}
          backButtonLink={backButtonLink}
          addButtonLabel={addButtonLabel}
        />
      ) : GlobalFilterNumber === 3 ? (
        <GlobalFilter3
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          addButtonLink={addButtonLink}
          addButtonLabel={addButtonLabel}
          manageLabel={manageLabel}
          manageLink={manageLink}
        />
      ) : GlobalFilterNumber === 4 ? (
        <GlobalFilter4
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      ) : (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          addButtonLink={addButtonLink}
          addButtonLabel={addButtonLabel}
        />
      )}

      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden sm:rounded-lg">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200 "
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={`px-6 py-3 text-left text-xs uppercase tracking-wider ${
                          isDarkmode
                            ? "bg-gray-700 text-white"
                            : "bg-gray-50 text-gray-600"
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {data.length === 0 ? (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className={`text-center py-4 ${
                        isDarkmode ? "text-white" : "text-black"
                      }`}
                    >
                      No data to show.
                    </td>
                  </tr>
                ) : (
                  // Render table rows
                  page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={`${
                          rowIndex % 2 === 0
                            ? isDarkmode
                              ? "bg-gray-600"
                              : "bg-gray-100"
                            : isDarkmode
                            ? "bg-gray-700"
                            : "bg-white"
                        } ${
                          isDarkmode ? "hover:bg-gray-800" : "hover:bg-gray-200"
                        } transition-all cursor-pointer`}
                      >
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            className={`${
                              isDarkmode
                                ? "text-white border-gray-900"
                                : "text-black border-gray-200"
                            } px-6 py-4 whitespace-nowrap border-b `}
                          >
                            {cell.column.id === "profile" ? (
                              <Avatar imgAccessor="photopath" row={row} />
                            ) : cell.column.id === "address" &&
                              cell.value === null ? (
                              <i>null</i>
                            ) : cell.column.id === "student_id" &&
                              cell.value === null ? (
                              <i>null</i>
                            ) : cell.column.id === "photopath" ? (
                              <ImageAvatar
                                imgAccessor="photopath"
                                row={row}
                                photoUrl={photoUrl}
                              />
                            ) : (
                              cell.render("Cell")
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="py-3 flex items-center text-center justify-center pt-10 mx-10">
              <div className="flex-1 flex justify-between">
                <button
                  onClick={() => previousPage()}
                  className={`font-semibold px-4 py-2 rounded-md ${
                    isDarkmode
                      ? "bg-gray-700 hover:bg-gray-800 text-white disabled:bg-gray-600 "
                      : "bg-gray-400 hover:bg-gray-500 text-white disabled:bg-gray-300 "
                  }`}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
              </div>
              <div
                className="sm:flex-1 sm:flex sm:items-center -ml-64 space-x-2"
                aria-label="Pagination"
              >
                {paginationRange?.map((pageNumber, index) => (
                  <div key={index}>
                    {pageNumber === DOTS ? (
                      <span
                        className={`text-gray-700 text-lg ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        ...
                      </span>
                    ) : (
                      <div
                        className={` px-3 py-2 cursor-pointer rounded-md transition duration-300 ${
                          pageNumber - 1 === pageIndex
                            ? isDarkmode
                              ? "bg-gray-700 text-white hover:bg-gray-700"
                              : "bg-gray-400 text-black hover:bg-gray-400"
                            : isDarkmode
                            ? "bg-gray-600 text-white hover:bg-gray-700 "
                            : "bg-gray-300 text-gray-700 hover:bg-gray-400 "
                        }`}
                        onClick={() => gotoPage(pageNumber - 1)}
                      >
                        {pageNumber}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className={`font-semibold px-4 py-2 rounded-md ${
                  isDarkmode
                    ? "bg-gray-700 hover:bg-gray-800 text-white disabled:bg-gray-600 "
                    : "bg-gray-400 hover:bg-gray-500 text-white disabled:bg-gray-300 "
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
