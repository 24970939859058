// GlobalFilter.js
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useAsyncDebounce } from "react-table";
import { useNavigate } from "react-router-dom";

const GlobalFilter = ({
  addButtonLabel,
  addButtonLink,
  globalFilter,
  setGlobalFilter,
  placeholder = "Search...",
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const { isDarkmode } = useAuth();

  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate(`${addButtonLink}`);
  };
  return (
    <div className="mt-1 mb-3 mx-1 flex justify-between items-center">
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onFocus={() => setGlobalFilter(globalFilter || undefined)}
        className={`w-1/3 rounded-md border p-2 focus:outline-none focus:ring focus:border-blue-500 ${
          isDarkmode
            ? "text-white placeholder-white bg-gray-700 border-gray-700"
            : "text-gray-700 placeholder-gray-500"
        }`}
        type="search"
        placeholder={placeholder}
      />

      <button
        onClick={handleAddClick}
        className={`px-4 py-2 rounded-lg shadow-md ${
          isDarkmode
            ? "bg-gray-700 text-white hover:bg-gray-900"
            : "bg-primary text-white hover:bg-blue-800"
        } `}
      >
        {addButtonLabel}
      </button>
    </div>
  );
};

export default GlobalFilter;
