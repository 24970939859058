import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logoImg from "../images/logo.png";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { useToast } from "../contexts/ToastContext";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { useAuth } from "../contexts/AuthContext";

const ForgotPasswordCodePage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { resetEmail } = useAuth();

  const initialValues = {
    otp: "",
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("Please enter OTTP code"),
  });

  const handleSubmit = async (values) => {
    try {
      await axios.post(`verify-password-otp`, {
        otp: values.otp,
        email: resetEmail,
      });

      toast.open(<SuccessToast successMsg={`OTTP is match`} />, null, 7000);

      navigate("/reset-password");
    } catch (error) {
      console.error("Error:", error);
      toast.open(<ErrorToast errorMsg={"OTTP does not match"} />, null, 7000);
    }
  };

  const handleGotoHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center mb-8">
          <img
            src={logoImg}
            alt="Logo"
            className="w-48 mx-auto mb-6"
            onClick={handleGotoHome}
          />
          <h2 className="text-3xl font-extrabold text-gray-800">
            Enter OTTP Code
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Please enter the OTTP code sent to your email.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="mt-8 space-y-6">
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm font-semibold pb-1 text-gray-700"
                >
                  OTTP Code
                </label>
                <Field
                  type="number"
                  id="otp"
                  name="otp"
                  placeholder="Enter OTP Code"
                  autoFocus
                  className="px-4 py-2 w-full transition duration-300 border bg-gray-100 border-gray-300 rounded focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <ErrorMessage
                  name="otp"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-3 py-2 text-white bg-primary hover:bg-primary-dark rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark"
                >
                  Submit OTTP
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPasswordCodePage;
