import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../contexts/AuthContext";
import axios from "../../api/axios";
import { useDropzone } from "react-dropzone";
import { AiOutlineDownload, AiOutlineFilePdf } from "react-icons/ai";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import SuccessToast from "../ToastMsg/SuccessToast";
import { useToast } from "../../contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../ToastMsg/ErrorToast";

const UpdateResourseForm1 = ({
  courseId,
  isDarkmode,
  resourceData,
  subjectId,
  resourceId,
}) => {
  const [dropFile, setDropFile] = useState(null);
  const [fileViewerVisible, setFileViewerVisible] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
    subject_id: Yup.number().required("Subject is required"),
  });

  const { accessToken } = useAuth();

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("priority", values.priority);
      formData.append("subject_id", values.subject_id);
      // Check if an image file is selected
      if (dropFile !== null && dropFile !== "") {
        formData.append("filepath", dropFile);
      }

      const response = await axios.post(`update-pdf/${resourceId}`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success, e.g., show a success toast or navigate
      const newResource = response.data;

      navigate(`/courses/${courseId}/subjects/${subjectId}/resources`);

      toast.open(
        <SuccessToast
          successMsg={`Resource updated successfully!`}
          id={newResource.id}
        />,
        newResource.id,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error("Error :", error.message || error);
      console.error("Server response:", error.response?.data); // Log the server response

      toast.open(<ErrorToast successMsg={error.message} />, 5000);
    }
  };

  const handleFileViewer = () => {
    setFileViewerVisible(true);
  };

  const handleFileViewerClose = () => {
    setFileViewerVisible(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.type === "application/pdf") {
        setDropFile(file);
      }
    },
  });

  return (
    <>
      <Formik
        initialValues={{
          name: resourceData.name,
          priority: resourceData.priority,
          subject_id: resourceData.subject_id,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="mt-4">
          <div className="mb-4">
            <label
              htmlFor="subject_id"
              className={`hidden text-sm font-semibold ${
                isDarkmode ? "text-white" : "text-gray-700"
              }`}
            >
              Subject Id
            </label>
            <Field
              type="number"
              id="subject_id"
              name="subject_id"
              className={`mt-1 p-2 hidden w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                isDarkmode
                  ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                  : "border-gray-300 bg-white text-gray-700"
              }`}
              disabled
            />
            <ErrorMessage
              name="subject_id"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="priority"
              className={`block text-sm font-semibold ${
                isDarkmode ? "text-white" : "text-gray-700"
              }`}
            >
              Priority
            </label>
            <Field
              type="number"
              id="priority"
              name="priority"
              className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                isDarkmode
                  ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                  : "border-gray-300 bg-white text-gray-700"
              }`}
            />
            <ErrorMessage
              name="priority"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="name"
              className={`block text-sm font-semibold ${
                isDarkmode ? "text-white" : "text-gray-700"
              }`}
            >
              Resource Name
            </label>
            <Field
              type="text"
              id="name"
              name="name"
              className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                isDarkmode
                  ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                  : "border-gray-300 bg-white text-gray-700"
              }`}
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <Modal
              open={fileViewerVisible}
              onCancel={handleFileViewerClose}
              footer={null}
              width="80%"
              centered
              destroyOnClose
            >
              {dropFile && dropFile.type === "application/pdf" && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer fileUrl={URL.createObjectURL(dropFile)} />
                </Worker>
              )}
            </Modal>
            <div
              className={`mb-4 border-2 border-dashed border-gray-300 rounded-md p-8 text-center cursor-pointer transition duration-300 ${
                isDragActive && "border-green-500"
              }`}
            >
              <div
                {...getRootProps()}
                className="flex flex-col items-center justify-center"
              >
                <label
                  className={`block text-sm font-semibold mb-2 ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Drag & Drop PDF
                </label>
                <input {...getInputProps()} />
                <p
                  className={`block text-sm font-semibold mb-2 ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  {isDragActive
                    ? "Drop the PDF file here ..."
                    : "Drag drop a PDF here, or click to select a file"}
                </p>
                <AiOutlineDownload
                  size={64}
                  className={`mt-4 text-2xl ${
                    isDarkmode ? "text-white" : " text-primary "
                  }`}
                />
              </div>
            </div>
            {dropFile && (
              <div className="mb-4">
                <label
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Selected PDF
                </label>
                <div className="w-32 h-32 overflow-hidden rounded-md relative group">
                  <div
                    className={`w-48 h-48 flex items-center justify-center  rounded-md ${
                      isDarkmode ? "bg-gray-700" : "bg-gray-300"
                    }`}
                  >
                    <AiOutlineFilePdf
                      className={`${
                        isDarkmode ? "text-white" : "text-primary"
                      } text-4xl`}
                    />
                  </div>
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <EyeOutlined
                      className={`text-2xl cursor-pointer hover:scale-125 rounded-full px-1  text-primary`}
                      onClick={handleFileViewer}
                    />
                    <DeleteOutlined
                      className="text-secondary text-2xl cursor-pointer hover:scale-125"
                      onClick={() => setDropFile(null)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className={`px-4 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              }`}
            >
              Update
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default UpdateResourseForm1;
