// GlobalFilter.js
import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { FaLongArrowAltLeft } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

const GlobalFilter = ({ addButtonLabel, addButtonLink, backButtonLink }) => {
  const { isDarkmode } = useAuth();

  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate(`${addButtonLink}`);
  };

  const handleBackClick = () => {
    navigate(`${backButtonLink}`);
  };
  return (
    <div className="mt-1 mb-3 mx-1 flex justify-between items-center">
      <button
        onClick={handleBackClick}
        className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
          isDarkmode
            ? "bg-gray-700 text-white hover:bg-gray-900"
            : "bg-primary text-white hover:bg-blue-800"
        } `}
      >
        <FaLongArrowAltLeft />
        Back
      </button>

      <button
        onClick={handleAddClick}
        className={`px-4 py-2 rounded-lg shadow-md ${
          isDarkmode
            ? "bg-gray-700 text-white hover:bg-gray-900"
            : "bg-primary text-white hover:bg-blue-800"
        } `}
      >
        {addButtonLabel}
      </button>
    </div>
  );
};

export default GlobalFilter;
