import React from "react";

const ClockOutModal = ({ formik, showEarlyExitForm, closeEarlyExitForm }) => {
  return (
    <div className="fixed z-50 left-0 top-0 right-0 bottom-0 bg-opacity-50 backdrop-blur-sm bg-gray-700">
      <div className="flex h-full justify-center items-center">
        <div className="bg-white p-4 rounded-lg">
          <form
            onSubmit={formik.handleSubmit}
            className={`early-exit-form ${
              showEarlyExitForm ? "visible" : "hidden"
            }`}
          >
            <div>
              <label htmlFor="early_clock_out_reason">
                Early Clock-Out Reason:
              </label>
              <textarea
                type="text"
                id="early_clock_out_reason"
                name="early_clock_out_reason"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.late_clock_in_reason}
                className="w-full p-2 border rounded h-32"
              />
              {formik.touched.early_clock_out_reason &&
              formik.errors.early_clock_out_reason ? (
                <div className="text-red-500">
                  {formik.errors.early_clock_out_reason}
                </div>
              ) : null}

              <div className="mt-4 flex justify-between">
                <button
                  type="submit"
                  className="bg-primary text-white py-2 px-4 rounded hover:bg-blue-800 focus:outline-none focus:ring focus:border-blue-300"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={closeEarlyExitForm}
                  className="bg-secondary text-white py-2 px-4 rounded hover:bg-red-800 focus:outline-none focus:ring focus:border-gray-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClockOutModal;
