// UpdateResourcePage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import UpdateResourseForm1 from "../../components/Form/UpdateResourseForm1";
import UpdateResourseForm2 from "../../components/Form/UpdateResourseForm2";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateResourcePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const { courseId, subjectId, id } = useParams();
  const [resourceData, setResourceData] = useState({});

  useEffect(() => {
    const fetchResourceData = async () => {
      try {
        const response = await axios.get(`/resources/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setResourceData(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching score data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchResourceData();
  }, [accessToken, id]);

  const handleSubmit2 = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("priority", values.priority);
      formData.append("subject_id", values.subject_id);
      formData.append("filepath", values.filepath);

      const response = await axios.post(`update-video/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success, e.g., show a success toast or navigate
      const newResource = response.data;
      toast.open(
        <SuccessToast
          successMsg={`Resource updated successfully! `}
          id={newResource.id}
        />,
        newResource.id,
        7000
      );

      navigate(`/courses/${courseId}/subjects/${subjectId}/resources/`);
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error("Error :", error.message || error);
      console.error("Server response:", error.response?.data); // Log the server response

      toast.open(<ErrorToast successMsg={error.message} />, 5000);
    }
  };

  const handleBack = () => {
    navigate(`/courses/${courseId}/subjects/${subjectId}/resources/`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-800 text-white" : "bg-gray-100"
          }`}
        >
          {resourceData !== "" && !loading ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Update Resource
                </h2>

                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>

              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />

              {resourceData.filetype === "pdf" && (
                <UpdateResourseForm1
                  resourceData={resourceData}
                  courseId={courseId}
                  subjectId={subjectId}
                  isDarkmode={isDarkmode}
                  accessToken={accessToken}
                  resourceId={id}
                />
              )}

              {resourceData.filetype === "video" && (
                <UpdateResourseForm2
                  resourceData={resourceData}
                  onSubmit={handleSubmit2}
                  resourceId={id}
                  isDarkmode={isDarkmode}
                  subjectId={subjectId}
                />
              )}
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateResourcePage;
