import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useAsyncDebounce } from "react-table";

const GlobalFilter4 = ({
  globalFilter,
  setGlobalFilter,
  placeholder = "Search...",
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const { isDarkmode } = useAuth();

  return (
    <div className="mt-1 mb-3 mx-1 flex justify-between items-center">
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onFocus={() => setGlobalFilter(globalFilter || undefined)}
        className={`w-1/3 rounded-md border p-2 focus:outline-none focus:ring focus:border-blue-500 ${
          isDarkmode
            ? "text-white placeholder-white bg-gray-700 border-gray-700"
            : "text-gray-700 placeholder-gray-500"
        }`}
        type="search"
        placeholder={placeholder}
      />
    </div>
  );
};

export default GlobalFilter4;
