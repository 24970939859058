import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { FaFileAlt, FaLongArrowAltLeft } from "react-icons/fa";
import { EyeOutlined } from "@ant-design/icons";
import CustomStudentDocViewer from "../../components/Modal/CustomStudentDocViewer";
import { FcApproval, FcCancel } from "react-icons/fc";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import { IoCheckmarkDone, IoCloseSharp } from "react-icons/io5";
import PageLoading from "../../components/Diagrams/PageLoading";

const StudentDetailsPage = () => {
  const { studentId } = useParams();
  const [studentDetails, setStudentDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { isDarkmode, accessToken } = useAuth();
  const [fileViewerVisible, setFileViewerVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const navigate = useNavigate();
  const toast = useToast();
  const [documentId, setDocumentId] = useState(null);
  const [docStatus, setDocStatus] = useState(null);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        if (!studentId) {
          console.error("Student ID is not defined.");
          return;
        }

        const response = await axios.get(`/admin-students/${studentId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        console.log(response.data);
        setStudentDetails(responseData.data);
        setDocStatus(responseData.data.document_status);
      } catch (error) {
        console.error(
          "Error fetching student details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [studentId, accessToken]);

  const handleBack = () => {
    navigate(`/students`);
  };

  const handleFileViewer = (documentInfo) => {
    setSelectedFile(documentInfo);
    setDocumentId(documentInfo.document_actual_id);
    console.log(documentInfo.document_actual_id);
    setFileViewerVisible(true);
  };

  const handleFileViewerClose = () => {
    setFileViewerVisible(false);
  };

  const handleRequestedToAdmin = async () => {
    try {
      // Make an API request to update the status
      await axios.get(
        `/staff-student-document/request-to-approvel/${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setDocStatus("Requested");

      toast.open(
        <SuccessToast successMsg={`Requested for approval.`} id={studentId} />,
        studentId,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(`Error  :`, error.message || error.response?.data || error);

      // Show an error message
      toast.open(
        <ErrorToast
          errorMsg={error.message ? error.message : `Error`}
          id={studentId}
        />,
        studentId,
        7000
      );
    }
  };

  const handleCancelRequest = async () => {
    try {
      // Make an API request to update the status
      await axios.get(
        `/staff-student-document/cancel-request-to-approvel/${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setDocStatus("Pending");
      toast.open(
        <SuccessToast successMsg={`Cancel Request.`} id={studentId} />,
        studentId,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(`Error  :`, error.message || error.response?.data || error);

      // Show an error message
      toast.open(
        <ErrorToast
          errorMsg={error.message ? error.message : `Error`}
          id={studentId}
        />,
        studentId,
        7000
      );
    }
  };

  const updateDocumentStatus = (documentId, status) => {
    // Find the document in studentDetails and update its status
    setStudentDetails((prevDetails) => {
      const updatedDocuments = prevDetails.document.map((doc) => {
        if (doc.document_actual_id === documentId) {
          return { ...doc, status };
        }
        return doc;
      });
      return { ...prevDetails, document: updatedDocuments };
    });
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">Student</h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />

          {loading ? (
            <PageLoading />
          ) : (
            <>
              <table className="w-full border-collapse border border-gray-300 my-5">
                <thead>
                  <tr>
                    <th
                      colspan="4"
                      className={`p-4 text-center font-bold border border-gray-300 ${
                        isDarkmode ? "bg-gray-700" : "bg-gray-200"
                      }`}
                    >
                      BASIC INFORMATION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="6" className="p-4 border border-gray-300">
                      {studentDetails.photopath ? (
                        <img
                          src={`https://bitmap.bpnpdl.me/image/user/${studentDetails.photopath}`}
                          alt="Student Profile"
                          className="rounded-full h-48 w-48 mx-auto"
                        />
                      ) : (
                        <p className="text-white p-4">
                          No profile photo available
                        </p>
                      )}
                    </td>
                    <td
                      className="p-4 font-bold border border-gray-300"
                      colSpan="2"
                    >
                      FULL NAME
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      STUDENT REG. NO.
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 border border-gray-300" colSpan="2">
                      {studentDetails
                        ? studentDetails.first_name +
                          " " +
                          (studentDetails.middle_name === null
                            ? ""
                            : studentDetails.middle_name + " ") +
                          studentDetails.last_name
                        : "Null"}
                    </td>

                    <td className="p-4 border border-gray-300">
                      {studentDetails.student_id
                        ? studentDetails.student_id
                        : "Null"}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 font-bold border border-gray-300">
                      Role
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Gender
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Email
                    </td>
                  </tr>

                  <tr>
                    <td className="p-4 border border-gray-300">Student</td>
                    <td className="p-4 border border-gray-300">
                      {studentDetails.gender ? studentDetails.gender : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300">
                      {studentDetails ? studentDetails.email : "Null"}
                    </td>
                  </tr>

                  <tr>
                    <td className="p-4 font-bold border border-gray-300">
                      DOB
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Address
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Phone
                    </td>
                  </tr>

                  <tr>
                    <td className="p-4 border border-gray-300">
                      {studentDetails.dob ? studentDetails.dob : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300">
                      {studentDetails.address ? studentDetails.address : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300">
                      {studentDetails.phone ? studentDetails.phone : "Null"}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th
                      colspan="6"
                      className={`p-4 text-center font-bold border border-gray-300 ${
                        isDarkmode ? "bg-gray-700" : "bg-gray-200"
                      }`}
                    >
                      ADDITIONAL INFORMATION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-4 font-bold border border-gray-300">
                      Father's Name
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Mother's Name
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Interested Country
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Instrested Course
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.father_name
                        ? studentDetails.father_name
                        : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.mother_name
                        ? studentDetails.mother_name
                        : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.interested_country
                        ? studentDetails.interested_country
                        : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.interested_course
                        ? studentDetails.interested_course
                        : "Null"}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 font-bold border border-gray-300">
                      Secondary Address
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Secondary Phone
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Qualification
                    </td>
                    <td className="p-4 font-bold border border-gray-300">
                      Reference
                    </td>
                  </tr>
                  <tr>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.secondary_address
                        ? studentDetails.secondary_address
                        : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.secondary_phone
                        ? studentDetails.secondary_phone
                        : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.qualification
                        ? studentDetails.qualification
                        : "Null"}
                    </td>
                    <td className="p-4 border border-gray-300 ">
                      {studentDetails.reference
                        ? studentDetails.reference
                        : "Null"}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th
                      colSpan="6"
                      className={`p-4 text-center font-bold border border-gray-300 ${
                        isDarkmode ? "bg-gray-700" : "bg-gray-200"
                      }`}
                    >
                      STUDENT DOCUMENT
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="flex flex-wrap gap-4 mt-2">
                {studentDetails.document &&
                  Object.values(studentDetails.document).map(
                    (documentInfo, index) => {
                      const isImage =
                        documentInfo.filepath &&
                        /\.(jpeg|jpg|png|gif)$/i.test(documentInfo.filepath);

                      const imageName = documentInfo.name.replace(/_/g, " ");

                      return (
                        <div
                          key={index}
                          className="max-w-48 max-h-48 overflow-hidden rounded-md relative group"
                        >
                          <span className="text-sm">
                            {imageName}
                            {documentInfo.status === "Approved" && (
                              <FcApproval
                                size={25}
                                className={`absolute cursor-pointer rounded-full px-1`}
                              />
                            )}
                            {documentInfo.status === "Rejected" && (
                              <FcCancel
                                size={25}
                                className={`absolute cursor-pointer rounded-full px-1`}
                              />
                            )}
                          </span>
                          {isImage ? (
                            <img
                              src={`https://bitmap.pramodchhetri.com.np/studentdocument/${documentInfo.filepath}`}
                              className="w-full h-full object-cover"
                              alt={`Document ${index}`}
                              onClick={() => handleFileViewer(documentInfo)}
                            />
                          ) : (
                            <div className="w-48 h-48 flex items-center justify-center bg-gray-200 rounded-md">
                              <FaFileAlt className="text-gray-600 text-4xl" />
                            </div>
                          )}
                          <div className="absolute gap-x-2 inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <EyeOutlined
                              className={` text-2xl cursor-pointer hover:scale-125 rounded-full px-1  ${
                                isDarkmode
                                  ? "text-white bg-gray-700 "
                                  : "text-white bg-primary"
                              }`}
                              onClick={() => handleFileViewer(documentInfo)}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </>
          )}

          {(docStatus === "Pending" || docStatus === "Rejected") && (
            <button
              className="flex items-center bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 my-4 rounded-md focus:outline-none focus:shadow-outline mr-2"
              onClick={handleRequestedToAdmin}
            >
              <IoCheckmarkDone size={25} /> Request to Approve
            </button>
          )}

          {docStatus === "Requested" && (
            <button
              className=" flex items-center  bg-secondary hover:bg-red-800 text-white font-bold py-2 px-4 my-4 rounded-md focus:outline-none focus:shadow-outline"
              onClick={handleCancelRequest}
            >
              <IoCloseSharp size={25} /> Cancel Request
            </button>
          )}
        </div>
      </section>

      <CustomStudentDocViewer
        visible={fileViewerVisible}
        onClose={handleFileViewerClose}
        documentId={documentId}
        fileUrl={`https://bitmap.pramodchhetri.com.np/studentdocument/${selectedFile.filepath}`}
        fileType={
          /\.(jpeg|jpg|png|gif)$/i.test(selectedFile.filepath) ? "image" : "pdf"
        }
        onUpdateStatus={updateDocumentStatus}
      />
    </div>
  );
};

export default StudentDetailsPage;
