// CreateNotificationPage.js
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import AllUserNotificationForm from "../../components/Form/AllUserNotificationForm";
import AllStaffNotificationForm from "../../components/Form/AllStaffNotificationForm";
import AllStudentNotificationForn from "../../components/Form/AllStudentNotificationForn";

const CreateNotificationPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();

  const [formNumber, setFormNumber] = useState(1);

  const toggleFormNumber = (num) => {
    setFormNumber(num);
  };

  const handleBack = () => {
    navigate(`/notification`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-800 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Send Custom Notification for{" "}
              {formNumber === 1
                ? "All Users"
                : formNumber === 2
                ? "All Staffs"
                : "All Students"}
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />

          <div className="flex  mx-2 my-2 gap-x-2">
            <button
              onClick={() => toggleFormNumber(1)}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                formNumber === 1
                  ? " text-white bg-blue-800"
                  : "bg-primary text-white hover:bg-blue-800"
              }  `}
            >
              All Users
            </button>
            <button
              onClick={() => toggleFormNumber(2)}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                formNumber === 2
                  ? " text-white bg-blue-800"
                  : "bg-primary text-white hover:bg-blue-800"
              }  `}
            >
              All Staffs
            </button>
            <button
              onClick={() => toggleFormNumber(3)}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                formNumber === 3
                  ? " text-white bg-blue-800"
                  : "bg-primary text-white hover:bg-blue-800"
              }  `}
            >
              All Students
            </button>
          </div>

          {formNumber === 1 && (
            <AllUserNotificationForm
              isDarkmode={isDarkmode}
              accessToken={accessToken}
            />
          )}

          {formNumber === 2 && (
            <AllStaffNotificationForm
              isDarkmode={isDarkmode}
              accessToken={accessToken}
            />
          )}

          {formNumber === 3 && (
            <AllStudentNotificationForn
              isDarkmode={isDarkmode}
              accessToken={accessToken}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default CreateNotificationPage;
