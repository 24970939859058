import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaUser,
  FaImages,
  FaBuilding,
  FaNotesMedical,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import { ImAddressBook } from "react-icons/im";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { SiGoogleclassroom } from "react-icons/si";
import { BiSolidFoodMenu } from "react-icons/bi";
import {
  MdDashboard,
  MdNoteAlt,
  MdDateRange,
  MdOutlinePayment,
  MdFindInPage,
} from "react-icons/md";
import { SlEnvolopeLetter } from "react-icons/sl";
import { PiStudentFill } from "react-icons/pi";
import logoImg from "../../images/logo.png";
import { useAuth } from "../../contexts/AuthContext";
import NavItem from "../Navbar/AdminNavItem";
import axios from "../../api/axios";
import { BsThreeDotsVertical, BsCardImage } from "react-icons/bs";
import DefaultUser from "../../images/DefaultUser.jpg";
import Popup from "reactjs-popup";
import { GrScorecard } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import { MdDomainVerification } from "react-icons/md";

const AdminSidebar = () => {
  const {
    accessToken,
    isDarkmode,
    expanded,
    toggleExpanded,
    logout,
    userRole,
  } = useAuth();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/profile", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setUserData(response.data.data);
      } catch (error) {
        console.error(
          "Error fetching user data:",
          error.message || error.response?.data || error
        );
      }
    };

    if (accessToken) {
      fetchUserData();
    }
  }, [accessToken]);

  const handleSignOut = async () => {
    try {
      const response = await axios.post(
        "/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      console.log(responseData);

      logout();

      toast.open(
        <SuccessToast successMsg={`Logout successfully.`} id={userData.id} />,
        userData.id,
        7000
      );

      navigate("/");
    } catch (error) {
      // Log the error message for debugging
      console.error(error.message || error.response?.data || error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Use the error message from the Laravel response
        toast.open(
          <ErrorToast
            successMsg={error.response.data.message}
            id={userData.id}
          />,
          userData.id,
          7000
        );
      } else {
        toast.open(
          <ErrorToast
            errorMsg={"An unexpected error occurred. Please try again later."}
            id={userData.id}
          />,
          userData.id,
          7000
        );
      }
    }
  };

  const handleGotoProfile = () => {
    navigate("/profile");
  };

  return (
    <aside
      className={` ${
        expanded ? `lg:w-72 xl:w-72 2xl:w-72` : ``
      } transition-all ${
        expanded ? "fixed w-full z-10 min-h-full " : "w-16 min-h-screen"
      } lg:static`}
    >
      <nav
        className={`min-h-full flex flex-col relative border-r shadow-sm ${
          isDarkmode ? "bg-gray-700 border-gray-700 text-white" : "bg-white"
        }`}
      >
        {expanded && (
          <FaArrowLeft
            size={34}
            onClick={toggleExpanded}
            className={` absolute top-0 right-0 mt-4 mr-4 hidden lg:inline-block px-2 py-1.5  ${
              isDarkmode ? `hover:bg-gray-900` : `hover:bg-indigo-100`
            }`}
          />
        )}

        <div
          className={`p-4 pb-2 flex justify-between items-center border-b shadow-sm ${
            isDarkmode ? "border-gray-900" : "border-gray-200"
          }`}
        >
          <img
            src={logoImg}
            className={`overflow-hidden transition-all ${
              expanded ? "w-24 lg:w-48" : "hidden"
            }`}
            alt="Logo"
          />
          <button
            onClick={toggleExpanded}
            className={`px-2 py-1.5 rounded-lg ${
              isDarkmode ? `hover:bg-gray-900` : `hover:bg-indigo-100`
            } `}
          >
            {expanded ? (
              <FaArrowLeft size={20} className="lg:hidden" />
            ) : (
              <FaArrowRight size={20} />
            )}
          </button>
        </div>

        <ul className="flex-1 px-3 r">
          <NavItem
            to="/dashboard"
            icon={MdDashboard}
            label="Dashboard"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          {userRole === "Admin" && (
            <NavItem
              to="/users"
              icon={FaUser}
              label="Staff"
              isDarkmode={isDarkmode}
              expanded={expanded}
            />
          )}
          {userRole === "Admin" && (
            <NavItem
              to="/staff-attendance-today"
              icon={ImAddressBook}
              label="Attendance"
              isDarkmode={isDarkmode}
              expanded={expanded}
            />
          )}
          {userRole === "Staff" && (
            <NavItem
              to="/staff-attendance"
              icon={ImAddressBook}
              label="Attendance"
              isDarkmode={isDarkmode}
              expanded={expanded}
            />
          )}
          {userRole === "Admin" && (
            <NavItem
              to="/admin-leave-application"
              icon={SlEnvolopeLetter}
              label="Leave Application"
              isDarkmode={isDarkmode}
              expanded={expanded}
            />
          )}
          {userRole === "Staff" && (
            <NavItem
              to="/leave-application"
              icon={SlEnvolopeLetter}
              label="Leave Application"
              isDarkmode={isDarkmode}
              expanded={expanded}
            />
          )}
          <NavItem
            to="/students"
            icon={PiStudentFill}
            label="Students"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />

          {userRole === "Admin" && (
            <NavItem
              to="/document-approval"
              icon={MdDomainVerification}
              label="Document Approval"
              isDarkmode={isDarkmode}
              expanded={expanded}
            />
          )}

          <NavItem
            icon={FaMoneyCheckAlt}
            to="/transaction"
            label="Transaction"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/gallery"
            icon={FaImages}
            label="Gallery"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/courses"
            icon={BiSolidFoodMenu}
            label="Courses"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/notice"
            icon={MdNoteAlt}
            label="Notice"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/branch"
            icon={FaBuilding}
            label="Branch"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/banner"
            icon={BsCardImage}
            label="Banner"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/score"
            icon={GrScorecard}
            label="Score"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/appointment"
            icon={FaNotesMedical}
            label="Appointment"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            to="/class-shift"
            icon={SiGoogleclassroom}
            label="Class-Shift"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            icon={LiaChalkboardTeacherSolid}
            to="/Guide"
            label="Guide"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            icon={MdDateRange}
            to="/testdate"
            label="Test Date"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            icon={MdOutlinePayment}
            to="/payment"
            label="Payment"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
          <NavItem
            icon={MdFindInPage}
            to="/tracking"
            label="Tracking"
            isDarkmode={isDarkmode}
            expanded={expanded}
          />
        </ul>

        <div
          className={`border-t shadow-sm flex p-3 ${
            isDarkmode ? "border-gray-900" : "border-gray-200"
          }`}
        >
          <div className="leading-4 flex items-center w-full">
            {userData ? (
              expanded ? (
                <>
                  <img
                    src={`https://bitmap.bpnpdl.me/image/user/${userData.photopath}`}
                    alt={`${userData.first_name}'s Avatar`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = DefaultUser;
                    }}
                    className="w-10 h-10 rounded-md hover:cursor-pointer border-2 shadow-sm border-gray-200"
                  />
                  <div
                    className={`flex justify-between items-center w-full overflow-hidden transition-all ml-3 `}
                  >
                    <div className="leading-4">
                      <h4 className="font-semibold">{`${userData.first_name} ${userData.last_name}`}</h4>
                      <span
                        className={`text-xs ${
                          isDarkmode ? "text-white" : "text-gray-600"
                        }`}
                      >
                        {userData.email}
                      </span>
                    </div>

                    <div
                      className={
                        isDarkmode ? "hover:bg-black" : "hover:bg-indigo-100"
                      }
                    >
                      <Popup
                        trigger={
                          <BsThreeDotsVertical
                            size={20}
                            className="hover:cursor-pointer"
                          />
                        }
                        position="right "
                      >
                        <div
                          id="dropdownTop"
                          className="py-1 bg-gray-500 absolute lg:left-24 md:left-[35rem] left-28 bottom-16 rounded-lg shadow w-44 h-24"
                        >
                          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            <li>
                              <span
                                onClick={handleGotoProfile}
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                              >
                                Profile
                              </span>
                            </li>
                            <li>
                              <span
                                onClick={handleSignOut}
                                className="block justify-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                              >
                                Sign out
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Popup>
                    </div>
                  </div>
                </>
              ) : (
                <Popup
                  trigger={
                    <img
                      src={`https://bitmap.bpnpdl.me/image/user/${userData.photopath}`}
                      alt={`${userData.first_name}'s Avatar`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = DefaultUser;
                      }}
                      className="w-10 h-10 rounded-md hover:cursor-pointer border-2 shadow-sm border-gray-200"
                    />
                  }
                  position="right"
                >
                  <div
                    id="dropdownTop"
                    className="py-1 bg-gray-500 absolute -bottom-7 left-1 rounded-lg shadow w-44 h-24"
                  >
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                      <li>
                        <span
                          onClick={handleGotoProfile}
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Profile
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={handleSignOut}
                          className="block justify-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Sign out
                        </span>
                      </li>
                    </ul>
                  </div>
                </Popup>
              )
            ) : (
              <div className="flex w-full ">
                <div
                  role="status"
                  className="flex items-center justify-center m-auto "
                >
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default AdminSidebar;
