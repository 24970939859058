import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";

const CreateClassPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to create a new class
      const response = await axios.post("/class/create", values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the class details page
      const newClass = response.data.data;

      navigate(`/class-shift/class`);

      toast.open(
        <SuccessToast
          successMsg={`Class with title ${newClass.title} added `}
          id={newClass.id}
        />,
        newClass.id,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error creating class:",
        error.message || error.response?.data || error
      );

      // Check if the error is a server-side error and show a custom error message
      if (error.response && error.response.status >= 500) {
        toast.open(
          <ErrorToast
            errorMsg={`Server error occurred. Please try again later.`}
          />,
          null,
          7000
        );
      } else {
        // For other types of errors, show a generic error message
        toast.open(
          <ErrorToast
            errorMsg={error.response?.data?.message || "An error occurred."}
          />,
          null,
          7000
        );
      }
    }
  };

  const handleBack = () => {
    navigate(`/class`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Add Class
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          <Formik
            initialValues={{
              title: "",
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="mt-4">
              <div className="mb-4">
                <div className="mb-4">
                  <label
                    htmlFor="title"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Title
                  </label>
                  <Field
                    type="text"
                    id="title"
                    name="title"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="description"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Description
                  </label>
                  <Field
                    as="textarea"
                    id="description"
                    name="description"
                    rows="4"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  }`}
                >
                  Add Class
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default CreateClassPage;
