// UpdateAppointmentTimeslotPage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateAppointmentTimeslotPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { timeslotId } = useParams();
  const [loading, setLoading] = useState(true);
  const [timeslot, setTimeslot] = useState("");

  useEffect(() => {
    const fetchTimeslotData = async () => {
      try {
        const response = await axios.get(`/timeslot/${timeslotId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setTimeslot(responseData.data.timeslot);
      } catch (error) {
        console.error(
          "Error fetching timeslot data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTimeslotData();
  }, [timeslotId, accessToken]);

  const validationSchema = Yup.object().shape({
    timeslot: Yup.string()
      .required("Timeslot is required")
      .matches(
        /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
        "Invalid timeslot format. Use HH:mm-HH:mm"
      ),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update the appointment timeslot
      await axios.post(`/timeslot/update/${timeslotId}`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the timeslot details page
      navigate(`/appointment/timeslot`);

      toast.open(
        <SuccessToast
          successMsg={`Timeslot updated successfully`}
          id={timeslotId}
        />,
        timeslotId,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating timeslot:",
        error.message || error.response?.data || error
      );

      // Check if the error is a server-side error and show a custom error message
      if (error.response && error.response.status >= 500) {
        toast.open(
          <ErrorToast
            errorMsg={`Server error occurred. Please try again later.`}
          />,
          null,
          7000
        );
      } else {
        // For other types of errors, show a generic error message
        toast.open(
          <ErrorToast
            errorMsg={error.response?.data?.message || "An error occurred."}
          />,
          null,
          7000
        );
      }
    }
  };

  const handleBack = () => {
    navigate(`/appointment/timeslot`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {!loading ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Update Appointment Timeslot
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  timeslot: timeslot,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="mt-4">
                  <div className="mb-4">
                    <div className="mb-4">
                      <label
                        htmlFor="timeslot"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Timeslot
                      </label>
                      <Field
                        type="text"
                        id="timeslot"
                        name="timeslot"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="timeslot"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-4 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      }`}
                    >
                      Update Appointment Timeslot
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateAppointmentTimeslotPage;
