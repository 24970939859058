import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { FaLongArrowAltLeft } from "react-icons/fa";
import PageLoading from "../../components/Diagrams/PageLoading";

const AppointmentTimeslotDetailsPage = () => {
  const { timeslotId } = useParams();
  const [timeslotDetails, setTimeslotDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTimeslotDetails = async () => {
      try {
        if (!timeslotId) {
          console.error("Timeslot ID is not defined.");
          return;
        }

        const response = await axios.get(`/timeslot/${timeslotId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setTimeslotDetails(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching timeslot details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTimeslotDetails();
  }, [timeslotId, accessToken]);

  const handleBack = () => {
    navigate(`/appointment/timeslot`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Timeslot Details
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />

          {loading ? (
            <PageLoading />
          ) : (
            <div className=" p-4 rounded-lg">
              <table className="w-full border-collapse border rounded-lg border-gray-300 mt-4">
                <tbody className="rounded-lg">
                  {Object.keys(timeslotDetails).map((key) => (
                    <tr key={key}>
                      <td className="border p-2 font-semibold">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </td>
                      <td
                        className={`w-full border p-2 ${
                          timeslotDetails[key] === null ? "italic" : ""
                        }`}
                      >
                        {timeslotDetails[key] === null
                          ? "null"
                          : timeslotDetails[key]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AppointmentTimeslotDetailsPage;
