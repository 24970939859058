// CreateResourcePage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import CreateResourceForm from "../../components/Form/CreateResourceForm";
import CreateResourceForm2 from "../../components/Form/CreateResourseForm2";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import PageLoading from "../../components/Diagrams/PageLoading";

const CreateResourcePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [formNumber, setFormNumber] = useState(1);

  const [loading, setLoading] = useState(true);
  const [countPriority, setCountPriority] = useState("");
  const { courseId, subjectId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch priority count
        const priorityResponse = await axios.get("/priority-resources", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const priorityData = await priorityResponse.data;
        const parsedCountPriority = parseInt(priorityData.data, 10);
        setCountPriority(isNaN(parsedCountPriority) ? "" : parsedCountPriority);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  const handleSubmit2 = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("priority", values.priority);
      formData.append("subject_id", values.subject_id);
      formData.append("filepath", values.filepath);

      const response = await axios.post("create-video", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success, e.g., show a success toast or navigate
      const newResource = response.data;
      console.log(newResource);
      toast.open(
        <SuccessToast successMsg={`Resource  added `} id={newResource.id} />,
        newResource.id,
        7000
      );

      navigate(`/courses/${courseId}/subjects/${subjectId}/resources/`);
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error("Error :", error.message || error);
      console.error("Server response:", error.response?.data); // Log the server response

      toast.open(<ErrorToast successMsg={error.message} />, 5000);
    }
  };

  const toggleFormNumber = () => {
    if (formNumber === 1) {
      setFormNumber(2);
    } else {
      setFormNumber(1);
    }
  };

  const handleBack = () => {
    navigate(`/courses/${courseId}/subjects/${subjectId}/resources/`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-800 text-white" : "bg-gray-100"
          }`}
        >
          {countPriority !== "" && !loading ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Add Resource
                </h2>
                <div className="flex justify-center items-center gap-x-2">
                  {formNumber === 1 && (
                    <button
                      onClick={toggleFormNumber}
                      className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      } `}
                    >
                      Add Video
                    </button>
                  )}
                  {formNumber === 2 && (
                    <button
                      onClick={toggleFormNumber}
                      className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      } `}
                    >
                      Add PDF
                    </button>
                  )}
                  <button
                    onClick={handleBack}
                    className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-primary text-white hover:bg-blue-800"
                    } `}
                  >
                    <FaLongArrowAltLeft />
                    Back
                  </button>
                </div>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />

              {formNumber === 1 && (
                <CreateResourceForm
                  courseId={courseId}
                  subjectId={subjectId}
                  isDarkmode={isDarkmode}
                  priority={countPriority}
                  accessToken={accessToken}
                />
              )}

              {formNumber === 2 && (
                <CreateResourceForm2
                  onSubmit={handleSubmit2}
                  isDarkmode={isDarkmode}
                  priority={countPriority}
                  subjectId={subjectId}
                />
              )}
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default CreateResourcePage;
