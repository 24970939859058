import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useToast } from "../../contexts/ToastContext";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateTrackingPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { trackingId } = useParams();

  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    user_id: Yup.number()
      .required("User ID is required")
      .integer("User ID must be an integer"),
    date: Yup.date().required("Date is required"),
    description: Yup.string()
      .required("Description is required")
      .max(255, "Description is too long"),
  });

  useEffect(() => {
    const fetchTrackingDetails = async () => {
      try {
        const response = await axios.get(`/tracking/${trackingId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const trackingData = response.data.data;
        setInitialValues({
          user_id: trackingData.user_id,
          date: trackingData.date,
          description: trackingData.description,
        });
      } catch (error) {
        console.error(
          "Error fetching tracking details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTrackingDetails();
  }, [trackingId, accessToken]);

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update the tracking entry
      const response = await axios.post(
        `/tracking/update/${trackingId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const updatedTrackingData = response.data.data;

      toast.open(
        <SuccessToast
          successMsg={`Tracking entry of id ${updatedTrackingData.id} Updated.`}
          id={updatedTrackingData.id}
        />,
        updatedTrackingData.id,
        7000
      );

      // Handle success, e.g., show a success toast or navigate to the tracking page
      navigate(`/tracking`);
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating tracking entry:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/tracking`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Update Tracking Entry
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          {loading ? (
            <PageLoading />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="mt-4">
                <div className="mb-4">
                  <label
                    htmlFor="user_id"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    User ID
                  </label>
                  <Field
                    type="number"
                    id="user_id"
                    name="user_id"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="user_id"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="date"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Date
                  </label>
                  <Field
                    type="date"
                    id="date"
                    name="date"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="description"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Description
                  </label>
                  <Field
                    as="textarea"
                    id="description"
                    name="description"
                    rows="4"
                    className={`mt-1 p-2 block w-full rounded-md resize-none focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className={`px-4 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-primary text-white hover:bg-blue-800"
                    }`}
                  >
                    Update Tracking Entry
                  </button>
                </div>
              </Form>
            </Formik>
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateTrackingPage;
