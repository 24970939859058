// CreateSubjectPage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import PageLoading from "../../components/Diagrams/PageLoading";

const CreateSubjectPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [countPriority, setCountPriority] = useState("");
  const { courseId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch priority count
        const priorityResponse = await axios.get("/priority-subjects", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const priorityData = await priorityResponse.data;
        const parsedCountPriority = parseInt(priorityData.data, 10);
        setCountPriority(isNaN(parsedCountPriority) ? "" : parsedCountPriority);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
    course_id: Yup.number().required("Course is required"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to create a new subject
      const response = await axios.post("/subjects/create", values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the subject details page
      const newSubject = response.data.data;

      navigate(`courses/${courseId}/subjects/`);

      toast.open(
        <SuccessToast
          successMsg={`Subject with name ${newSubject.name} added `}
          id={newSubject.id}
        />,
        newSubject.id,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error creating subject:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`courses/${courseId}/subjects/`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {countPriority !== "" && !loading ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Add Subject
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  name: "",
                  priority: countPriority,
                  course_id: courseId,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="mt-4">
                  <div className="mb-4">
                    <label
                      htmlFor="course_id"
                      className={`hidden text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Course Id
                    </label>
                    <Field
                      type="number"
                      id="course_id"
                      name="course_id"
                      className={`mt-1 p-2 hidden w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                      disabled
                    />
                    <ErrorMessage
                      name="priority"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="priority"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Priority
                    </label>
                    <Field
                      type="number"
                      id="priority"
                      name="priority"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                      disabled
                    />
                    <ErrorMessage
                      name="priority"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Subject Name
                    </label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-4 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      }`}
                    >
                      Add Subject
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default CreateSubjectPage;
