import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import loginIMg from "../images/login.jpg";
import logoIMg from "../images/logo.png";
import ErrorMsg from "../components/FormMsg/ErrorFormMsg";
import axios from "../api/axios";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // Use useRef for email and password input references
  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`/login`, formData);

      const responseData = response.data;
      console.log(responseData);

      const role = responseData.data.role;

      if (role === "User") {
        setErrorMsg("Unauthorized User!.");
        return;
      }

      const accessToken = responseData?.token;

      login(accessToken, role);
      navigate("/dashboard");
    } catch (error) {
      // Log the error message for debugging
      console.error(error.message || error.response?.data || error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Use the error message from the Laravel response
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGotoForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="flex flex-col md:flex-row overflow-hidden min-h-screen bg-white rounded-md shadow-lg">
      <div className="md:w-1/2 p-4 py-6 text-white md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
        <img src={loginIMg} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="p-5 bg-white w-full md:flex md:flex-col md:justify-center md:items-center ">
        <img src={logoIMg} alt="" className="w-64 mb-10" />

        <form className="flex flex-col space-y-5 " onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-1">
            <label
              htmlFor="email"
              className="text-sm font-semibold pb-1 text-gray-500"
            >
              Enter Email Address
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter Email Address"
              autoFocus
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              ref={emailRef}
              className="px-4 py-2 w-full transition duration-300 border bg-gray-100 border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-500 focus:bg-white"
            />
          </div>
          {errorMsg && <ErrorMsg errMsg={errorMsg} />}
          <div className="flex flex-col space-y-1">
            <label
              htmlFor="password"
              className="text-sm font-semibold pb-1 text-gray-500"
            >
              Enter Password
            </label>
            <input
              type="password"
              id="password"
              required
              placeholder="Enter Password"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              ref={passwordRef}
              className="px-4 bg-gray-100 py-2 transition w-full duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-500 focus:bg-white"
            />
          </div>
          <div className="flex justify-end">
            <span
              onClick={handleGotoForgotPassword}
              className="text-sm text-blue-600 hover:text-primary-dark hover:underline focus:text-blue-800 cursor-pointer"
            >
              Forgot Password?
            </span>
          </div>
          <div>
            <button
              type="submit"
              disabled={loading}
              className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-primary-dark focus:outline-none focus:ring-blue-200 focus:ring-4"
            >
              {loading ? "Loading..." : "Login"}
            </button>
          </div>
          <div className="flex flex-col space-y-5 justify-center items-center">
            <div className="flex flex-col space-y-4">
              <div>
                ©2024. All Rights Reserved{" "}
                <span className="text-secondary">
                  Bitmap I.T. Solution Pvt. Ltd.
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
