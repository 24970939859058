// CoursePage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import DeleteModal from "../components/DeleteModel/DeleteModal";
import { useAuth } from "../contexts/AuthContext";
import { FaEye, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { useToast } from "../contexts/ToastContext";
import PageLoading from "../components/Diagrams/PageLoading";

const GalleryPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [galleries, setGalleries] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteGallery, setDeleteGallery] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await axios.get("/gallerylist", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setGalleries(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching gallery data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryData();
  }, [accessToken]);

  const handleView = useCallback(
    (row) => {
      navigate(`/gallery/${row.id}`);
    },
    [navigate]
  );

  const handleManage = useCallback(
    (row) => {
      navigate(`/gallery/image/${row.id}`);
    },
    [navigate]
  );

  const handleEdit = useCallback(
    (row) => {
      navigate(`/gallery/update/${row.id}`);
    },
    [navigate]
  );

  const handleDeleteModal = useCallback((row) => {
    setDeleteGallery(row.id);
    setDeleteModal(true);
  }, []);

  const handleDelete = async (galleryId) => {
    try {
      console.log("Deleting Gallery:", galleryId);
      await axios.delete(`gallerylist/delete/${galleryId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(`Gallery with ID ${galleryId} deleted successfully`);

      toast.open(
        <SuccessToast
          successMsg={`Gallery with ID ${galleryId} deleted successfully`}
          id={galleryId}
        />,
        galleryId,
        7000
      );

      // Update the UI after deletion
      const updated = galleries.filter(
        (galleries) => galleries.id !== galleryId
      );
      setGalleries(updated);
    } catch (error) {
      console.error("Error deleting the gallery:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setDeleteGallery(null);
      setDeleteModal(false);
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Order", accessor: "priority" },
      { Header: "Name", accessor: "name" },
      {
        Header: "Manage Resources",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className={`px-2 py-1 rounded-lg shadow-md hover:scale-110 ${
                isDarkmode
                  ? "bg-gray-500 text-white hover:bg-gray-600"
                  : "bg-primary text-white hover:bg-blue-600"
              }`}
              onClick={() => handleManage(row.original)}
            >
              Manage
            </button>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleEdit(row.original)}
            >
              <FaEdit size={25} className="text-blue-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleDeleteModal(row.original)}
            >
              <MdDelete size={25} s className="text-red-500" />
            </button>
          </div>
        ),
      },
    ],
    [handleView, handleEdit, handleDeleteModal, handleManage, isDarkmode]
  );

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onDelete={() => handleDelete(deleteGallery)}
          onHide={() => setDeleteModal(false)}
          deleteId={deleteGallery || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <h2 className="font-bold mt-1 text-4xl text-black-700">Gallery</h2>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />

            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(galleries)}
                  addButtonLink="/gallery/create"
                  addButtonLabel="Add New Gallery"
                  editButtonLink="/EditUser"
                  viewButtonLink="/ViewUser"
                  deleteButtonLink="/DeleteUser"
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDeleteModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default GalleryPage;
