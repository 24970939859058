import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import DeleteModal from "../components/DeleteModel/DeleteModal";
import { useAuth } from "../contexts/AuthContext";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { useToast } from "../contexts/ToastContext";
import parse from "html-react-parser";
import PageLoading from "../components/Diagrams/PageLoading";

const AdminLeaveApplicationPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [leaveApplications, setLeaveApplications] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLeaveApplications, setDeleteLeaveApplications] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const [filter, setFilter] = useState("Pending");

  useEffect(() => {
    const fetchLeaveApplicationsData = async () => {
      try {
        const response = await axios.get("all-leave-application", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseUser = await axios.get("admin-users", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setLeaveApplications(responseData.data);

        // Extract staff array from responseUser.data.data
        const staffsArray = responseUser.data.data.data;
        setStaffs(staffsArray);
      } catch (error) {
        console.error(
          "Error fetching Leave Applications data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLeaveApplicationsData();
  }, [accessToken]);

  const handleView = useCallback(
    (row) => {
      navigate(`/admin-leave-application/${row.id}`);
    },
    [navigate]
  );

  const handleDeleteModal = useCallback((row) => {
    setDeleteLeaveApplications(row.id);
    setDeleteModal(true);
  }, []);

  const handleDelete = async (leaveApplicationId) => {
    try {
      console.log("Deleting Leave Application:", leaveApplicationId);
      await axios.delete(`leave-application/delete/${leaveApplicationId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(
        `Leave Application with ID ${leaveApplicationId} deleted successfully`
      );

      toast.open(
        <SuccessToast
          successMsg={`Leave Application with ID ${leaveApplicationId} deleted successfully`}
          id={leaveApplicationId}
        />,
        leaveApplicationId,
        7000
      );

      // Update the UI after deletion
      const updated = leaveApplications.filter(
        (leaveApplication) => leaveApplication.id !== leaveApplicationId
      );
      setLeaveApplications(updated);
    } catch (error) {
      console.error("Error deleting the Leave Application:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setDeleteLeaveApplications(null);
      setDeleteModal(false);
    }
  };

  const handleGotoStaff = useCallback(
    (id) => {
      navigate(`/users/${id}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        Header: "SN",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Staff",
        accessor: "user_id",
        Cell: ({ value }) => {
          const staff = value
            ? staffs.find((staff) => staff.id === value)
            : null;
          return staff ? (
            <button
              onClick={() => handleGotoStaff(staff.id)}
              className="hover:text-primary hover:underline"
            >
              {`${staff.first_name} ${
                staff.middle_name !== null ? staff.middle_name : ""
              } ${staff.last_name} `}
            </button>
          ) : (
            "Unknown Staff"
          );
        },
      },
      { Header: "Submitted at", accessor: "submitted_date" },
      { Header: "Subject", accessor: "subject" },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ row }) => (
          <div className="truncate line-clamp-3">
            <p className="whitespace-normal">
              {parse(row.original.description)}
            </p>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleDeleteModal(row.original)}
            >
              <MdDelete size={25} s className="text-red-500" />
            </button>
          </div>
        ),
      },
    ],
    [handleView, handleDeleteModal, staffs, handleGotoStaff]
  );

  const filteredLeaveApplications = useMemo(() => {
    if (!filter) {
      return Object.values(leaveApplications);
    }
    return Object.values(leaveApplications).filter(
      (leaveApplication) => leaveApplication.status === filter
    );
  }, [leaveApplications, filter]);

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onDelete={() => handleDelete(deleteLeaveApplications)}
          onHide={() => setDeleteModal(false)}
          deleteId={deleteLeaveApplications || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <div className="flex justify-between items-center">
              <h2 className="font-bold mt-1 text-4xl text-black-700">
                Leave Applications - {filter}
              </h2>

              <div className="flex my-2 space-x-2">
                <button
                  className={`px-4 py-2 rounded-md ${
                    filter === "Pending"
                      ? "bg-blue-500 text-white scale-110"
                      : "bg-gray-300 text-gray-700"
                  }`}
                  onClick={() => setFilter("Pending")}
                >
                  Pending
                </button>
                <button
                  className={`px-4 py-2 rounded-md ${
                    filter === "Approved"
                      ? "bg-green-600 text-white scale-110"
                      : "bg-gray-300 text-gray-700"
                  }`}
                  onClick={() => setFilter("Approved")}
                >
                  Approved
                </button>
                <button
                  className={`px-4 py-2 rounded-md ${
                    filter === "Rejected"
                      ? "bg-red-500 text-white scale-110"
                      : "bg-gray-300 text-gray-700"
                  }`}
                  onClick={() => setFilter("Rejected")}
                >
                  Rejected
                </button>
              </div>
            </div>

            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(filteredLeaveApplications)}
                  GlobalFilterNumber={4}
                  handleView={handleView}
                  handleDelete={handleDeleteModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminLeaveApplicationPage;
