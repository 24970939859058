import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useDropzone } from "react-dropzone";
import { AiOutlineDownload } from "react-icons/ai";
import { DeleteOutlined } from "@ant-design/icons";

const CreateImagePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { galleryId } = useParams();

  const [photopaths, setPhotopaths] = useState([]);

  const validationSchema = Yup.object().shape({
    galarylist_id: Yup.number().required("Gallery List is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Combine galarylist_id and photopaths before submitting
      const formData = new FormData();
      formData.append("galarylist_id", values.galarylist_id);

      photopaths.forEach((file) => {
        formData.append("photopath[]", file); // Updated field name
      });

      // Make an API request to create new images
      const response = await axios.post("/gallery/create", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success, e.g., show a success toast or navigate to a success page
      const newImages = response.data.data;

      navigate(`/gallery/image/${galleryId}`);

      toast.open(
        <SuccessToast
          successMsg={`Images added successfully`}
          id={newImages.id}
        />,
        newImages.id,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error("Error creating images:", error.message || error);
      console.error("Server response:", error.response.data); // Log the server response
    } finally {
      // Set submitting to false to enable the form submit button
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    navigate(`/gallery/image/${galleryId}`);
  };

  // Dropzone onDrop handler
  const onDrop = async (acceptedFiles) => {
    const newPhotopaths = acceptedFiles.map(async (file) => {
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        return file;
      }
      // Handle non-image files (e.g., show an error message)
      console.error(`File "${file.name}" is not an image.`);
      return null;
    });

    // Filter out null values (non-image files)
    const resolvedPhotopaths = (await Promise.all(newPhotopaths)).filter(
      (file) => file !== null
    );

    setPhotopaths([...photopaths, ...resolvedPhotopaths]);
  };

  // UseDropzone hook
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemoveImage = (indexToRemove) => {
    setPhotopaths((prevPhotopaths) =>
      prevPhotopaths.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Add Images
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          <Formik
            initialValues={{
              galarylist_id: parseInt(galleryId, 10),
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="mt-4">
              <div className="mb-4">
                <Field
                  type="number"
                  id="galarylist_id"
                  name="galarylist_id"
                  className={`mt-1 p-2 hidden w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="galarylist_id"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Dropzone component */}
              <div
                className={`mb-4 border-2 border-dashed border-gray-300 rounded-md p-8 text-center cursor-pointer transition duration-300 ${
                  isDragActive && "border-green-500"
                }`}
              >
                <div
                  {...getRootProps()}
                  className="flex flex-col items-center justify-center"
                >
                  <label
                    className={`block text-sm font-semibold mb-2 ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Drag & Drop Images
                  </label>
                  <input {...getInputProps()} />
                  <p
                    className={`block text-sm font-semibold mb-2 ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    {isDragActive
                      ? "Drop the files here ..."
                      : "Drag drop some files here, or click to select files"}
                  </p>
                  <AiOutlineDownload
                    size={64}
                    className={`mt-4 text-2xl ${
                      isDarkmode ? "text-white" : " text-primary "
                    }`}
                  />
                </div>
              </div>

              {/* Image previews */}
              <div className="mb-4">
                <label
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Image Previews
                </label>
                <div className="flex flex-wrap gap-4 mt-2">
                  {photopaths.map((file, index) => (
                    <div
                      key={index}
                      className="max-w-32 max-h-32 overflow-hidden rounded-md relative group"
                    >
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <DeleteOutlined
                          className="text-secondary text-2xl cursor-pointer hover:scale-125"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <ErrorMessage
                  name="images"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  }`}
                >
                  Add Images
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default CreateImagePage;
