// UpdateSubjectPage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateSubjectPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { id, courseId } = useParams();
  const [subjectData, setSubjectData] = useState(null);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [subjectResponse, coursesResponse] = await Promise.all([
          axios.get(`/subjects/${id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }),
          axios.get("/admin-courses", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }),
        ]);

        const subjectData = await subjectResponse.data.data;
        const coursesData = await coursesResponse.data.data;

        console.log(subjectData);
        console.log(coursesData);

        setSubjectData(subjectData);
        setCourses(coursesData);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchData();
  }, [id, accessToken]);

  const initialValues = {
    name: subjectData ? subjectData.name : "",
    priority: subjectData ? subjectData.priority : "",
    course_id: subjectData ? subjectData.course_id : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
    course_id: Yup.string().required("Course is required"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update the subject
      const response = await axios.post(`/subjects/update/${id}`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the subject details page
      const updatedSubject = response.data.data;
      console.log(`Subject with ID ${updatedSubject.id} updated successfully`);

      toast.open(
        <SuccessToast
          successMsg={`Subject with ID ${updatedSubject.id} updated`}
          id={updatedSubject.id}
        />,
        updatedSubject.id,
        7000
      );

      // Redirect to the subject details page or any other page
      navigate(`courses/${courseId}/subjects`);
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating subject:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`courses/${courseId}/subjects/`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Edit Subject
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />

          {subjectData ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="mt-4">
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Subject Name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="priority"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Priority
                  </label>
                  <Field
                    type="number"
                    id="priority"
                    name="priority"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="priority"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="course_id"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Course
                  </label>
                  <Field
                    as="select"
                    id="course_id"
                    name="course_id"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  >
                    <option value="" label="Select a course" />
                    {courses.map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="course_id"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className={`px-4 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-primary text-white hover:bg-blue-800"
                    }`}
                  >
                    Update
                  </button>
                </div>
              </Form>
            </Formik>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateSubjectPage;
