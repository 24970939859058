// UpdateStudentPage.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft, FaCircle } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import UserForm from "../../components/Form/UpdateBasicInfoForm";
import UpdateAdditionalInfoForm from "../../components/Form/UpdateAdditionalInfoForm";
import UpdateDocumentForm from "../../components/Form/UpdateStudentDocument";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateStudentPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { studentId } = useParams();
  const [formCount, setFormCount] = useState(1);

  const [studentData, setStudentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [studentDocs, setStudentDocs] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/admin-students/${studentId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data.data;

        setStudentData(responseData);
      } catch (error) {
        console.error(
          `Error fetching data for student ${studentId}:`,
          error.message ||
            (error.response?.data && error.response.data.message) ||
            error
        );
      }
    };

    fetchUserData();
  }, [studentId, accessToken]);

  const fetchUserDocument = async () => {
    try {
      const response = await axios.get(
        `/admin-student-document-field/${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = await response.data.data;

      setStudentDocs(responseData);
    } catch (error) {
      console.error(
        `Error fetching documents for student ${studentId}:`,
        error.message ||
          (error.response?.data && error.response.data.message) ||
          error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDocument();
  });

  const handleBasicInfoSubmit = async (values) => {
    try {
      // Check if photopath is null and remove it from values
      if (values.photopath === null) {
        delete values.photopath;
      }

      const response = await axios.post(
        `/admin-students/update-basic-info/${studentId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.open(
        <SuccessToast
          successMsg={`User with email ${response.data.data.email} updated `}
          id={response.data.id}
        />,
        response.data.id,
        7000
      );
      setFormCount(2);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        console.error("Validation Errors:", error.response.data.errors);
        toast.open(
          <ErrorToast errorMsg="Validation errors. Please check the form." />,
          "error-toast",
          7000
        );
      } else {
        console.error(
          "Error updating user:",
          error.message || error.response?.data || error
        );
        console.log(error.response);
        toast.open(
          <ErrorToast errorMsg="Failed to update user. Please try again later." />,
          "error-toast",
          7000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAdditionalInfoSubmit = async (values) => {
    try {
      const response = await axios.post(
        `/admin-students/update-additional-info/${studentId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast.open(
        <SuccessToast
          successMsg={`User with email ${response.data.data.email} updated `}
          id={response.data.id}
        />,
        response.data.id,
        7000
      );

      fetchUserDocument();
      setFormCount(3);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        console.error("Validation Errors:", error.response.data.errors);
        toast.open(
          <ErrorToast errorMsg="Validation errors. Please check the form." />,
          "error-toast",
          7000
        );
      } else {
        console.error(
          "Error updating user:",
          error.message || error.response?.data || error
        );
        console.log(error.response);
        toast.open(
          <ErrorToast errorMsg="Failed to update user. Please try again later." />,
          "error-toast",
          7000
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleStudentDocsSubmit = async (values) => {
    console.log(values);
    try {
      const response = await axios.post(
        `admin-student-document/update/${studentId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("response :", response.data.message);

      toast.open(
        <SuccessToast successMsg={`User updated `} id={response.data.id} />,
        response.data.id,
        7000
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormCount = (num) => {
    setFormCount(num);
  };

  const handleBack = () => {
    navigate(`/students`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Update Basic Information
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <div className="flex items-center w-full">
            <FaCircle color="blue" onClick={() => handleFormCount(1)} />
            <hr
              className={`flex-1 h-1.5 border-0 ${
                formCount === 2 || formCount === 3
                  ? "bg-blue-500"
                  : "bg-gray-900"
              } 
              }`}
            />
            <FaCircle
              color={formCount === 2 || formCount === 3 ? "blue" : "black"}
              onClick={() => handleFormCount(2)}
            />
            <hr
              className={`flex-1 h-1.5 border-0 ${
                formCount === 3 ? "bg-blue-500" : "bg-gray-900"
              } 
              }`}
            />
            <FaCircle
              color={formCount === 3 ? "blue" : "black"}
              onClick={() => handleFormCount(3)}
            />
          </div>

          {loading ? (
            <PageLoading />
          ) : (
            <>
              {formCount === 1 && (
                <UserForm
                  initialValues={{
                    first_name: studentData.first_name || "no studentData",
                    middle_name: studentData.middle_name || "",
                    last_name: studentData.last_name || "",
                    email: studentData.email || "",
                    address: studentData.address || "",
                    phone_no: studentData.phone_no || "",
                    dob: studentData.dob || "",
                    gender: studentData.gender || "",
                    photopath: null,
                  }}
                  photopath={studentData.photopath}
                  onSubmit={handleBasicInfoSubmit}
                  isDarkmode={isDarkmode}
                  setFormCount={setFormCount}
                  onSkip={handleFormCount}
                />
              )}

              {formCount === 2 && (
                <UpdateAdditionalInfoForm
                  initialValues={{
                    father_name: studentData.father_name || "",
                    mother_name: studentData.mother_name || "",
                    intrested_country: studentData.intrested_country || "",
                    intrested_course: studentData.intrested_course || "",
                    secondary_address: studentData.secondary_address || "",
                    secondary_phone_no: studentData.secondary_phone_no || "",
                    qualification: studentData.qualification || "",
                    reference: studentData.reference || "",
                    applying_country: studentData.applying_country || "",
                  }}
                  onSubmit={handleAdditionalInfoSubmit}
                  isDarkmode={isDarkmode}
                  setFormCount={setFormCount}
                  onSkip={handleFormCount}
                  onBack={handleFormCount}
                />
              )}

              {formCount === 3 && (
                <UpdateDocumentForm
                  onSubmit={handleStudentDocsSubmit}
                  isDarkmode={isDarkmode}
                  setFormCount={setFormCount}
                  onBack={handleFormCount}
                  studentDocs={studentDocs}
                  studentId={studentId}
                />
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateStudentPage;
