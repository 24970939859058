import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavItem = ({ to, icon, label, isDarkmode, expanded }) => {
  const location = useLocation();

  // Check if the current location matches the 'to' prop
  const isActive = location.pathname === to;

  return (
    <Link to={to}>
      <li
        className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${
          isActive
            ? isDarkmode
              ? "bg-black text-white "
              : "bg-indigo-100 text-indigo-800"
            : isDarkmode
            ? "hover:bg-black text-white"
            : "hover:bg-indigo-100 text-gray-600"
        } `}
      >
        {icon({ size: 25, style: { color: isDarkmode ? "white" : "black" } })}
        <span
          className={`overflow-hidden transition-all ${
            expanded ? "w-52 ml-3" : "w-0"
          }`}
        >
          {label}
        </span>
        {!expanded && (
          <div
            className={`
              absolute left-full rounded-md px-2 py-1 ml-6
              bg-indigo-100 text-indigo-800 text-sm
              invisible opacity-20 -translate-x-3 transition-all
              group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
            `}
          >
            {label}
          </div>
        )}
      </li>
    </Link>
  );
};

export default NavItem;
