import React, { createContext, useContext, useEffect, useState } from "react";

const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [chat1, setChat1State] = useState(
    () => JSON.parse(localStorage.getItem("chat1")) || {}
  );
  const [chat2, setChat2State] = useState(
    () => JSON.parse(localStorage.getItem("chat2")) || {}
  );
  const [chat3, setChat3State] = useState(
    () => JSON.parse(localStorage.getItem("chat3")) || {}
  );

  useEffect(() => {
    localStorage.setItem("chat1", JSON.stringify(chat1));
    localStorage.setItem("chat2", JSON.stringify(chat2));
    localStorage.setItem("chat3", JSON.stringify(chat3));
  }, [chat1, chat2, chat3]);

  const setChat1 = (chat) => {
    setChat1State(chat);
  };

  const setChat2 = (chat) => {
    setChat2State(chat);
  };

  const setChat3 = (chat) => {
    setChat3State(chat);
  };

  const closeChat1 = () => {
    setChat1State({});
  };

  const closeChat2 = () => {
    setChat2State({});
  };

  const closeChat3 = () => {
    setChat3State({});
  };

  return (
    <ChatContext.Provider
      value={{
        setChat1,
        setChat2,
        setChat3,
        chat1,
        chat2,
        chat3,
        closeChat1,
        closeChat2,
        closeChat3,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
