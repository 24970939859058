import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ForgotPasswordCodePage from "./pages/ForgotPasswordCodePage";
import NewPasswordPage from "./pages/NewPasswordPage";
import Dashboard from "./pages/Dashboard";
import { AuthProvider } from "./contexts/AuthContext";
import UserPage from "./pages/UserPage";
import CoursePage from "./pages/CoursePage";
import GalleryPage from "./pages/GalleryPage";
import BannerPage from "./pages/BannerPage";
import BranchPage from "./pages/BranchPage";
import NoticePage from "./pages/NoticePage";
import SubjectPage from "./pages/InsidePages/SubjectPage";
import ResourcePage from "./pages/InsidePages/ResourcePage";
import { ToastProvider } from "./contexts/ToastContext";
import CourseDetailsPage from "./pages/ViewDetails/CourseDetailsPage";
import UserDetailsPage from "./pages/ViewDetails/UserDetailsPage";
import UpdateClockInOutTimePage from "./pages/InsidePages/UpdateClockInOutTimePage";
import CreateCoursePage from "./pages/CreatePages/CreateCoursePage";
import CreateUserPage from "./pages/CreatePages/CreateUserPage";
import UpdateCoursePage from "./pages/UpdatePages/UpdateCoursePage";
import UpdateUserPage from "./pages/UpdatePages/UpdateUserPage";
import CreateSubjectPage from "./pages/CreatePages/CreateSubjectPage";
import SubjectDetailsPage from "./pages/ViewDetails/SubjectDetailsPage";
import UpdateSubjectPage from "./pages/UpdatePages/UpdateSubjectPage";
import CreateResourcePage from "./pages/CreatePages/CreateResourcePage";
import UpdateResourcePage from "./pages/UpdatePages/UpdateResourcePage";
import CreatGalleryePage from "./pages/CreatePages/CreateGalleryPage";
import GalleryDetailsPage from "./pages/ViewDetails/GalleryDetailsPage";
import UpdateGalleryPage from "./pages/UpdatePages/UpdateGalleryPage";
import ManageImagePage from "./pages/InsidePages/ManageImagesPage";
import CreateImagePage from "./pages/CreatePages/CreateImagePage";
import ImageDetailsPage from "./pages/ViewDetails/ImageDetailsPage";
import UpdateImagePage from "./pages/UpdatePages/UpdateImagePage";
import CreateNoticePage from "./pages/CreatePages/CreateNoticePage";
import NoticeDetailsPage from "./pages/ViewDetails/NoticeDetailsPage";
import UpdateNoticePage from "./pages/UpdatePages/UpdateNoticePage";
import CreateBannerPage from "./pages/CreatePages/CreateBannerPage";
import BannerDetailsPage from "./pages/ViewDetails/BannerDetailsPage";
import UpdateBannerPage from "./pages/UpdatePages/UpdateBannerPage";
import CreateBranchPage from "./pages/CreatePages/CreateBranchPage";
import BranchDetailsPage from "./pages/ViewDetails/BranchDetailsPage";
import UpdateBranchPage from "./pages/UpdatePages/UpdateBranchPage";
import ScorePage from "./pages/ScorePage";
import CreateScorePage from "./pages/CreatePages/CreateScorePage";
import ScoreDetailsPage from "./pages/ViewDetails/ScoreDetailsPage";
import UpdateScorePage from "./pages/UpdatePages/UpdateScorePage";
import AppointmentTimeslotPage from "./pages/InsidePages/AppointmentTimeslotPage";
import CreateAppointmentTimeslotPage from "./pages/InsidePages/CreateAppointmentTimeslotPage";
import AppointmentTimeslotDetailsPage from "./pages/InsidePages/AppointmentTimeslotDetailsPage";
import UpdateAppointmentTimeslotPage from "./pages/InsidePages/UpdateAppointmentTimeslotPage";
import AppointmentPage from "./pages/AppointmentPage";
import CreateAppointmentPage from "./pages/CreatePages/CreateAppointmentPage";
import AppointmentDetailsPage from "./pages/ViewDetails/AppointmentDetailsPage";
import UpdateAppointmentPage from "./pages/UpdatePages/UpdateAppointmentPage";
import ClassShiftPage from "./pages/ClassShiftPage";
import CreateClassShiftPage from "./pages/CreatePages/CreateClassShiftPage";
import ClassShiftDetailsPage from "./pages/ViewDetails/ClassShiftDetailsPage";
import UpdateClassShiftPage from "./pages/UpdatePages/UpdateClassShiftPage";
import ClassPage from "./pages/InsidePages/ClassPage";
import CreateClassPage from "./pages/InsidePages/CreateClassPage";
import ClassDetailsPage from "./pages/InsidePages/ClassDetailsPage";
import UpdateClassPage from "./pages/InsidePages/UpdateClassPage";
import GuidePage from "./pages/GuidePage";
import CreateGuidePage from "./pages/CreatePages/CreateGuidePage";
import GuideDetailsPage from "./pages/ViewDetails/GuideDetailsPage";
import UpdateGuidePage from "./pages/UpdatePages/UpdateGuidePage";
import TestDatePage from "./pages/TestDatePage";
import CreateTestDatePage from "./pages/CreatePages/CreateTestDatePage";
import TestDateDetailsPage from "./pages/ViewDetails/TestDateDetailsPage";
import UpdateTestDatePage from "./pages/UpdatePages/UpdateTestDatePage";
import TrackingPage from "./pages/TrackingPage";
import CreateTrackingPage from "./pages/CreatePages/CreateTrackingPage";
import TrackingDetailsPage from "./pages/ViewDetails/TrackingDetailsPage";
import UpdateTrackingPage from "./pages/UpdatePages/UpdateTrackingPage";
import PaymentPage from "./pages/PaymentPage";
import CreatePaymentPage from "./pages/CreatePages/CreatePaymentPage";
import PaymentDetailsPage from "./pages/ViewDetails/PaymentDetailsPage";
import UpdatePaymentPage from "./pages/UpdatePages/UpdatePaymentPage";
import StudentPage from "./pages/StudentPage";
import CreateStudentPage from "./pages/CreatePages/CreateStudentPage";
import StudentDetailsPage from "./pages/ViewDetails/StudentsDetailsPage";
import UpdateStudentPage from "./pages/UpdatePages/UpdateStudentPage";
import ProfilePage from "./pages/ProfilePage";
import LeaveApplicationPage from "./pages/LeaveApplicationPage";
import LeaveApplicationDetailsPage from "./pages/ViewDetails/LeaveApplicationDetailsPage";
import AskLeavePage from "./pages/InsidePages/AskLeavePage";
import AdminLeaveApplicationPage from "./pages/AdminLeaveApplicationPage";
import AdminLeaveApplicationDetailsPage from "./pages/ViewDetails/AdminLeaveApplicationDetailsPage";
import StaffAttendancePage from "./pages/StaffAttendancePage";
import StaffAttendanceDetailsPage from "./pages/ViewDetails/StaffAttendanceDetailsPage";
import AdminStaffAttendancePage from "./pages/AdminAttendancePage";
import AdminStaffAttendanceDetailsPage from "./pages/ViewDetails/AdminAttendanceDetailsPage";
import StaffAttendanceHIstory from "./pages/StaffAttendanceHIstory";
import StaffAttendanceHistoryDetails from "./pages/ViewDetails/StaffAttendanceHistoryDetails";
import MonetaryTransactionPage from "./pages/MonetaryTransactionPage";
import IncomePage from "./pages/IncomePage";
import CreateIncomePage from "./pages/CreatePages/CreateIncomePage";
import IncomeDetailsPage from "./pages/ViewDetails/IncomeDetailsPage";
import UpdateIncomePage from "./pages/UpdatePages/UpdateIncomePage";
import ExpensePage from "./pages/ExpensePage";
import CreateExpensePage from "./pages/CreatePages/CreateExpensePage";
import ExpenseDetailsPage from "./pages/ViewDetails/ExpenseDetailsPage";
import UpdateExpensePage from "./pages/UpdatePages/UpdateExpensePage";
import NotificationPage from "./pages/NotificationPage";
import { ChatProvider } from "./contexts/ChatContext";
import DocumentApprovalPage from "./pages/DocumentApprovalPage";
import RequireAuth from "./contexts/RequireAuth";
import CreateNotificationPage from "./pages/CreatePages/CreateNotificationPage";
import DefinedNotificationPage from "./pages/DefinedNotificationPage";
import StudentNotificationPage from "./pages/InsidePages/StudentNotificationPage";
import StaffNotificationPage from "./pages/InsidePages/StaffNotificationPage";
import CreateDefinedNotification from "./pages/CreatePages/CreateDefinedNotification";
import ViewDefinedNotificationDetailsPage from "./pages/ViewDetails/ViewDefinedNotificationDetailsPage";
import UpdateDefinedNotification from "./pages/UpdatePages/UpdateDefinedNotification";

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <ChatProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route
                path="/reset-password-code"
                element={<ForgotPasswordCodePage />}
              />
              <Route path="/reset-password" element={<NewPasswordPage />} />
              {/* Users */}
              <Route
                path="/dashboard"
                element={
                  <RequireAuth allowedRoles={["Admin", "Staff"]}>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              {/* Users */}
              <Route
                path="/users/*"
                element={
                  <RequireAuth allowedRoles={["Admin"]}>
                    <UserPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/users/:userId"
                element={
                  <RequireAuth allowedRoles={["Admin"]}>
                    <UserDetailsPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/users/clock-in-out/:Id"
                element={
                  <RequireAuth allowedRoles={["Admin"]}>
                    <UpdateClockInOutTimePage />
                  </RequireAuth>
                }
              />
              <Route
                path="/users/create"
                element={
                  <RequireAuth allowedRoles={["Admin"]}>
                    <CreateUserPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/users/update/:userId/*"
                element={
                  <RequireAuth allowedRoles={["Admin"]}>
                    <UpdateUserPage />
                  </RequireAuth>
                }
              />

              {/* Staff Attendence Inside Staff page */}
              <Route
                path="/users/attendance/:userId"
                element={
                  <RequireAuth allowedRoles={["Admin"]}>
                    <StaffAttendancePage />
                  </RequireAuth>
                }
              />
              <Route
                path="/users/attendance/view/:Id"
                element={
                  <RequireAuth allowedRoles={["Admin"]}>
                    <StaffAttendanceDetailsPage />
                  </RequireAuth>
                }
              />

              {/* Staff Attendence for admin */}
              <Route
                path="/staff-attendance-today"
                element={<AdminStaffAttendancePage />}
              />
              <Route
                path="/staff-attendance-today/:Id"
                element={<AdminStaffAttendanceDetailsPage />}
              />
              {/* Staff Attendence for staff */}
              <Route
                path="/staff-attendance/*"
                element={<StaffAttendanceHIstory />}
              />
              <Route
                path="/staff-attendance/:Id"
                element={<StaffAttendanceHistoryDetails />}
              />
              {/* Leave Application */}
              <Route
                path="/admin-leave-application/*"
                element={<AdminLeaveApplicationPage />}
              />
              <Route
                path="/admin-leave-application/:Id"
                element={<AdminLeaveApplicationDetailsPage />}
              />
              {/* Leave Application */}
              <Route
                path="/leave-application/*"
                element={
                  <RequireAuth allowedRoles={["Staff", "Admin"]}>
                    <LeaveApplicationPage />
                  </RequireAuth>
                }
              />

              <Route
                path="/leave-application/:Id"
                element={<LeaveApplicationDetailsPage />}
              />
              <Route
                path="/leave-application/ask-leave"
                element={<AskLeavePage />}
              />

              {/* students */}
              <Route path="/students/*" element={<StudentPage />} />
              <Route
                path="/students/:studentId"
                element={<StudentDetailsPage />}
              />
              <Route path="/students/create" element={<CreateStudentPage />} />
              <Route
                path="/students/update/:studentId"
                element={<UpdateStudentPage />}
              />
              {/* Courses */}
              <Route path="/courses/*" element={<CoursePage />} />
              <Route path="/courses/create" element={<CreateCoursePage />} />
              <Route
                path="/courses/:courseId"
                element={<CourseDetailsPage />}
              />
              <Route
                path="/courses/update/:id"
                element={<UpdateCoursePage />}
              />
              {/* Subjects */}
              <Route
                path="/courses/:courseId/subjects/*"
                element={<SubjectPage />}
              />
              <Route
                path="/courses/:courseId/subjects/create"
                element={<CreateSubjectPage />}
              />
              <Route
                path="/courses/:courseId/subjects/:subjectId"
                element={<SubjectDetailsPage />}
              />
              <Route
                path="/courses/:courseId/subjects/update/:id"
                element={<UpdateSubjectPage />}
              />
              {/* Resourse */}
              <Route
                path="/courses/:courseId/subjects/:subjectId/resources/*"
                element={<ResourcePage />}
              />
              <Route
                path="/courses/:courseId/subjects/:subjectId/resources/create"
                element={<CreateResourcePage />}
              />

              <Route
                path="/courses/:courseId/subjects/:subjectId/resources/update/:id"
                element={<UpdateResourcePage />}
              />
              {/* Gallery */}
              <Route path="/gallery" element={<GalleryPage />} />
              <Route path="/gallery/create" element={<CreatGalleryePage />} />
              <Route
                path="/gallery/:galleryId"
                element={<GalleryDetailsPage />}
              />
              <Route
                path="/gallery/update/:id"
                element={<UpdateGalleryPage />}
              />
              <Route
                path="/gallery/image/:galleryId/*"
                element={<ManageImagePage />}
              />
              <Route
                path="/gallery/image/create/:galleryId"
                element={<CreateImagePage />}
              />
              <Route
                path="/gallery/:galleryId/image/view/:imageId"
                element={<ImageDetailsPage />}
              />
              <Route
                path="/gallery/:galleryId/image/update/:imageId"
                element={<UpdateImagePage />}
              />
              {/* Notice */}
              <Route path="/notice/*" element={<NoticePage />} />
              <Route path="/notice/create" element={<CreateNoticePage />} />
              <Route path="/notice/:noticeId" element={<NoticeDetailsPage />} />
              <Route
                path="/notice/update/:noticeId"
                element={<UpdateNoticePage />}
              />
              {/* Banner */}
              <Route path="/banner/*" element={<BannerPage />} />
              <Route path="/banner/create" element={<CreateBannerPage />} />
              <Route path="/banner/:bannerId" element={<BannerDetailsPage />} />
              <Route
                path="/banner/update/:bannerId"
                element={<UpdateBannerPage />}
              />
              {/* Branch */}
              <Route path="/branch/*" element={<BranchPage />} />
              <Route path="/branch/create" element={<CreateBranchPage />} />
              <Route path="/branch/:branchId" element={<BranchDetailsPage />} />
              <Route
                path="/branch/update/:branchId/*"
                element={<UpdateBranchPage />}
              />
              {/* score */}
              <Route path="/score/*" element={<ScorePage />} />
              <Route path="/score/create" element={<CreateScorePage />} />
              <Route path="/score/:scoreId" element={<ScoreDetailsPage />} />
              <Route
                path="/score/update/:scoreId"
                element={<UpdateScorePage />}
              />
              {/* appointment */}
              <Route path="/appointment/*" element={<AppointmentPage />} />
              <Route
                path="/appointment/create"
                element={<CreateAppointmentPage />}
              />
              <Route
                path="/appointment/:appointmentId"
                element={<AppointmentDetailsPage />}
              />
              <Route
                path="/appointment/update/:appointmentId"
                element={<UpdateAppointmentPage />}
              />
              {/* AppointmentTimeslot */}
              <Route
                path="/appointment/timeslot/*"
                element={<AppointmentTimeslotPage />}
              />
              <Route
                path="/appointment/timeslot/create"
                element={<CreateAppointmentTimeslotPage />}
              />
              <Route
                path="/appointment/timeslot/:timeslotId"
                element={<AppointmentTimeslotDetailsPage />}
              />
              <Route
                path="/appointment/timeslot/update/:timeslotId"
                element={<UpdateAppointmentTimeslotPage />}
              />
              {/* class */}
              <Route path="/class-shift/class/*" element={<ClassPage />} />
              <Route
                path="/class-shift/class/create"
                element={<CreateClassPage />}
              />
              <Route
                path="/class-shift/class/:classId"
                element={<ClassDetailsPage />}
              />
              <Route
                path="/class-shift/class/update/:classId"
                element={<UpdateClassPage />}
              />
              {/* classTimeslot */}
              <Route path="/class-shift/*" element={<ClassShiftPage />} />
              <Route
                path="/class-shift/create"
                element={<CreateClassShiftPage />}
              />
              <Route
                path="/class-shift/:shiftId"
                element={<ClassShiftDetailsPage />}
              />
              <Route
                path="/class-shift/update/:shiftId"
                element={<UpdateClassShiftPage />}
              />
              {/* guide */}
              <Route path="/guide/*" element={<GuidePage />} />
              <Route path="/guide/create" element={<CreateGuidePage />} />
              <Route path="/guide/:guideId" element={<GuideDetailsPage />} />
              <Route
                path="/guide/update/:guideId"
                element={<UpdateGuidePage />}
              />
              {/* testdate */}
              <Route path="/testdate/*" element={<TestDatePage />} />
              <Route path="/testdate/create" element={<CreateTestDatePage />} />
              <Route
                path="/testdate/:testdateId"
                element={<TestDateDetailsPage />}
              />
              <Route
                path="/testdate/update/:testdateId"
                element={<UpdateTestDatePage />}
              />
              {/* payment */}
              <Route path="/payment/*" element={<PaymentPage />} />
              <Route path="/payment/create" element={<CreatePaymentPage />} />
              <Route
                path="/payment/:paymentId"
                element={<PaymentDetailsPage />}
              />
              <Route
                path="/payment/update/:paymentId"
                element={<UpdatePaymentPage />}
              />
              {/* tracking */}
              <Route path="/tracking/*" element={<TrackingPage />} />
              <Route path="/tracking/create" element={<CreateTrackingPage />} />
              <Route
                path="/tracking/:trackingId"
                element={<TrackingDetailsPage />}
              />
              <Route
                path="/tracking/update/:trackingId"
                element={<UpdateTrackingPage />}
              />
              {/* transaction */}
              <Route
                path="/transaction/*"
                element={<MonetaryTransactionPage />}
              />
              {/* income */}
              <Route path="/income/*" element={<IncomePage />} />
              <Route path="/income/create" element={<CreateIncomePage />} />
              <Route
                path="/income/:transactionId"
                element={<IncomeDetailsPage />}
              />
              <Route
                path="/income/update/:transactionId"
                element={<UpdateIncomePage />}
              />
              {/* expense */}
              <Route path="/expense/*" element={<ExpensePage />} />
              <Route path="/expense/create" element={<CreateExpensePage />} />
              <Route
                path="/expense/:transactionId"
                element={<ExpenseDetailsPage />}
              />
              <Route
                path="/expense/update/:transactionId"
                element={<UpdateExpensePage />}
              />
              {/* Profile */}
              <Route path="/profile/*" element={<ProfilePage />} />

              {/* Notification */}
              <Route path="/notification" element={<NotificationPage />} />
              <Route
                path="/notification/create"
                element={<CreateNotificationPage />}
              />

              {/* Approval Request */}
              <Route
                path="/document-approval"
                element={<DocumentApprovalPage />}
              />

              {/* Defined Notification */}
              <Route
                path="/defined-notification/*"
                element={<DefinedNotificationPage />}
              />
              <Route
                path="/defined-notification/create"
                element={<CreateDefinedNotification />}
              />
              <Route
                path="/defined-notification/:notificationId"
                element={<ViewDefinedNotificationDetailsPage />}
              />
              <Route
                path="/defined-notification/update/:notificationId"
                element={<UpdateDefinedNotification />}
              />

              <Route
                path="/users/:userId/notification/*"
                element={<StaffNotificationPage />}
              />
              <Route
                path="/students/:studentId/notification/*"
                element={<StudentNotificationPage />}
              />
            </Routes>
          </Router>
        </ChatProvider>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
