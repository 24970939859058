import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import { useToast } from "../../contexts/ToastContext";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateAppointmentPage = () => {
  const { appointmentId } = useParams();
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [timeSlotOptions, setTimeSlotOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAppointmentDetails = async () => {
      try {
        if (!appointmentId) {
          console.error("Appointment ID is not defined.");
          return;
        }

        const response = await axios.get(`/appointment/${appointmentId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setAppointmentDetails(responseData.data);
        console.log(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching appointment details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    const fetchTimeSlotOptions = async () => {
      try {
        const response = await axios.get("/timeslot", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const timeSlotData = response.data.data;
        setTimeSlotOptions(timeSlotData);
      } catch (error) {
        console.error(
          "Error fetching timeslot options:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchAppointmentDetails();
    fetchTimeSlotOptions();
  }, [appointmentId, accessToken]);

  const validationSchema = Yup.object().shape({
    user_id: Yup.number().required("User ID is required"),
    date: Yup.date().required("Date is required"),
    timeslot: Yup.string().required("Timeslot is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update appointment details
      await axios.post(`/appointment/update/${appointmentId}`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the updated appointment details page
      navigate(`/appointment`);

      toast.open(
        <SuccessToast
          successMsg={`Appointment details updated successfully`}
          id={appointmentId}
        />,
        appointmentId,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating appointment details:",
        error.message || error.response?.data || error
      );

      // Check if the error is a server-side error and show a custom error message
      if (error.response && error.response.status >= 500) {
        toast.open(
          <ErrorToast
            errorMsg={`Server error occurred. Please try again later.`}
          />,
          null,
          7000
        );
      } else {
        // For other types of errors, show a generic error message
        toast.open(
          <ErrorToast
            errorMsg={error.response?.data?.message || "An error occurred."}
          />,
          null,
          7000
        );
      }
    }
  };

  const handleBack = () => {
    navigate(`/appointment`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {!loading ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Update Appointment
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  user_id: appointmentDetails.user_id || "",
                  date: appointmentDetails.date || "",
                  timeslot: appointmentDetails.timeslot || "",
                  description: appointmentDetails.description || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="mt-4">
                  {/* User ID */}
                  <div className="mb-4">
                    <label
                      htmlFor="user_id"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      User ID
                    </label>
                    <Field
                      type="number"
                      id="user_id"
                      name="user_id"
                      className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="user_id"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Date */}
                  <div className="mb-4">
                    <label
                      htmlFor="date"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Date
                    </label>
                    <Field
                      type="date"
                      id="date"
                      name="date"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* TimeSlot */}
                  <div className="mb-4">
                    <label
                      htmlFor="timeslot"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Timeslot
                    </label>
                    <Field
                      as="select"
                      id="timeslot"
                      name="timeslot"
                      className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    >
                      <option value="" label="Select a timeslot" />
                      {timeSlotOptions.map((timeslot) => (
                        <option key={timeslot.id} value={timeslot.timeslot}>
                          {timeslot.timeslot}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="timeslot"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Description */}
                  <div className="mb-4">
                    <label
                      htmlFor="description"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Description
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      rows="4"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-4 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      }`}
                    >
                      Update Appointment
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateAppointmentPage;
