import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDarkmode, setIsDarkmode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [resetEmail, setResetEmail] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    const storedRole = localStorage.getItem("role");
    const storedResetEmail = localStorage.getItem("resetEmail");
    if (storedToken) {
      setAccessToken(storedToken);
      setUserRole(storedRole);
      setIsLoggedIn(true);
    }

    if (storedResetEmail) {
      setResetEmail(storedResetEmail);
    }

    const storedTheme = localStorage.getItem("isDarkmode");
    setIsDarkmode(storedTheme === "true");

    const storedExpanded = localStorage.getItem("expanded");
    setExpanded(storedExpanded === "true");
  }, []);

  const toggleTheme = () => {
    setIsDarkmode(!isDarkmode);
    localStorage.setItem("isDarkmode", !isDarkmode);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
    localStorage.setItem("expanded", !expanded);
  };

  const login = (token, role) => {
    setIsLoggedIn(true);
    localStorage.setItem("accessToken", token);
    setAccessToken(token);
    localStorage.setItem("role", role);
    setUserRole(role);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    setAccessToken(null);
    setUserRole(null);
  };

  const addEmailForResetPassowrd = (email) => {
    localStorage.setItem("resetEmail", email);
    setResetEmail(email);
  };

  const removeEmailForResetPassword = () => {
    localStorage.removeItem("resetEmail");
    setResetEmail(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        accessToken,
        isDarkmode,
        expanded,
        setExpanded,
        toggleExpanded,
        toggleTheme,
        setIsDarkmode,
        login,
        logout,
        userRole,
        resetEmail,
        addEmailForResetPassowrd,
        removeEmailForResetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
