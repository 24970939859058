// UpdateScorePage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateScorePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { scoreId } = useParams();
  const [scoreData, setScoreData] = useState(null);

  useEffect(() => {
    const fetchScoreData = async () => {
      try {
        const response = await axios.get(`/score/${scoreId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setScoreData(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching score data:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchScoreData();
  }, [scoreId, accessToken]);

  const initialValues = {
    user_id: scoreData ? scoreData.user_id : "",
    course_name: scoreData ? scoreData.course_name : "",
    listening: scoreData ? scoreData.listening : "",
    reading: scoreData ? scoreData.reading : "",
    speaking: scoreData ? scoreData.speaking : "",
    writing: scoreData ? scoreData.writing : "",
    overall_score: scoreData ? scoreData.overall_score : "",
    test_date: scoreData ? scoreData.test_date : "",
  };

  const validationSchema = Yup.object().shape({
    user_id: Yup.number().required("User ID is required"),
    course_name: Yup.string().required("Course Name is required"),
    listening: Yup.number().required("Listening score is required"),
    reading: Yup.number().required("Reading score is required"),
    speaking: Yup.number().required("Speaking score is required"),
    writing: Yup.number().required("Writing score is required"),
    overall_score: Yup.number().required("Overall score is required"),
    test_date: Yup.date().required("Test date is required"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update the scores
      const response = await axios.post(`/score/update/${scoreId}`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the scores details page
      const updatedScores = response.data.data;
      console.log(`Scores updated successfully`);

      toast.open(
        <SuccessToast successMsg={`Scores updated`} id={updatedScores.id} />,
        updatedScores.id,
        7000
      );

      // Redirect to the scores details page or any other page
      navigate(`/score`);
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating scores:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/score`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Edit Scores
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />

          {scoreData ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="mt-4">
                <div className="mb-4">
                  <label
                    htmlFor="user_id"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    User ID
                  </label>
                  <Field
                    type="number"
                    id="user_id"
                    name="user_id"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="user_id"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="course_name"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Course Name
                  </label>
                  <Field
                    type="text"
                    id="course_name"
                    name="course_name"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="course_name"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="listening"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Listening
                  </label>
                  <Field
                    type="number"
                    id="listening"
                    name="listening"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="listening"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="reading"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Reading
                  </label>
                  <Field
                    type="number"
                    id="reading"
                    name="reading"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="reading"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="speaking"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Speaking
                  </label>
                  <Field
                    type="number"
                    id="speaking"
                    name="speaking"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="speaking"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="writing"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Writing
                  </label>
                  <Field
                    type="number"
                    id="writing"
                    name="writing"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="writing"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="overall_score"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Overall Score
                  </label>
                  <Field
                    type="number"
                    id="overall_score"
                    name="overall_score"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="overall_score"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="test_date"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Test Date
                  </label>
                  <Field
                    type="date"
                    id="test_date"
                    name="test_date"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="test_date"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className={`px-4 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-primary text-white hover:bg-blue-800"
                    }`}
                  >
                    Update
                  </button>
                </div>
              </Form>
            </Formik>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateScorePage;
