import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useToast } from "../../contexts/ToastContext";

const CreatePaymentPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    user_id: Yup.number()
      .required("User ID is required")
      .integer("User ID must be an integer"),
    title: Yup.string()
      .required("Title is required")
      .max(255, "Title is too long"),
    amount: Yup.string()
      .required("Amount is required")
      .max(255, "Amount is too long"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to create a new payment
      const response = await axios.post("/payment/create", values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const paymentData = response.data.data;

      toast.open(
        <SuccessToast
          successMsg={`Payment of id ${paymentData.id} Added.`}
          id={paymentData.id}
        />,
        paymentData.id,
        7000
      );

      // Handle success, e.g., show a success toast or navigate to the payments page
      navigate(`/payment`);
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error creating payment:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/payment`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Add Payment
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          <Formik
            initialValues={{
              user_id: "",
              title: "",
              amount: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="mt-4">
              <div className="mb-4">
                <label
                  htmlFor="user_id"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  User ID
                </label>
                <Field
                  type="number"
                  id="user_id"
                  name="user_id"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="user_id"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="title"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Title
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="amount"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Amount
                </label>
                <Field
                  type="text"
                  id="amount"
                  name="amount"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="amount"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  }`}
                >
                  Add Payment
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default CreatePaymentPage;
