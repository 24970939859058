// TestDatePage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import DeleteModal from "../components/DeleteModel/DeleteModal";
import { useAuth } from "../contexts/AuthContext";
import { FaEye, FaEdit, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import { useToast } from "../contexts/ToastContext";
import PageLoading from "../components/Diagrams/PageLoading";

const TestDatePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [testDates, setTestDates] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTestDates, setDeleteTestDates] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchTestDateData = async () => {
      try {
        const response = await axios.get("admin-test-date", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setTestDates(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching testDate data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTestDateData();
  }, [accessToken]);

  const handleView = useCallback(
    (row) => {
      navigate(`/testdate/${row.id}`);
    },
    [navigate]
  );

  const handleEdit = useCallback(
    (row) => {
      navigate(`/testdate/update/${row.id}`);
    },
    [navigate]
  );

  const handleDeleteModal = useCallback((row) => {
    setDeleteTestDates(row.id);
    setDeleteModal(true);
  }, []);

  const toggleStatus = useCallback(
    async (testDateId) => {
      try {
        // Make an API request to update the status
        await axios.get(`toggle-testdate-status/${testDateId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setTestDates((prevTestDates) => {
          return prevTestDates.map((testDate) => {
            if (testDate.id === testDateId) {
              return {
                ...testDate,
                status: testDate.status === "show" ? "hide" : "show",
              };
            }
            return testDate;
          });
        });

        toast.open(
          <SuccessToast
            successMsg={`TestDate status changed`}
            id={testDateId}
          />,
          testDateId,
          7000
        );
      } catch (error) {
        // Handle error, e.g., show an error toast
        console.error(
          `Error updating testDate status:`,
          error.message || error.response?.data || error
        );

        // Show an error message
        toast.open(
          <ErrorToast errorMsg={`Error updating testDate status`} />,
          null,
          7000
        );
      }
    },
    [accessToken, setTestDates, toast]
  );

  const handleDelete = async (testDateId) => {
    try {
      console.log("Deleting TestDate:", testDateId);
      await axios.delete(`test-date/delete/${testDateId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(`TestDate with ID ${testDateId} deleted successfully`);

      toast.open(
        <SuccessToast
          successMsg={`TestDate with ID ${testDateId} deleted successfully`}
          id={testDateId}
        />,
        testDateId,
        7000
      );

      // Update the UI after deletion
      const updated = testDates.filter(
        (testDates) => testDates.id !== testDateId
      );
      setTestDates(updated);
    } catch (error) {
      console.error("Error deleting the testDate:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setDeleteTestDates(null);
      setDeleteModal(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "SN",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      { Header: "Title", accessor: "title" },
      { Header: "date", accessor: "date" },
      { Header: "Time", accessor: "time" },
      { Header: "Location", accessor: "location" },
      { Header: "Remaining", accessor: "remaining_days" },
      {
        Header: "Hide/Show",
        Cell: ({ row }) => (
          <button
            className={`w-10 h-5 rounded-full ${
              isDarkmode ? "bg-gray-500 " : "bg-gray-200"
            } flex items-center transition duration-300 focus:outline-none shadow`}
            onClick={() => toggleStatus(row.original.id)}
          >
            <div
              id="switch-toggle"
              className={`w-6 h-6 relative rounded-full transition duration-500 transform p-1 text-white flex items-center justify-center ${
                row.original.status === "show"
                  ? "bg-green-500 translate-x-full"
                  : "bg-red-500 -translate-x-2"
              }`}
            >
              {row.original.status === "show" ? (
                <FaEye size={15} color="#FFF" />
              ) : (
                <FaEyeSlash size={15} color="#FFF" />
              )}
            </div>
          </button>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleEdit(row.original)}
            >
              <FaEdit size={25} className="text-blue-500" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleDeleteModal(row.original)}
            >
              <MdDelete size={25} s className="text-red-500" />
            </button>
          </div>
        ),
      },
    ],
    [handleView, handleEdit, handleDeleteModal, toggleStatus, isDarkmode]
  );

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onDelete={() => handleDelete(deleteTestDates)}
          onHide={() => setDeleteModal(false)}
          deleteId={deleteTestDates || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Test Date
            </h2>

            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(testDates)}
                  addButtonLink="/testdate/create"
                  addButtonLabel="Add New Test Date"
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDeleteModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default TestDatePage;
