// LeaveApplicationPage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import { useAuth } from "../contexts/AuthContext";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import PageLoading from "../components/Diagrams/PageLoading";

const LeaveApplicationPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [leaveApplications, setLeaveApplications] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeaveApplicationsData = async () => {
      try {
        const response = await axios.get("all-staff-leave-application", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setLeaveApplications(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching Leave Applications data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLeaveApplicationsData();
  }, [accessToken]);

  const handleView = useCallback(
    (row) => {
      navigate(`/leave-application/${row.id}`);
    },
    [navigate]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "SN",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      { Header: "Submitted at", accessor: "submitted_date" },
      { Header: "Subject", accessor: "subject" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ row }) => (
          <div className="truncate line-clamp-3">
            <p className="whitespace-normal">
              {parse(row.original.description)}
            </p>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
          </div>
        ),
      },
    ],
    [handleView]
  );

  return (
    <>
      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <h2 className="font-bold mt-1 text-4xl text-black">
              Leave Applications
            </h2>

            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(leaveApplications)}
                  addButtonLink="/leave-application/ask-leave"
                  addButtonLabel="Ask Leave"
                  handleView={handleView}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default LeaveApplicationPage;
