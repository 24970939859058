// CustomStudentDocViewer.js
import axios from "../../api/axios";
import React, { useState, useRef } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useAuth } from "../../contexts/AuthContext";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../ToastMsg/SuccessToast";
import ErrorToast from "../ToastMsg/ErrorToast";
import StaffRejectModal from "./StaffRejectModal";
import { useFormik } from "formik";
import * as Yup from "yup";

const CustomStudentDocViewer = ({
  visible,
  onClose,
  fileUrl,
  fileType,
  documentId,
  onUpdateStatus,
}) => {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef(null);
  const { accessToken } = useAuth();
  const toast = useToast();
  const [showRejectionForm, setShowRejectionForm] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleApprove = async () => {
    try {
      // Make an API request to update the status
      const response = await axios.post(
        `/staff-student-document/give-remarks/${documentId}`,
        {
          status: "Approved",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;

      onUpdateStatus(documentId, "Approved");

      toast.open(
        <SuccessToast successMsg={responseData.message} id={documentId} />,
        documentId,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(`Error  :`, error.message || error.response?.data || error);

      // Show an error message
      toast.open(
        <ErrorToast
          errorMsg={error.message ? error.message : `Error`}
          id={documentId}
        />,
        documentId,
        7000
      );
    }
  };

  const handleRejectionForm = () => {
    setShowRejectionForm(!showRejectionForm);
  };

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema: Yup.object().shape({
      remarks: Yup.string().required("Remarks is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(
          `/staff-student-document/give-remarks/${documentId}`,
          {
            status: "Rejected",
            remarks: values.remarks,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response && response.data && response.data.message) {
          toast.open(
            <SuccessToast successMsg={"Document Rejected."} id={documentId} />,
            documentId,
            7000
          );

          onUpdateStatus(documentId, "Rejected");

          resetForm();

          setShowRejectionForm(false);
        } else {
          console.warn("Response does not contain expected message:", response);
        }
      } catch (error) {
        console.error("Error rejecting the document :", error);
        if (error.response) {
          console.error("Response status code:", error.response.status);
          console.error("Response data:", error.response.data);
        }
      }
    },
  });

  return (
    <>
      {showRejectionForm && (
        <StaffRejectModal
          formik={formik}
          showRejectionForm={showRejectionForm}
          closeRejectionForm={handleRejectionForm}
        />
      )}
      <div
        className={`fixed inset-0 z-40 overflow-auto ${
          visible ? "block" : "hidden"
        }`}
        onClick={handleClose}
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
          <div className="relative bg-gray-200 w-full max-w-3xl p-8 rounded-lg overflow-hidden">
            <span
              className="absolute -top-2 -right-2 mt-4 mr-4 text-gray-600 cursor-pointer hover:text-gray-800 transition duration-300"
              onClick={handleClose}
            >
              <IoCloseSharp size={25} />
            </span>
            {fileType === "pdf" ? (
              <iframe
                title="PDF Document"
                src={fileUrl}
                ref={iframeRef}
                width="100%"
                height="600px"
                onLoad={() => setLoading(false)}
                className="w-full"
              />
            ) : (
              <img
                src={fileUrl}
                alt="Document"
                className="w-full h-auto max-h-96 object-contain"
                onLoad={() => setLoading(false)}
              />
            )}
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-gray-600">Loading...</p>
              </div>
            )}
            <div className="flex justify-between mt-4">
              <button
                onClick={handleApprove}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Approve
              </button>
              <button
                onClick={handleRejectionForm}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomStudentDocViewer;
