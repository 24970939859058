import React, { useEffect, useState } from "react";
import { Drawer, message } from "antd";
import axios from "../../api/axios";
import Avatar from "react-avatar";
import { useAuth } from "../../contexts/AuthContext";
import DefaultUser from "../../images/DefaultUser.jpg";
import { useChat } from "../../contexts/ChatContext";

const ChatSidebar = ({
  placement,
  onClose,
  open,
  isDarkmode,
  shouldReloadChats,
  setShouldReloadChats,
}) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const { accessToken } = useAuth();
  const { setChat1, setChat2, setChat3, chat1, chat2, chat3 } = useChat();
  const [suggestions, setSuggestions] = useState([]);
  const [filterSuggestions, setFilterSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchAllChats = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`chat-latest-users`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = response.data;
      setChats(responseData.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Failed to load chats");
    }
  };

  const fetchAllStudents = async () => {
    try {
      const response = await axios.get(`admin-students`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = response.data;
      setSuggestions(responseData.data);
    } catch (error) {
      console.log("Failed to load students");
    }
  };

  useEffect(() => {
    fetchAllStudents();
    fetchAllChats();
  }, [accessToken]);

  useEffect(() => {
    if (shouldReloadChats) {
      fetchAllChats();
      setShouldReloadChats(false);
    }
  }, [shouldReloadChats, accessToken]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    setInputValue(inputValue);

    // Filter suggestions based on the input value
    const filtered = suggestions
      .filter((student) =>
        `${student.first_name} ${
          student.middle_name ? student.middle_name + " " : ""
        }${student.last_name}`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      )
      .slice(0, 5); // Take only the first 5 matches

    setFilterSuggestions(filtered);
  };

  const handleChatClick = async (chat) => {
    try {
      const response = await axios.get(`all-chat-to-seen/${chat.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = response.data;
      console.log(responseData);
    } catch (error) {
      console.log(error);
    }

    if (
      chat.id === chat1?.id ||
      chat.id === chat2?.id ||
      chat.id === chat3?.id
    ) {
      return;
    } else {
      if (!chat1) {
        setChat1(chat);
      } else if (!chat2) {
        setChat2(chat);
      } else if (!chat3) {
        setChat3(chat);
      } else {
        const randomSlot = Math.floor(Math.random() * 3) + 1;
        switch (randomSlot) {
          case 1:
            setChat1(chat);
            break;
          case 2:
            setChat2(chat);
            break;
          case 3:
            setChat3(chat);
            break;
          default:
            break;
        }
      }
      message.success(
        `${chat.first_name} ${chat.middle_name ? chat.middle_name + " " : ""}${
          chat.last_name
        } added to shortcut.`
      );
    }
  };

  return (
    <>
      <Drawer
        title={
          <div className="flex items-center justify-between mt-2">
            <span
              className="text-2xl font-bold"
              style={{ color: isDarkmode ? "white" : "black" }}
            >
              Messages
            </span>
          </div>
        }
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        style={{ backgroundColor: isDarkmode ? "#333" : "#f0f2f5" }}
        styles={{
          header: {
            borderBottom: `2px solid ${isDarkmode ? "white" : "black"}`,
            marginTop: "0.5rem",
          },
          body: {},
        }}
      >
        <input
          value={inputValue}
          onChange={handleInputChange}
          className={`w-full rounded-lg border-2 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-1 ${
            isDarkmode
              ? "text-white placeholder-white bg-gray-800 border-gray-700 focus:border-blue-500"
              : "text-gray-800 placeholder-gray-500 bg-gray-200 border-gray-400 focus:border-blue-500"
          }`}
          type="search"
          placeholder="Search User..."
        />

        {/* Render suggestions based on input value */}
        {inputValue && (
          <ul className="absolute z-10 w-80 bg-white border rounded-lg shadow-md">
            {filterSuggestions.slice(0, 5).map((student) => (
              <li
                key={student.id}
                onClick={() => handleChatClick(student)}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
              >
                <img
                  src={
                    student.photopath
                      ? `https://bitmap.bpnpdl.me/image/user/${student.photopath}`
                      : DefaultUser
                  }
                  alt="User Avatar"
                  className="w-10 h-10 rounded-full mr-3"
                />
                <div>
                  <h4 className="text-lg font-semibold">
                    {`${student.first_name} ${
                      student.middle_name ? student.middle_name + " " : ""
                    }${student.last_name}`}
                  </h4>
                </div>
              </li>
            ))}
          </ul>
        )}

        {loading && <p>Loading chats...</p>}
        {!loading && chats.length > 0 ? (
          <div
            className="overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 200px)" }}
          >
            {chats.map((chat) => (
              <div
                key={chat.id}
                className={`flex items-start px-4 py-3 cursor-pointer transition duration-300 ease-in-out ${
                  isDarkmode ? "hover:bg-gray-800" : "hover:bg-gray-300"
                }`}
                onClick={() => handleChatClick(chat)}
              >
                <Avatar
                  round
                  size="40"
                  src={
                    chat.photopath
                      ? `https://bitmap.bpnpdl.me/image/user/${chat.photopath}`
                      : DefaultUser
                  }
                  className="mr-3"
                  alt="User Avatar"
                />
                <div>
                  <h2
                    className={`text-lg font-semibold ${
                      isDarkmode ? "text-white" : "text-black"
                    }`}
                  >
                    {`${chat.first_name} ${
                      chat.middle_name ? chat.middle_name + " " : ""
                    }${chat.last_name}`}
                  </h2>
                  <p
                    className={`text-sm ${
                      chat.unseen_status === "Unseen"
                        ? "font-extrabold text-gray-100"
                        : isDarkmode
                        ? "text-gray-400"
                        : "text-gray-600"
                    } overflow-hidden whitespace-nowrap overflow-ellipsis  `}
                    style={{ maxWidth: "200px" }}
                  >
                    {chat.message}
                  </p>

                  <div
                    className={`text-xs ${
                      isDarkmode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {chat.message_date} {chat.message_time}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p
            className={`text-center py-4 ${
              isDarkmode ? "text-white" : "text-black"
            } `}
          >
            No message to show!
          </p>
        )}
      </Drawer>
    </>
  );
};

export default ChatSidebar;
