// RequireAuth.js
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "./AuthContext";

const RequireAuth = ({ allowedRoles, children }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    const storedRole = localStorage.getItem("role");

    if (!storedToken || !allowedRoles.includes(storedRole)) {
      logout();
      navigate("/");
    }
  }, [allowedRoles, logout, navigate]);

  return children;
};

export default RequireAuth;
