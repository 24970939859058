import React from "react";
import DefaultUser from "../../images/DefaultUser.jpg";

const ImageAvatar = ({ imgAccessor, row, photoUrl }) => {
  const profileImgUrl = `https://bitmap.bpnpdl.me/${photoUrl}/${row.original[imgAccessor]}`;

  return (
    <img
      className="h-16 rounded-lg"
      src={profileImgUrl}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = DefaultUser;
      }}
      alt={`Profile of ${row.original.first_name}`}
    />
  );
};

export default ImageAvatar;
