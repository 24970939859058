import React, { useEffect, useState, useRef, useCallback } from "react";
import { FaTimes } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { useAuth } from "../../contexts/AuthContext";
import axios from "../../api/axios";

const MessagingBox = ({
  onClose,
  userId,
  isDarkMode,
  onSendMessage,
  position,
}) => {
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [username, setUsername] = useState("");
  const { accessToken } = useAuth();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`/user-conversation/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = response.data;
        setMessages(responseData.data);
        setUsername(responseData.username);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [userId, accessToken]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMessageSend = async () => {
    if (!messageInput.trim()) {
      return;
    }

    const newMessage = {
      message: messageInput,
      action: "UserRecieve",
    };

    const errorMessage = {
      message: messageInput + " !!!",
      action: "Error",
    };

    try {
      const response = await axios.post(
        "/chat/admin-sending-message",
        { message: newMessage.message, user_id: userId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (response.status >= 200 && response.status < 300) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      } else {
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }

    onSendMessage();
    setMessageInput("");
  };

  const handleInputChange = useCallback((e) => {
    setMessageInput(e.target.value);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSend();
    }
  };

  return (
    <div className={position}>
      <div
        className={`w-80 max-h-100 rounded-lg shadow-lg overflow-hidden ${
          isDarkMode ? "bg-gray-500" : "bg-white"
        }`}
      >
        {/* Chat Header */}
        <div
          className={`${
            isDarkMode ? "bg-gray-700" : "bg-primary"
          } px-4 py-3 border-b border-gray-800 text-center font-semibold relative`}
        >
          Chat with {username}
          <button
            onClick={onClose}
            className={`absolute top-3 right-2 hover:scale-125 focus:outline-none ${
              isDarkMode ? "text-white" : "text-gray-700"
            }`}
            aria-label="Close chat"
          >
            <FaTimes />
          </button>
        </div>
        {/* Chat Messages */}
        <div
          className="px-4 py-4 overflow-y-auto"
          style={{ maxHeight: "400px" }}
        >
          {messages.length < 1 && (
            <p
              className={`text-center py-4 ${
                isDarkMode ? "text-white" : "text-black"
              }`}
            >
              No message to show!
            </p>
          )}
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex justify-${
                message.action === "UserRecieve" ? "end" : "start"
              } items-start mb-4`}
            >
              <div
                className={`${
                  message.action === "Error"
                    ? "bg-red-500 text-white"
                    : message.action === "UserRecieve"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-black"
                } p-4 rounded-lg max-w-64 break-words`}
              >
                <p className="text-sm">{message.message}</p>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        {/* Chat Input */}
        <div
          className={`${
            isDarkMode ? "bg-gray-900" : "bg-gray-100"
          } px-4 py-3 flex items-center`}
        >
          <textarea
            placeholder="Type a message..."
            className={`flex-1 bg-transparent focus:outline-none overflow-hidden overflow-wrap break-words resize-none ${
              isDarkMode ? "text-white" : "text-black"
            }`}
            value={messageInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            aria-label="Type a message"
          />
          <button
            className={`ml-3 focus:outline-none ${
              isDarkMode
                ? "text-blue-700 hover:text-blue-900"
                : "text-blue-500 hover:text-blue-700"
            }`}
            onClick={handleMessageSend}
            aria-label="Send message"
          >
            <IoSend />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessagingBox;
