import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import DeleteModal from "../components/DeleteModel/DeleteModal";
import { useAuth } from "../contexts/AuthContext";
import { FaEye, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import { useToast } from "../contexts/ToastContext";
import PageLoading from "../components/Diagrams/PageLoading";

const AppointmentPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [appointments, setAppointments] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAppointment, setDeleteAppointment] = useState(null);
  const [filter, setFilter] = useState("Pending");
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchAppointmentData = async () => {
      try {
        const response = await axios.get("/admin-appointment", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setAppointments(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching appointment data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAppointmentData();
  }, [accessToken, setFilter]);

  const handleView = useCallback(
    (row) => {
      navigate(`/appointment/${row.id}`);
    },
    [navigate]
  );

  const handleEdit = useCallback(
    (row) => {
      navigate(`/appointment/update/${row.id}`);
    },
    [navigate]
  );

  const handleDeleteModal = useCallback((row) => {
    setDeleteAppointment(row.id);
    setDeleteModal(true);
  }, []);

  const handleStatusChange = useCallback(
    async (appointmentId, newStatus) => {
      try {
        // Make an API request to update the status
        await axios.post(
          `/appointment/updateStatus/${appointmentId}`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Update the appointment status in the state
        setAppointments((prevAppointments) => {
          const updatedAppointments = prevAppointments.map((appointment) => {
            if (appointment.id === appointmentId) {
              return { ...appointment, status: newStatus };
            }
            return appointment;
          });
          return updatedAppointments;
        });

        toast.open(
          <SuccessToast
            successMsg={`Appointment status updated to ${newStatus}`}
            id={appointmentId}
          />,
          appointmentId,
          7000
        );
      } catch (error) {
        // Handle error, e.g., show an error toast
        console.error(
          `Error updating appointment status to ${newStatus}:`,
          error.message || error.response?.data || error
        );

        // Show an error message
        toast.open(
          <ErrorToast
            errorMsg={`Error updating appointment status to ${newStatus}`}
          />,
          null,
          7000
        );
      }
    },
    [accessToken, setAppointments, toast]
  );

  const handleDelete = async (appointmentId) => {
    try {
      console.log("Deleting Appointment:", appointmentId);
      await axios.delete(`appointment/delete/${appointmentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(`Appointment with ID ${appointmentId} deleted successfully`);

      toast.open(
        <SuccessToast
          successMsg={`Appointment with ID ${appointmentId} deleted successfully`}
          id={appointmentId}
        />,
        appointmentId,
        7000
      );

      // Update the UI after deletion
      const updated = appointments.filter(
        (appointment) => appointment.id !== appointmentId
      );
      setAppointments(updated);
    } catch (error) {
      console.error("Error deleting the appointment:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setDeleteAppointment(null);
      setDeleteModal(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "SN",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      { Header: "Date", accessor: "date" },
      { Header: "Timeslot", accessor: "timeslot" },
      { Header: "User Id", accessor: "user_id" },
      { Header: "Description", accessor: "description" },
      {
        Header: "Manage Status",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex gap-x-1">
            {filter !== "Pending" && (
              <button
                className="py-1 rounded-md bg-green-600 text-white hover:bg-green-800 px-1"
                onClick={() => handleStatusChange(row.original.id, "Pending")}
              >
                Pending
              </button>
            )}
            {filter !== "Approved" && (
              <button
                className="py-1 rounded-md bg-blue-500 text-white hover:bg-blue-800 px-1"
                onClick={() => handleStatusChange(row.original.id, "Approved")}
              >
                Approved
              </button>
            )}
            {filter !== "Cancelled" && (
              <button
                className="py-1 rounded-md bg-red-500 text-white hover:bg-red-800 px-1"
                onClick={() => handleStatusChange(row.original.id, "Cancelled")}
              >
                Cancelled
              </button>
            )}
          </div>
        ),
      },

      {
        Header: "Action",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleEdit(row.original)}
            >
              <FaEdit size={25} className="text-blue-500" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleDeleteModal(row.original)}
            >
              <MdDelete size={25} s className="text-red-500" />
            </button>
          </div>
        ),
      },
    ],
    [handleView, handleEdit, handleDeleteModal, handleStatusChange, filter]
  );

  const filteredAppointments = useMemo(() => {
    if (!filter) {
      return Object.values(appointments);
    }
    return Object.values(appointments).filter(
      (appointment) => appointment.status === filter
    );
  }, [appointments, filter]);

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onDelete={() => handleDelete(deleteAppointment)}
          onHide={() => setDeleteModal(false)}
          deleteId={deleteAppointment || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 it overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <div className="flex justify-between items-center">
              <h2 className="font-bold mt-1 text-4xl text-black-700">
                Appointments - {filter}
              </h2>

              <div className="flex my-2 space-x-2">
                <button
                  className={`px-4 py-2 rounded-md ${
                    filter === "Pending"
                      ? "bg-blue-500 text-white scale-110"
                      : "bg-gray-300 text-gray-700"
                  }`}
                  onClick={() => setFilter("Pending")}
                >
                  Pending
                </button>
                <button
                  className={`px-4 py-2 rounded-md ${
                    filter === "Approved"
                      ? "bg-green-600 text-white scale-110"
                      : "bg-gray-300 text-gray-700"
                  }`}
                  onClick={() => setFilter("Approved")}
                >
                  Approved
                </button>
                <button
                  className={`px-4 py-2 rounded-md ${
                    filter === "Cancelled"
                      ? "bg-red-500 text-white scale-110"
                      : "bg-gray-300 text-gray-700"
                  }`}
                  onClick={() => setFilter("Cancelled")}
                >
                  Cancelled
                </button>
              </div>
            </div>

            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />

            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={filteredAppointments}
                  GlobalFilterNumber={3}
                  addButtonLink="/appointment/create"
                  addButtonLabel="Add New Appointment"
                  manageLabel="Manage Timeslot"
                  manageLink="/appointment/timeslot"
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDeleteModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default AppointmentPage;
