import React, { useEffect, useRef, useState } from "react";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import { useAuth } from "../contexts/AuthContext";
import Chart from "chart.js/auto";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import PageLoading from "../components/Diagrams/PageLoading";

const MonetaryTransactionPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [totalIncome, setTotalIncome] = useState("");
  const [totalExpense, setTotalExpense] = useState("");
  const [revenue, setRevenue] = useState("");
  const [lastMonthTransaction, setLastMonthTransaction] = useState({});
  const [profitData, setProfitData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardBoxData = async () => {
      try {
        const response = await axios.get("monetary-transaction-box-data", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;

        setTotalExpense(responseData.total_expense);
        setTotalIncome(responseData.total_income);
        setRevenue(responseData.revenue);
      } catch (error) {
        console.error(
          "Error fetching box data:",
          error.message || error.response?.data || error
        );
      }
    };

    const fetchLast10DaysTransaction = async () => {
      try {
        const response = await axios.get(
          "monetary-transaction-last-10-days-profit",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const responseData = await response.data;

        setProfitData(responseData.profitData);
      } catch (error) {
        console.error(
          "Error fetching box data:",
          error.message || error.response?.data || error
        );
      }
    };

    const fetchChartData = async () => {
      try {
        const response = await axios.get("monetary-transaction-chart", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;

        // Convert incomeData and expenseData to numbers
        const incomeData = responseData.incomeData.map((value) =>
          typeof value === "string" ? parseFloat(value) : value
        );
        const expenseData = responseData.expenseData.map((value) =>
          typeof value === "string" ? parseFloat(value) : value
        );

        setLastMonthTransaction({
          datesForLabel: responseData.datesForLabel,
          incomeData,
          expenseData,
        });
      } catch (error) {
        console.error(
          "Error fetching chart data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchChartData();
    fetchLast10DaysTransaction();
    fetchDashboardBoxData();
  }, [accessToken]);

  const data = {
    labels: lastMonthTransaction.datesForLabel,
    datasets: [
      {
        label: "Income",
        backgroundColor: "rgba(0, 150, 0, 0.2)",
        borderColor: "rgb(0, 150, 0)",
        data: lastMonthTransaction.incomeData,
      },
      {
        label: "Expense",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        borderColor: "rgb(255, 0, 0)",
        data: lastMonthTransaction.expenseData,
      },
    ],
  };

  useEffect(() => {
    // Initialize Chart
    const myChart = new Chart(chartRef.current, {
      type: "line",
      data: data,
    });

    // Cleanup for Chart
    return () => {
      myChart.destroy();
    };
  });

  const gotoIncome = () => {
    navigate("/income");
  };

  const gotoExpense = () => {
    navigate("/expense");
  };

  return (
    <>
      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col ">
          <AdminNavbar />

          {loading ? (
            <div
              className={`flex w-full h-full justify-center items-center ${
                isDarkmode ? "bg-gray-500" : "bg-gray-100"
              }`}
            >
              <PageLoading />
            </div>
          ) : (
            <div
              className={`flex-1  ${
                isDarkmode ? "bg-gray-800 text-white" : "bg-gray-100"
              }`}
            >
              <div className="flex ">
                {/* Chart taking up 2/3 of the width */}
                <div className="w-3/4 m-5">
                  <h2 className="font-bold mt-1 text-4xl text-black-700">
                    Transaction
                  </h2>

                  <hr
                    className={`h-0.5 mt-2 border-0 ${
                      isDarkmode ? "bg-gray-900" : "bg-red-500"
                    }`}
                  />
                  <div className="m-5 max-w-full ">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                      <div className="bg-primary text-white p-6 rounded-lg">
                        <button>
                          <p className="font-bold text-lg">Total Income</p>
                        </button>
                        <p className="font-bold text-3xl">{totalIncome}</p>
                      </div>

                      <div className="bg-red-600 text-white p-6 rounded-lg">
                        <button>
                          <p className="font-bold text-lg">Total Expense</p>
                        </button>
                        <p className="font-bold text-3xl">{totalExpense}</p>
                      </div>

                      <div className="bg-green-600 text-white p-6 rounded-lg">
                        <button>
                          <p className="font-bold text-lg">Revenue</p>
                        </button>
                        <p className="font-bold text-3xl">{revenue}</p>
                      </div>
                    </div>
                  </div>

                  <div className="mx-2 max-w-full">
                    <div className="bg-white p-8 rounded-lg shadow-md">
                      <canvas ref={chartRef}></canvas>
                    </div>
                  </div>
                </div>

                {/* Widgets taking up 1/3 of the width */}
                <div className="w-1/4">
                  <div className="flex flex-col gap-y-3 mx-5 mt-5">
                    <button
                      onClick={gotoIncome}
                      className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-green-300"
                    >
                      <GiReceiveMoney size={20} />
                      <span>Income</span>
                    </button>
                    <button
                      onClick={gotoExpense}
                      className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-primary to-blue-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-blue-300"
                    >
                      <GiPayMoney size={20} />
                      <span>Expenses</span>
                    </button>
                  </div>
                  <div className="mr-2 overflow-x-auto">
                    <table className="w-full mt-4 table-auto border rounded-md shadow-lg bg-white">
                      <thead className="bg-gray-200">
                        <tr>
                          <th className="py-2 px-4 border text-center">Date</th>
                          <th className="py-2 px-4 border text-center">
                            Profit (NPR)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(profitData).map(
                          ([date, profit], index) => {
                            const formattedDate = new Date(
                              date
                            ).toLocaleDateString("en-NP", {
                              day: "numeric",
                              month: "short",
                            });

                            return (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "bg-gray-100" : ""}
                              >
                                <td className="py-2 px-4 border text-center">
                                  {formattedDate}
                                </td>
                                <td
                                  className={`py-2 px-4 border flex gap-x-1 items-center justify-center ${
                                    profit >= 0
                                      ? "text-green-600"
                                      : "text-red-600"
                                  }`}
                                >
                                  {new Intl.NumberFormat("en-NP", {
                                    style: "currency",
                                    currency: "NPR",
                                  }).format(profit)}
                                  {profit >= 0 ? (
                                    <CaretUpOutlined />
                                  ) : (
                                    <CaretDownOutlined />
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default MonetaryTransactionPage;
