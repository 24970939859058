import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import { useSpring, animated } from "react-spring";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateUserPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { userId } = useParams();
  const [previewImage, setPreviewImage] = useState(null);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    middle_name: Yup.string().max(
      255,
      "Middle Name must be at most 255 characters"
    ),
    last_name: Yup.string()
      .required("Last Name is required")
      .max(255, "Last Name must be at most 255 characters"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required")
      .max(255, "Email must be at most 255 characters"),
    password: Yup.string().min(6, "Password must be at least 6 characters"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    phone_no: Yup.string().max(
      20,
      "Phone Number must be at most 20 characters"
    ),
    address: Yup.string().max(255, "Address must be at most 255 characters"),
    role: Yup.string().max(255, "Role must be at most 255 characters"),
    is_verified: Yup.boolean().nullable(),
    dob: Yup.date().nullable(),
    gender: Yup.string().max(255, "Gender must be at most 255 characters"),
    qualification: Yup.string().max(
      255,
      "Qualification must be at most 255 characters"
    ),
    father_name: Yup.string().max(
      255,
      "Father's Name must be at most 255 characters"
    ),
    mother_name: Yup.string().max(
      255,
      "Mother's Name must be at most 255 characters"
    ),
    interested_country: Yup.string().max(
      255,
      "Interested Country must be at most 255 characters"
    ),
    interested_course: Yup.string().max(
      255,
      "Interested Course must be at most 255 characters"
    ),
    secondary_phone: Yup.string().max(
      20,
      "Secondary Phone Number must be at most 20 characters"
    ),
    secondary_address: Yup.string().max(
      255,
      "Secondary Address must be at most 255 characters"
    ),
    reference: Yup.string().max(
      255,
      "Reference must be at most 255 characters"
    ),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/admin-users/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data.data;
        setUserData(responseData);
      } catch (error) {
        console.error(
          "Error fetching user details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId, accessToken]);

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];

    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setFieldValue("photopath", file);
    } else {
      setPreviewImage(null);
      setFieldValue("photopath", null);
    }
  };

  const handlePreviewClick = () => {
    setPreviewModalOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewModalOpen(false);
  };

  const handleDeleteClick = (setFieldValue) => {
    setPreviewImage(null);
    setFieldValue("photopath", null);
  };

  const modalAnimation = useSpring({
    opacity: isPreviewModalOpen ? 1 : 0,
    transform: isPreviewModalOpen ? "translateY(0)" : "translateY(-100%)",
  });

  const handleSubmit = async (values) => {
    try {
      if (values.photopath === null) {
        delete values.photopath;
      }

      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axios.post(
        `/admin-users/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        toast.open(
          <SuccessToast
            successMsg={`User with email ${response.data.data.email} updated `}
            id={response.data.id}
          />,
          response.data.id,
          7000
        );

        navigate("/users");
      } else {
        console.error(
          "Unexpected response status:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("Validation Errors:", error.response.data);

        // Display validation errors to the user
        toast.open(
          <ErrorToast errorMsg={`Validation errors`} />,
          "error-toast",
          7000
        );
      } else {
        console.error(
          "Error updating user:",
          error.message || error.response?.data || error
        );
        toast.open(
          <ErrorToast errorMsg="Failed to update user. Please try again later." />,
          "error-toast",
          7000
        );
      }
    } finally {
      setLoading(false); // Set loading to false when the data is fetched
    }
  };

  const handleBack = () => {
    navigate(`/users`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Edit User
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          {loading ? (
            <PageLoading />
          ) : (
            <>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  first_name: userData.first_name || "no userData",
                  middle_name: userData.middle_name || "",
                  last_name: userData.last_name || "",
                  email: userData.email || "",
                  phone_no: userData.phone_no || "",
                  address: userData.address || "",
                  role: userData.role || "",
                  dob: userData.dob || "",
                  gender: userData.gender || "",
                  qualification: userData.qualification || "",
                  father_name: userData.father_name || "",
                  mother_name: userData.mother_name || "",
                  interested_country: userData.interested_country || "",
                  interested_course: userData.interested_course || "",
                  secondary_phone: userData.secondary_phone || "",
                  secondary_address: userData.secondary_address || "",
                  reference: userData.reference || "",
                  photopath: null,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form className="mt-4">
                    <div className="mb-4">
                      <label
                        htmlFor="photopath"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Profile Image
                      </label>
                      <div className="relative group rounded-full overflow-hidden w-48 h-48 m-4 bg-gray-200 hover:border-blue-300 hover:border-2 ">
                        <input
                          type="file"
                          id="photopath"
                          name="photopath"
                          accept="image/*"
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          onChange={(event) =>
                            handleFileChange(event, setFieldValue)
                          }
                        />
                        {previewImage ? (
                          <div className="w-full h-full relative flex ">
                            <img
                              src={previewImage}
                              alt="Preview"
                              className="object-cover w-full h-full group-hover:opacity-75 transition-opacity duration-300 cursor-pointer"
                              onClick={handlePreviewClick}
                            />
                            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              <EyeOutlined
                                className="text-primary text-2xl mr-2 cursor-pointer hover:scale-125"
                                onClick={handlePreviewClick}
                              />
                              <DeleteOutlined
                                className="text-secondary text-2xl cursor-pointer hover:scale-125"
                                onClick={() => handleDeleteClick(setFieldValue)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center justify-center w-full h-full hover:border-blue-300 hover:border-2 ">
                            <PlusOutlined className="text-4xl text-gray-500" />
                            <div className="mt-2 text-gray-500">
                              Upload Photo
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Error message */}
                    <ErrorMessage
                      name="photopath"
                      component="div"
                      className="text-red-500 text-sm mt-2"
                    />

                    <div className="mb-4">
                      <label
                        htmlFor="first_name"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        First Name
                      </label>
                      <Field
                        type="text"
                        id="first_name"
                        name="first_name"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="middle_name"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Middle Name
                      </label>
                      <Field
                        type="text"
                        id="middle_name"
                        name="middle_name"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="middle_name"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="last_name"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Last Name
                      </label>
                      <Field
                        type="text"
                        id="last_name"
                        name="last_name"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Email
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="password"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Password
                      </label>
                      <Field
                        type="password"
                        id="password"
                        name="password"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="password_confirmation"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Confirm Password
                      </label>
                      <Field
                        type="password"
                        id="password_confirmation"
                        name="password_confirmation"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="password_confirmation"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="role"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Role
                      </label>
                      <Field
                        as="select"
                        id="role"
                        name="role"
                        className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      >
                        <option value="User">User</option>
                        <option value="Staff">Staff</option>
                        <option value="Admin">Admin</option>
                      </Field>
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    {/* Phone Number */}
                    <div className="mb-4">
                      <label
                        htmlFor="phone_no"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Phone Number
                      </label>
                      <Field
                        type="text"
                        id="phone_no"
                        name="phone_no"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="phone_no"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    {/* Address */}
                    <div className="mb-4">
                      <label
                        htmlFor="address"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Address
                      </label>
                      <Field
                        type="text"
                        id="address"
                        name="address"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="dob"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Date of Birth
                      </label>
                      <Field
                        type="date"
                        id="dob"
                        name="dob"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="dob"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="gender"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Gender
                      </label>
                      <Field
                        type="text"
                        id="gender"
                        name="gender"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="qualification"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Qualification
                      </label>
                      <Field
                        type="text"
                        id="qualification"
                        name="qualification"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="qualification"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="father_name"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Father's Name
                      </label>
                      <Field
                        type="text"
                        id="father_name"
                        name="father_name"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="father_name"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="mother_name"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Mother's Name
                      </label>
                      <Field
                        type="text"
                        id="mother_name"
                        name="mother_name"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="mother_name"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="interested_country"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Interested Country
                      </label>
                      <Field
                        type="text"
                        id="interested_country"
                        name="interested_country"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="interested_country"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="interested_course"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Interested Course
                      </label>
                      <Field
                        type="text"
                        id="interested_course"
                        name="interested_course"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="interested_course"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="secondary_phone"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Secondary Phone Number
                      </label>
                      <Field
                        type="text"
                        id="secondary_phone"
                        name="secondary_phone"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="secondary_phone"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="secondary_address"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Secondary Address
                      </label>
                      <Field
                        type="text"
                        id="secondary_address"
                        name="secondary_address"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="secondary_address"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="reference"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Reference
                      </label>
                      <Field
                        type="text"
                        id="reference"
                        name="reference"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="reference"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mt-6">
                      <button
                        type="submit"
                        className={`px-4 py-2 rounded-lg shadow-md ${
                          isDarkmode
                            ? "bg-gray-700 text-white hover:bg-gray-900"
                            : "bg-primary text-white hover:bg-blue-800"
                        }`}
                      >
                        Add User
                      </button>
                    </div>

                    {isPreviewModalOpen && (
                      <animated.div
                        style={modalAnimation}
                        className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-80"
                      >
                        <div className="relative bg-white rounded-lg p-4 w-96 h-96  shadow-lg">
                          <img
                            src={previewImage}
                            alt="Preview"
                            className="object-cover w-full h-full rounded-lg mb-4 cursor-pointer"
                            onClick={handleClosePreview}
                          />
                          <button
                            className="absolute top-2 right-2 text-gray-700 text-xl cursor-pointer"
                            onClick={handleClosePreview}
                          >
                            &times;
                          </button>
                        </div>
                      </animated.div>
                    )}
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateUserPage;
