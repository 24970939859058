// UpdateImagePage.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { AiOutlineDownload } from "react-icons/ai";
import { useDropzone } from "react-dropzone";
import { DeleteOutlined } from "@ant-design/icons";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateImagePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { galleryId, imageId } = useParams();

  const [imageData, setImageData] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [photopath, setPhotopath] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const response = await axios.get(`/gallery/${imageId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setImageData(response.data.data);
        setPhotopath(response.data.data.photopath || "");
      } catch (error) {
        console.error("Error fetching image data:", error.message || error);
        console.error("Server response:", error.response.data);
      } finally {
        setLoading(false);
      }
    };

    fetchImageData();
  }, [accessToken, imageId]);

  const [galleryList, setGalleryList] = useState([]);
  useEffect(() => {
    const fetchGalleryList = async () => {
      try {
        const response = await axios.get("/gallerylist", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setGalleryList(response.data.data);
      } catch (error) {
        console.error("Error fetching gallery list:", error.message || error);
        console.error("Server response:", error.response.data);
      }
    };

    fetchGalleryList();
  }, [accessToken]);

  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("galarylist_id", values.galarylist_id);

      // Check if an image file is selected
      if (imageFile !== null && imageFile !== "") {
        formData.append("photopath", imageFile);
      }

      const response = await axios.post(
        `/gallery/update/${imageId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      navigate(`/gallery/image/${values.galarylist_id}`);

      toast.open(
        <SuccessToast successMsg={`Image updated successfully`} id={imageId} />,
        imageId,
        7000
      );
    } catch (error) {
      console.error("Error updating image:", error.message || error);
      console.error("Server response:", error.response.data);
    } finally {
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    navigate(`/gallery/image/${galleryId}`);
  };

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file && file.type.startsWith("image/")) {
      setImageFile(file);
    } else {
      console.error(`File "${file.name}" is not an image.`);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {loading ? (
            <PageLoading />
          ) : (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Update Image
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  galarylist_id: imageData.galarylist_id,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="mt-4">
                    <div className="mb-4">
                      <Field
                        as="select"
                        id="galarylist_id"
                        name="galarylist_id"
                        className={`mt-1 p-2 w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      >
                        {galleryList.map((gallery) => (
                          <option key={gallery.id} value={gallery.id}>
                            {gallery.name}
                          </option>
                        ))}
                      </Field>

                      <ErrorMessage
                        name="galarylist_id"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div
                      className={`mb-4 border-2 border-dashed border-gray-300 rounded-md p-8 text-center cursor-pointer transition duration-300 ${
                        isDragActive && "border-green-500"
                      }`}
                    >
                      <div
                        {...getRootProps()}
                        className="flex flex-col items-center justify-center"
                      >
                        <label
                          className={`block text-sm font-semibold mb-2 ${
                            isDarkmode ? "text-white" : "text-gray-700"
                          }`}
                        >
                          Drag & Drop Image
                        </label>
                        <input {...getInputProps()} />
                        <p
                          className={`block text-sm font-semibold mb-2 ${
                            isDarkmode ? "text-white" : "text-gray-700"
                          }`}
                        >
                          {isDragActive
                            ? "Drop the file here ..."
                            : "Drag drop an image here, or click to select a file"}
                        </p>
                        <AiOutlineDownload
                          size={64}
                          className={`mt-4 text-2xl ${
                            isDarkmode ? "text-white" : " text-primary "
                          }`}
                        />
                      </div>
                    </div>
                    <div className="flex gap-x-3 items-center">
                      {photopath && imageFile === null ? (
                        <div className="mb-4">
                          <label
                            className={`block text-sm font-semibold ${
                              isDarkmode ? "text-white" : "text-gray-700"
                            }`}
                          >
                            Previous Image
                          </label>
                          <div className="w-auto h-32 overflow-hidden rounded-md relative group">
                            <img
                              src={`https://bitmap.bpnpdl.me/image/gallery/${photopath}`}
                              alt={`Previous - ${imageData.id}`}
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {imageFile && (
                        <div className="mb-4">
                          <label
                            className={`block text-sm font-semibold ${
                              isDarkmode ? "text-white" : "text-gray-700"
                            }`}
                          >
                            Selected Image
                          </label>
                          <div className="w-auto h-32 overflow-hidden rounded-md relative group">
                            <img
                              src={URL.createObjectURL(imageFile)}
                              alt={`Selected - ${imageFile.name}`}
                              className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              <DeleteOutlined
                                className="text-secondary text-2xl cursor-pointer hover:scale-125"
                                onClick={() => setImageFile(null)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-6">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`px-4 py-2 rounded-lg shadow-md ${
                          isDarkmode
                            ? "bg-gray-700 text-white hover:bg-gray-900"
                            : "bg-primary text-white hover:bg-blue-800"
                        }`}
                      >
                        {isSubmitting ? "Updating..." : "Update Image"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateImagePage;
