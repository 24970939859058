import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { FaLongArrowAltLeft } from "react-icons/fa";
import PageLoading from "../../components/Diagrams/PageLoading";

const ExpenseDetailsPage = () => {
  const { transactionId } = useParams();
  const [transactionDetails, setTransactionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        if (!transactionId) {
          console.error("Transaction ID is not defined.");
          return;
        }

        const response = await axios.get(
          `monetary-transaction/${transactionId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const responseData = response.data;
        setTransactionDetails(responseData.data);
      } catch (error) {
        console.error("Error fetching transaction details:", error);

        if (error.response && error.response.status === 401) {
          // Unauthorized
          console.error("Unauthorized access.");
        } else {
          // Other errors, handle as needed
          console.error("Unexpected error occurred:", error.message || error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [transactionId, accessToken]);

  const handleBack = () => {
    navigate(`/expense`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Expense Details
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />

          {loading ? (
            <PageLoading />
          ) : (
            <div className=" p-4 rounded-lg">
              <table className="w-full border-collapse border rounded-lg border-gray-300 mt-4">
                <tbody className="rounded-lg">
                  {Object.keys(transactionDetails).map(
                    (key) =>
                      key !== "created_at" &&
                      key !== "transaction_type" &&
                      key !== "updated_at" && (
                        <tr key={key}>
                          <td className="border p-2 font-semibold">
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </td>
                          <td
                            className={`w-full border p-2 ${
                              transactionDetails[key] === null ? "italic" : ""
                            }`}
                          >
                            {transactionDetails[key] === null
                              ? "null"
                              : transactionDetails[key]}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ExpenseDetailsPage;
