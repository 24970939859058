// UpdateAdditionalInfoForm.js
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const UpdateAdditionalInfoForm = ({
  initialValues,
  onSubmit,
  isDarkmode,
  onSkip,
  onBack,
}) => {
  const validationSchema = Yup.object().shape({
    father_name: Yup.string().max(
      255,
      "Father's Name must be at most 255 characters"
    ),
    mother_name: Yup.string().max(
      255,
      "Mother's Name must be at most 255 characters"
    ),
    interested_country: Yup.string().max(
      255,
      "Interested Country must be at most 255 characters"
    ),
    interested_course: Yup.string().max(
      255,
      "Interested Course must be at most 255 characters"
    ),
    secondary_address: Yup.string().max(
      255,
      "Secondary Address must be at most 255 characters"
    ),
    secondary_phone: Yup.string().max(
      20,
      "Secondary Phone Number must be at most 20 characters"
    ),
    qualification: Yup.string().max(
      255,
      "Qualification must be at most 255 characters"
    ),
    reference: Yup.string().max(
      255,
      "Reference must be at most 255 characters"
    ),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form className="mt-4 flex flex-wrap">
          <div className="w-full flex gap-x-2">
            <div className="mb-4 w-1/3">
              <label
                htmlFor="father_name"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Father's Name
              </label>
              <Field
                type="text"
                id="father_name"
                name="father_name"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="father_name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4 w-1/3">
              <label
                htmlFor="mother_name"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Mother's Name
              </label>
              <Field
                type="text"
                id="mother_name"
                name="mother_name"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="mother_name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4 w-1/3">
              <label
                htmlFor="interested_country"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Interested Country
              </label>
              <Field
                type="text"
                id="interested_country"
                name="interested_country"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="interested_country"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="w-full flex gap-x-2">
            <div className="mb-4 w-1/3">
              <label
                htmlFor="interested_course"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Interested Course
              </label>
              <Field
                type="text"
                id="interested_course"
                name="interested_course"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="interested_course"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4 w-1/3">
              <label
                htmlFor="secondary_address"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Secondary Address
              </label>
              <Field
                type="text"
                id="secondary_address"
                name="secondary_address"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="secondary_address"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4 w-1/3">
              <label
                htmlFor="secondary_phone"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Secondary Phone Number
              </label>
              <Field
                type="text"
                id="secondary_phone"
                name="secondary_phone"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="secondary_phone"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="w-full flex gap-x-2">
            <div className="mb-4 w-1/3">
              <label
                htmlFor="qualification"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Qualification
              </label>
              <Field
                type="text"
                id="qualification"
                name="qualification"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="qualification"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4 w-1/3">
              <label
                htmlFor="reference"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Reference
              </label>
              <Field
                type="text"
                id="reference"
                name="reference"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              />
              <ErrorMessage
                name="reference"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4 w-1/3">
              <label
                htmlFor="applying_country"
                className={`block text-sm font-semibold ${
                  isDarkmode ? "text-white" : "text-gray-700"
                }`}
              >
                Applying Country
              </label>
              <Field
                as="select"
                id="applying_country"
                name="applying_country"
                className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                  isDarkmode
                    ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                    : "border-gray-300 bg-white text-gray-700"
                }`}
              >
                <option>Select Country</option>
                <option value="UK">UK</option>
                <option value="Australia">Australia</option>
                <option value="USA">USA</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Canada">Canada</option>
              </Field>
              <ErrorMessage
                name="role"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="flex mt-6 gap-x-2">
            <button
              className={`mt-6 px-6 py-3 rounded-full  ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-green-600 text-white hover:bg-green-800"
              }`}
              onClick={() => {
                onBack(1);
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className={`mt-6 px-6 py-3 rounded-full ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              }`}
            >
              Update Additional Information
            </button>
            <button
              className={`mt-6 px-6 py-3 rounded-full  ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-secondary text-white hover:bg-red-900"
              }`}
              onClick={() => {
                onSkip(3);
              }}
            >
              Skip
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default UpdateAdditionalInfoForm;
