// UpdateNoticePage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateNoticePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { noticeId } = useParams();
  const [noticeData, setNoticeData] = useState(null);

  useEffect(() => {
    const fetchNoticeDetails = async () => {
      try {
        const response = await axios.get(`notice/${noticeId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setNoticeData(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching notice details:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchNoticeDetails();
  }, [accessToken, noticeId]);

  const validationSchema = Yup.object().shape({
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
    notice: Yup.string().required("Notice text is required"),
    date: Yup.date().required("Date is required"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update the notice
      const response = await axios.post(`/notice/update/${noticeId}`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the notice details page
      const updatedNotice = response.data.data;

      navigate(`/notice`);

      toast.open(
        <SuccessToast
          successMsg={`Notice with priority ${updatedNotice.priority} updated`}
          id={updatedNotice.id}
        />,
        updatedNotice.id,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating notice:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/notice`);
  };

  // Only render the form when noticeData is available
  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {noticeData ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Update Notice
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  priority: noticeData.priority,
                  notice: noticeData.notice,
                  date: noticeData.date,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="mt-4">
                  {/* Priority */}
                  <div className="mb-4">
                    <label
                      htmlFor="priority"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Priority
                    </label>
                    <Field
                      type="number"
                      id="priority"
                      name="priority"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="priority"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Notice */}
                  <div className="mb-4">
                    <label
                      htmlFor="notice"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Notice
                    </label>
                    <Field
                      as="textarea"
                      id="notice"
                      name="notice"
                      rows="4"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="notice"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Date */}
                  <div className="mb-4">
                    <label
                      htmlFor="date"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Date
                    </label>
                    <Field
                      type="date"
                      id="date"
                      name="date"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-4 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      }`}
                    >
                      Update Notice
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateNoticePage;
