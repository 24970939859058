import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useToast } from "../../contexts/ToastContext";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateExpensePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { transactionId } = useParams();

  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("Date is required"),
    particular: Yup.string().required("Particular is required"),
    amount: Yup.number()
      .required("Amount is required")
      .max(255, "Amount is too long"),
    transaction_type: Yup.string().required("Type is required"),
  });

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const response = await axios.get(
          `/monetary-transaction/${transactionId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const transactionData = response.data.data;
        setInitialValues({
          date: transactionData.date,
          particular: transactionData.particular,
          amount: transactionData.amount,
          transaction_type: transactionData.transaction_type,
        });
      } catch (error) {
        console.error(
          "Error fetching transaction details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [transactionId, accessToken]);

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update the transaction entry
      await axios.post(
        `/monetary-transaction/update/${transactionId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast.open(
        <SuccessToast
          successMsg={`Transaction entry of id ${transactionId} Updated.`}
          id={transactionId}
        />,
        transactionId,
        7000
      );

      // Handle success, e.g., show a success toast or navigate to the transaction page
      navigate(`/expense`);
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating transaction entry:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/expense`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Update Expense
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          {loading ? (
            <PageLoading />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="mt-4">
                <div className="mb-4">
                  <label
                    htmlFor="date"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Date
                  </label>
                  <Field
                    type="date"
                    id="date"
                    name="date"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="particular"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Particular
                  </label>
                  <Field
                    type="text"
                    id="particular"
                    name="particular"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="particular"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="amount"
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Amount
                  </label>
                  <Field
                    type="number"
                    id="amount"
                    name="amount"
                    className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  />
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mb-4">
                  <Field
                    disabled
                    as="select"
                    id="transaction_type"
                    name="transaction_type"
                    className={`mt-1 p-2 hidden w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                      isDarkmode
                        ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                        : "border-gray-300 bg-white text-gray-700"
                    }`}
                  >
                    <option value="" disabled>
                      Select Transaction Type
                    </option>
                    <option value="Expense">Expense</option>
                    <option value="Income">Income</option>
                  </Field>
                  <ErrorMessage
                    name="transaction_type"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className={`px-4 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-primary text-white hover:bg-blue-800"
                    }`}
                  >
                    Update Expense
                  </button>
                </div>
              </Form>
            </Formik>
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateExpensePage;
