// UserPage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import DeleteModal from "../components/DeleteModel/DeleteModal";
import { useAuth } from "../contexts/AuthContext";
import { FaEye, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { useToast } from "../contexts/ToastContext";
import { Progress } from "antd";
import PageLoading from "../components/Diagrams/PageLoading";
import { MdNotificationAdd } from "react-icons/md";

const UserPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteStudent, setDeleteStudent] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/admin-students", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;

        if (responseData && responseData.data && responseData.data) {
          setStudents(responseData.data);
        } else {
          console.error("Invalid response structure:", responseData);
        }
      } catch (error) {
        console.error(
          "Error fetching user data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [accessToken]);

  const handleView = useCallback(
    (row) => {
      navigate(`/students/${row.id}`);
    },
    [navigate]
  );

  const handleEdit = useCallback(
    (row) => {
      navigate(`/students/update/${row.id}`);
    },
    [navigate]
  );

  const handleGotoNotification = useCallback(
    (row) => {
      navigate(`/students/${row.id}/notification`);
    },
    [navigate]
  );

  const handleDeleteModal = useCallback((row) => {
    console.log("Deleting user:", row.id);
    setDeleteStudent(row.id);
    setDeleteModal(true);
  }, []);

  const handleDelete = async (userId) => {
    try {
      console.log("Deleting user:", userId);
      await axios.delete(`admin-students/delete/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      toast.open(
        <SuccessToast
          successMsg={`User with ID ${userId} deleted successfully`}
          id={userId}
        />,
        userId,
        7000
      );

      // Update the UI after deletion
      const updatedUsers = students.filter((user) => user.id !== userId);
      setStudents(updatedUsers);
    } catch (error) {
      console.error("Error deleting the user:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      // Reset delete user and hide the modal
      setDeleteStudent(null);
      setDeleteModal(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Profile",
        accessor: "profile",
      },
      {
        Header: "Name",
        accessor: (user) => {
          const middleName =
            user.middle_name !== null ? ` ${user.middle_name}` : "";
          return `${user.first_name}${middleName} ${user.last_name}`;
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Documents",
        Cell: ({ row }) => (
          <Progress
            percent={Math.round(
              (row.original.approved_document /
                row.original.total_document_count) *
                100
            )}
          />
        ),
      },
      {
        Header: "Document Status",
        Cell: ({ row }) =>
          row.original.document_status === "Approved" ? (
            <span className="bg-green-600 py-1 px-2 rounded-md">Approved</span>
          ) : row.original.document_status === "Pending" ? (
            <span className="bg-blue-500 py-1 px-2 rounded-md">Pending</span>
          ) : row.original.document_status === "Requested" ? (
            <span className="bg-purple-500 py-1 px-2 rounded-md">
              Requested
            </span>
          ) : (
            <span className="bg-red-500 py-1 px-2 rounded-md">Rejected</span>
          ),
      },
      {
        Header: "Last Edited",
        Cell: ({ row }) =>
          row.original.last_edited_by === null ? (
            <span className="italic">null</span>
          ) : (
            <span>{row.original.last_edited_by}</span>
          ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleEdit(row.original)}
            >
              <FaEdit size={25} className="text-blue-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleDeleteModal(row.original)}
            >
              <MdDelete size={25} s className="text-red-500" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleGotoNotification(row.original)}
            >
              <MdNotificationAdd size={25} className="text-violet-400" />
            </button>
          </div>
        ),
      },
    ],
    [handleView, handleEdit, handleDeleteModal, handleGotoNotification]
  );

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onDelete={() => handleDelete(deleteStudent)}
          onHide={() => setDeleteModal(false)}
          deleteId={deleteStudent || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col ">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <h2 className="font-bold mt-1 text-4xl text-black-700">Students</h2>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />

            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(students)}
                  addButtonLink="/students/create"
                  addButtonLabel="Add New Student"
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDeleteModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default UserPage;
