// AdminAttendancePage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import PageLoading from "../components/Diagrams/PageLoading";
import { useAuth } from "../contexts/AuthContext";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AdminAttendancePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [staffAttendances, setStaffAttendances] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStaffAttendancesData = async () => {
      try {
        const response = await axios.get(`staff-attendance-today`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setStaffAttendances(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching Staff Attendances data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchStaffAttendancesData();
  }, [accessToken]);

  const handleView = useCallback(
    (row) => {
      navigate(`/staff-attendance-today/${row.id}`);
    },
    [navigate]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "SN",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      { Header: "Date", accessor: "date" },
      { Header: "Clock In Time", accessor: "clock_in_time" },
      { Header: "Late Clock In Reason", accessor: "late_clock_in_reason" },
      { Header: "Clock Out Time", accessor: "clock_out_time" },
      { Header: "Early Clock Out Reason", accessor: "early_clock_out_reason" },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
          </div>
        ),
      },
    ],
    [handleView]
  );

  return (
    <>
      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
              <h2 className="font-bold mt-1 text-4xl text-black-700">
                Today's Attendance
              </h2>
            </div>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />

            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(staffAttendances)}
                  GlobalFilterNumber={4}
                  handleView={handleView}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminAttendancePage;
