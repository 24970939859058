import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logoImg from "../images/logo.png";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { useToast } from "../contexts/ToastContext";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { useAuth } from "../contexts/AuthContext";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { addEmailForResetPassowrd } = useAuth();

  const handleGotoHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white p-8 rounded-xl shadow-md">
        <div className="text-center mb-8">
          <Link to="/">
            <img
              src={logoImg}
              alt="Logo"
              className="w-48 mx-auto mb-6 cursor-pointer"
              onClick={handleGotoHome}
            />
          </Link>
          <h2 className="text-3xl font-extrabold text-gray-800">
            Find your account
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Please enter your email to search for your account.
          </p>
        </div>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Please enter your email address"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            addEmailForResetPassowrd(values.email);

            try {
              const response = await axios.post(`forgot-password`, values);
              toast.open(
                <SuccessToast successMsg={response.data.message} />,
                null,
                7000
              );

              console.log(response);
              navigate("/reset-password-code");
            } catch (error) {
              console.error("Error:", error);
              if (error.response) {
                console.error("Response data:", error.response.data);
                toast.open(
                  <ErrorToast errorMsg={error.response.data.message} />,
                  null,
                  7000
                );
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="mt-8 space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold pb-1 text-gray-500"
                >
                  Email Address
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter Email Address"
                  autoFocus
                  className="px-4 py-2 w-full transition duration-300 border bg-gray-100 border-gray-300 rounded focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {isSubmitting ? "Searching..." : "Search"}
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <div className="mt-6 text-center">
          <span className="text-gray-500">Remembered your password?</span>{" "}
          <Link to="/" className="text-blue-600 hover:text-blue-500">
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
