import React, { createContext, useContext, useState } from "react";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const open = (component, id, timeout = 5000) => {
    setToasts((prevToasts) => [...prevToasts, { id, component }]);
    setTimeout(() => close(id), timeout);
  };

  const close = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ open, close }}>
      {children}
      <div className="space-y-2 fixed top-4 right-4">
        {toasts.map(({ id, component }) => (
          <div
            key={id}
            className="relative mt-4 max-w-sm w-full overflow-hidden transition-opacity duration-300 ease-out origin-right opacity-100 hover:opacity-150 "
          >
            {component}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
