import React, { useState } from "react";
import { Modal, Button, Form, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "../../api/axios"; // Import your axios instance
import { useAuth } from "../../contexts/AuthContext";

const ChangeProfilePicModal = ({
  visible,
  onClose,
  handleChangeInProfilePic,
}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { accessToken } = useAuth(); // Assuming useAuth provides accessToken
  const [form] = Form.useForm(); // Create a form instance

  // Function to handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file

    if (file) {
      setPreviewImage(file);
    } else {
      setPreviewImage(null);
    }
  };

  // Function to handle delete click
  const handleDeleteClick = () => {
    // Clear preview image and form field value
    setPreviewImage(null);
    form.setFieldsValue({ photopath: null });
  };

  const handleSubmit = async () => {
    if (!previewImage) return;

    setLoading(true);

    try {
      // Create FormData object to send file
      const formData = new FormData();
      formData.append("photopath", previewImage);

      // Send the image file to the server using Axios
      const response = await axios.post(
        "dashboard-profile-pic-update",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      message.success("Profile picture updated successfully!");

      // Call the callback function with the new image URL
      handleChangeInProfilePic(response.data.data.photopath);

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error updating profile picture:", error); // Log error
      message.error(
        error.response?.data?.message || "Error updating profile picture!" // Display error message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Update Profile Picture"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-700 text-white"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={handleSubmit}
          loading={loading}
          className="bg-blue-500 hover:bg-blue-700 text-white"
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <div className="relative group rounded-full overflow-hidden w-48 h-48 mt-6 mb-12 mx-auto bg-gray-200 hover:border-blue-300 hover:border-2 ">
          <input
            type="file"
            id="photopath"
            name="photopath"
            accept="image/*"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            onChange={handleFileChange} // Attach handleFileChange to onChange event
          />
          {previewImage ? (
            <div className="w-full h-full relative flex ">
              <img
                src={URL.createObjectURL(previewImage)}
                alt="Preview"
                className="object-cover w-full h-full group-hover:opacity-75 transition-opacity duration-300 cursor-pointer"
              />
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <DeleteOutlined
                  className="text-secondary text-2xl cursor-pointer hover:scale-125"
                  onClick={handleDeleteClick} // Attach handleDeleteClick to onClick event
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full hover:border-blue-300 hover:border-2 ">
              <PlusOutlined className="text-4xl text-gray-500" />
              <div className="mt-2 text-gray-500">Upload Photo</div>
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeProfilePicModal;
