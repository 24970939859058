import React, { useEffect, useState } from "react";
import { BsChatSquareTextFill } from "react-icons/bs";
import { IoCloseSharp, IoNotifications } from "react-icons/io5";
import { useAuth } from "../../contexts/AuthContext";
import NotificationSidebar from "../Sidebar/NotificationSidebar";
import ChatSidebar from "../Sidebar/ChatSidebar";
import { IoChatbubbles } from "react-icons/io5";
import DefaultUser from "../../images/DefaultUser.jpg";
import { useChat } from "../../contexts/ChatContext";
import { Tooltip } from "antd";
import MessagingBox from "../MessageBox/MessageBox";
import axios from "../../api/axios";
import notificationSound from "../../sound/notification1.mp3";
const AdminNavbar = () => {
  const { isDarkmode, toggleTheme, accessToken } = useAuth();
  const { chat1, chat2, chat3, closeChat1, closeChat2, closeChat3 } = useChat();

  const [unreadNotificationNumber, setUnreadNotificationNumber] = useState(0);
  const [unseenMessageNumber, setUnseenMessageNumber] = useState(0);
  const [userId, setUserId] = useState(null);
  const [showMessagingBox, setShowMessagingBox] = useState(false);
  const [openNotificationSidebar, setOpenNotificationSidebar] = useState(false);
  const [openChatSidebar, setOpenChatSidebar] = useState(false);
  const [openMessageMenu, setOpenMessageMenu] = useState(false);
  const [shouldReloadChats, setShouldReloadChats] = useState(true);
  const placement = "right";

  const handleSendMessage = () => {
    // Step 4: Update state to trigger re-render of ChatSidebar
    setShouldReloadChats(true);
  };

  const toggleMessagingBox = (id) => {
    setUserId(id);
    setShowMessagingBox((prevState) => !prevState);
  };

  const showChatDrawer = () => {
    setOpenChatSidebar(true);
  };

  const onCloseChatSidebar = () => {
    setOpenChatSidebar(false);
  };

  const showNotificationDrawer = () => {
    setOpenNotificationSidebar(true);
  };

  const onCloseNotificationSidebar = () => {
    setOpenNotificationSidebar(false);
  };

  const toggleOpenMessageMenu = () => {
    setOpenMessageMenu(!openMessageMenu);
  };

  const handleCloseChat1 = () => {
    closeChat1();
  };

  const handleCloseChat2 = () => {
    closeChat2();
  };

  const handleCloseChat3 = () => {
    closeChat3();
  };

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      try {
        const response = await axios.get(`/get-unread-notification`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const responseData = response.data.data;

        if (responseData > 0 && responseData !== unreadNotificationNumber) {
          playNotificationSound();
        }

        setUnreadNotificationNumber(responseData);
      } catch (error) {
        console.error(
          `Error fetching data:`,
          error.message ||
            (error.response?.data && error.response.data.message) ||
            error
        );
      }
    };

    const fetchUnseenMsg = async () => {
      try {
        const response = await axios.get(`/get-unseen-chat`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const responseData = response.data.data;
        setUnseenMessageNumber(responseData);
      } catch (error) {
        console.error(
          `Error fetching data:`,
          error.message ||
            (error.response?.data && error.response.data.message) ||
            error
        );
      }
    };

    fetchUnreadNotifications();
    fetchUnseenMsg();

    const notificationIntervalId = setInterval(fetchUnreadNotifications, 20000); // refresh every 20 seconds
    const messageIntervalId = setInterval(fetchUnseenMsg, 20000); // refresh every 20 seconds

    return () => {
      clearInterval(notificationIntervalId); // clean up notification interval on component unmount
      clearInterval(messageIntervalId); // clean up message interval on component unmount
    };
  }, [accessToken, unreadNotificationNumber]);

  return (
    <div
      className={`flex justify-between items-center py-4 px-10 text-white w-full ${
        isDarkmode ? "bg-primary-dark" : "bg-primary"
      } `}
    >
      {/* Right Section - Icon links */}
      <div className="flex space-x-4 relative">
        <button
          className="hover:text-gray-300 relative"
          onClick={showChatDrawer}
        >
          <BsChatSquareTextFill size={20} />

          {unseenMessageNumber > 99 ? (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
              {"99+"}
            </span>
          ) : unseenMessageNumber > 0 ? (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
              {unseenMessageNumber}
            </span>
          ) : (
            ""
          )}
        </button>
        <button
          className="hover:text-gray-300 relative"
          onClick={showNotificationDrawer}
        >
          <IoNotifications size={20} />

          {unreadNotificationNumber > 99 ? (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
              {"99+"}
            </span>
          ) : unreadNotificationNumber > 0 ? (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
              {unreadNotificationNumber}
            </span>
          ) : (
            ""
          )}
        </button>
      </div>
      {/* Theme Toggle Button */}
      <button
        className={`w-14 h-7 rounded-full ${
          isDarkmode ? "bg-gray-500 " : "bg-gray-200"
        } flex items-center transition duration-300 focus:outline-none shadow`}
        onClick={toggleTheme}
      >
        <div
          id="switch-toggle"
          className={`w-8 h-8 relative rounded-full transition duration-500 transform p-1 text-white flex items-center justify-center ${
            isDarkmode
              ? "bg-gray-700 translate-x-full"
              : "bg-yellow-500 -translate-x-2"
          }`}
        >
          {isDarkmode ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={30}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          )}
        </div>
      </button>

      <NotificationSidebar
        placement={placement}
        onClose={onCloseNotificationSidebar}
        open={openNotificationSidebar}
        isDarkmode={isDarkmode}
      />
      <ChatSidebar
        placement={placement}
        onClose={onCloseChatSidebar}
        open={openChatSidebar}
        isDarkmode={isDarkmode}
        shouldReloadChats={shouldReloadChats}
        setShouldReloadChats={setShouldReloadChats}
      />

      {showMessagingBox && (
        <MessagingBox
          onClose={toggleMessagingBox}
          position={"fixed z-10 bottom-10 right-40 "}
          userId={userId}
          isDarkMode={isDarkmode}
          onSendMessage={handleSendMessage}
        />
      )}

      <div className="fixed  bottom-8 right-8 z-50">
        {/* Floating Button */}
        {openMessageMenu && (
          <>
            {chat1.id ? (
              <Tooltip
                placement="left"
                title={`${chat1.first_name} ${
                  chat1.middle_name ? chat1.middle_name + " " : ""
                }${chat1.last_name}`}
              >
                <div className="relative">
                  <button
                    className={` relative text-white rounded-full w-12 h-12 flex items-center justify-center mt-2 shadow-lg focus:outline-none focus:ring  group  ${
                      isDarkmode
                        ? "hover:bg-gray-700 bg-gray-500 "
                        : "hover:bg-blue-700 bg-primary focus:ring-blue-300"
                    } `}
                    onClick={() => toggleMessagingBox(chat1.id)}
                  >
                    <img
                      src={
                        chat1.photopath
                          ? `https://bitmap.bpnpdl.me/image/user/${chat1.photopath}`
                          : DefaultUser
                      }
                      alt="Default User"
                      className="w-10 h-10 rounded-full"
                    />
                    <button
                      className="absolute top-1 -right-2 bg-white text-black rounded-full w-6 h-6 flex items-center justify-center -mt-2 mr-2 shadow opacity-0 group-hover:opacity-100 transition-opacity duration-300 focus:outline-none"
                      onClick={handleCloseChat1}
                    >
                      <IoCloseSharp />
                    </button>
                  </button>
                </div>
              </Tooltip>
            ) : (
              ""
            )}
            {chat2.id ? (
              <Tooltip
                placement="left"
                title={`${chat2.first_name} ${
                  chat2.middle_name ? chat2.middle_name + " " : ""
                }${chat2.last_name}`}
              >
                <div className="relative">
                  <button
                    className={` relative text-white rounded-full w-12 h-12 flex items-center justify-center mt-2 shadow-lg focus:outline-none focus:ring  group  ${
                      isDarkmode
                        ? "hover:bg-gray-700 bg-gray-500 "
                        : "hover:bg-blue-700 bg-primary focus:ring-blue-300"
                    } `}
                    onClick={() => toggleMessagingBox(chat2.id)}
                  >
                    <img
                      src={
                        chat2.photopath
                          ? `https://bitmap.bpnpdl.me/image/user/${chat2.photopath}`
                          : DefaultUser
                      }
                      alt="Default User"
                      className="w-10 h-10 rounded-full"
                    />
                    <button
                      className="absolute top-1 -right-2 bg-white text-black rounded-full w-6 h-6 flex items-center justify-center -mt-2 mr-2 shadow opacity-0 group-hover:opacity-100 transition-opacity duration-300 focus:outline-none"
                      onClick={handleCloseChat2}
                    >
                      <IoCloseSharp />
                    </button>
                  </button>
                </div>
              </Tooltip>
            ) : (
              ""
            )}
            {chat3.id ? (
              <Tooltip
                placement="left"
                title={`${chat3.first_name} ${
                  chat3.middle_name ? chat3.middle_name + " " : ""
                }${chat3.last_name}`}
              >
                <div className="relative">
                  <button
                    className={` relative text-white rounded-full w-12 h-12 flex items-center justify-center mt-2 shadow-lg focus:outline-none focus:ring  group  ${
                      isDarkmode
                        ? "hover:bg-gray-700 bg-gray-500 "
                        : "hover:bg-blue-700 bg-primary focus:ring-blue-300"
                    } `}
                    onClick={() => toggleMessagingBox(chat3.id)}
                  >
                    <img
                      src={
                        chat3.photopath
                          ? `https://bitmap.bpnpdl.me/image/user/${chat3.photopath}`
                          : DefaultUser
                      }
                      alt="Default User"
                      className="w-10 h-10 rounded-full"
                    />
                    <button
                      className="absolute top-1 -right-2 bg-white text-black rounded-full w-6 h-6 flex items-center justify-center -mt-2 mr-2 shadow opacity-0 group-hover:opacity-100 transition-opacity duration-300 focus:outline-none"
                      onClick={handleCloseChat3}
                    >
                      <IoCloseSharp />
                    </button>
                  </button>
                </div>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        )}
        {(chat1.id || chat2.id || chat3.id) && (
          <button
            className={`rounded-full w-12 h-12 flex items-center justify-center mt-2 shadow-lg focus:outline-none focus:ring ${
              isDarkmode
                ? "bg-gray-500 text-white hover:bg-gray-700 focus:ring-gray-300"
                : "bg-primary text-white hover:bg-blue-700 focus:ring-blue-300"
            }`}
            onClick={toggleOpenMessageMenu}
          >
            <IoChatbubbles size={35} />
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminNavbar;
