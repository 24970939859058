import React, { useState, useEffect, useCallback } from "react";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import DataTable from "../../components/Diagrams/DataTable";
import DeleteModal from "../../components/DeleteModel/DeleteModal";
import { useAuth } from "../../contexts/AuthContext";
import { FaEye, FaEdit, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import { useToast } from "../../contexts/ToastContext";
import { FaLongArrowAltLeft, FaFilePdf, FaVideo } from "react-icons/fa";
import PageLoading from "../../components/Diagrams/PageLoading";

const SubjectPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [resources, setResources] = useState({});
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteResource, setDeleteResource] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { courseId, subjectId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resourceResponse = await axios.get(
          `subjects/resources/${subjectId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const subjectResponse = await axios.get("/admin-subjects", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const subjectData = await resourceResponse.data;
        const courseData = await subjectResponse.data;

        setResources(subjectData.data);
        setSubjects(courseData.data);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken, subjectId]);

  const handleBackClick = () => {
    navigate(`/courses/${courseId}/subjects/`);
  };

  const handleView = useCallback(
    (row) => {
      navigate(
        `/courses/${courseId}/subjects/${subjectId}/resources/${row.id}`
      );
    },
    [navigate, courseId, subjectId]
  );

  const handleEdit = useCallback(
    (row) => {
      navigate(
        `/courses/${courseId}/subjects/${subjectId}/resources/update/${row.id}`
      );
    },
    [navigate, courseId, subjectId]
  );

  const handleDeleteModal = useCallback((row) => {
    setDeleteResource(row.id);
    setDeleteModal(true);
  }, []);

  const toggleStatus = useCallback(
    async (resourceId) => {
      try {
        // Make an API request to update the status
        await axios.get(`toggle-resource-status/${resourceId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setResources((prevResources) => {
          return prevResources.map((resource) => {
            if (resource.id === resourceId) {
              return {
                ...resource,
                status: resource.status === "show" ? "hide" : "show",
              };
            }
            return resource;
          });
        });

        toast.open(
          <SuccessToast
            successMsg={`Resource status changed`}
            id={resourceId}
          />,
          resourceId,
          7000
        );
      } catch (error) {
        // Handle error, e.g., show an error toast
        console.error(
          `Error updating resource status:`,
          error.message || error.response?.data || error
        );

        // Show an error message
        toast.open(
          <ErrorToast errorMsg={`Error updating resource status`} />,
          null,
          7000
        );
      }
    },
    [accessToken, setResources, toast]
  );

  const handleDelete = async (resourceId) => {
    try {
      console.log("Deleting Resource:", resourceId);
      await axios.delete(`resources/delete/${resourceId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(`Resource with ID ${resourceId} deleted successfully`);

      toast.open(
        <SuccessToast
          successMsg={`Resource with ID ${resourceId} deleted successfully`}
          id={resourceId}
        />,
        resourceId,
        7000
      );

      // Update the UI after deletion
      const updated = resources.filter(
        (resource) => resource.id !== resourceId
      );
      setResources(updated);
    } catch (error) {
      console.error("Error deleting the resource:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setDeleteResource(null);
      setDeleteModal(false);
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Order", accessor: "priority" },
      { Header: "Name", accessor: "name" },
      {
        Header: "Subject",
        accessor: "subject_id",
        Cell: ({ value }) => {
          const subject = subjects.find((subject) => subject.id === value);
          return subject ? subject.name : "Unknown Subject";
        },
      },
      {
        Header: "View File",
        accessor: "filepath",
        Cell: ({ row }) => (
          <a
            className={`${isDarkmode ? "text-blue-400" : "text-primary"} `}
            href={
              row.original.filetype === "video"
                ? row.original.filepath
                : `https://bitmap.pramodchhetri.com.np/upload/resource/${row.original.filepath}`
            }
            target="_blank"
            rel="noreferrer"
          >
            {row.original.filetype === "pdf" ? (
              <FaFilePdf size={25} className="hover:scale-125" />
            ) : (
              <FaVideo size={25} className="hover:scale-125" />
            )}
          </a>
        ),
      },

      {
        Header: "Hide/Show",
        Cell: ({ row }) => (
          <button
            className={`w-10 h-5 rounded-full ${
              isDarkmode ? "bg-gray-500 " : "bg-gray-200"
            } flex items-center transition duration-300 focus:outline-none shadow`}
            onClick={() => toggleStatus(row.original.id)}
          >
            <div
              id="switch-toggle"
              className={`w-6 h-6 relative rounded-full transition duration-500 transform p-1 text-white flex items-center justify-center ${
                row.original.status === "show"
                  ? "bg-green-500 translate-x-full"
                  : "bg-red-500 -translate-x-2"
              }`}
            >
              {row.original.status === "show" ? (
                <FaEye size={15} color="#FFF" />
              ) : (
                <FaEyeSlash size={15} color="#FFF" />
              )}
            </div>
          </button>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110"
              onClick={() => handleEdit(row.original)}
            >
              <FaEdit size={25} className="text-blue-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleDeleteModal(row.original)}
            >
              <MdDelete size={25} s className="text-red-500" />
            </button>
          </div>
        ),
      },
    ],
    [handleEdit, handleDeleteModal, subjects, toggleStatus, isDarkmode]
  );

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onDelete={() => handleDelete(deleteResource)}
          onHide={() => setDeleteModal(false)}
          deleteId={deleteResource || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <div className="mt-1 mb-3 mx-1 flex justify-between items-center">
              <h2 className="font-bold mt-1 text-4xl text-black-700">
                Resource
              </h2>

              <button
                onClick={handleBackClick}
                className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                  isDarkmode
                    ? "bg-gray-700 text-white hover:bg-gray-900"
                    : "bg-primary text-white hover:bg-blue-800"
                } `}
              >
                <FaLongArrowAltLeft />
                Back
              </button>
            </div>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />

            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(resources)}
                  addButtonLink={`/courses/${courseId}/subjects/${subjectId}/resources/create`}
                  addButtonLabel="Add New Resource"
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDeleteModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default SubjectPage;
