import React from "react";
import DefaultUser from "../../images/DefaultUser.jpg";

const Avatar = ({ imgAccessor, row }) => {
  const profileImgUrl = `https://bitmap.bpnpdl.me/image/user/${row.original[imgAccessor]}`;

  return (
    <img
      className="h-16 w-16 rounded-full"
      src={profileImgUrl}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = DefaultUser;
      }}
      alt={`Profile of ${row.original.first_name}`}
    />
  );
};

export default Avatar;
