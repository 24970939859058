import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logoImg from "../images/logo.png";
import { useAuth } from "../contexts/AuthContext";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { useToast } from "../contexts/ToastContext";
import axios from "../api/axios";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { resetEmail } = useAuth();
  const { removeEmailForResetPassword } = useAuth();

  const initialValues = {
    password: "",
    password_confirmation: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Please enter a password")
      .min(6, "Password must be at least 6 characters"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const handleSubmit = async (values) => {
    try {
      await axios.post(`reset-password`, {
        password: values.password,
        password_confirmation: values.password_confirmation,
        email: resetEmail,
      });

      toast.open(
        <SuccessToast successMsg={`Password is reset. Login in now.`} />,
        null,
        7000
      );

      removeEmailForResetPassword();
      navigate("/");
    } catch (error) {
      console.error("Error:", error);
      toast.open(<ErrorToast errorMsg={"Server error"} />, null, 7000);
    }
  };

  const handleGotoHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center mb-8">
          <img
            src={logoImg}
            alt="Logo"
            onClick={handleGotoHome}
            className="w-48 mx-auto mb-6"
          />
          <h2 className="text-3xl font-extrabold text-gray-800">
            Reset Password
          </h2>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="mt-8 space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-semibold pb-1 text-gray-700"
                >
                  Password
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  className="px-4 py-2 w-full transition duration-300 border bg-gray-100 border-gray-300 rounded focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="password_confirmation"
                  className="block text-sm font-semibold pb-1 text-gray-700"
                >
                  Confirm Password
                </label>
                <Field
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder="Confirm Password"
                  className="px-4 py-2 w-full transition duration-300 border bg-gray-100 border-gray-300 rounded focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <ErrorMessage
                  name="password_confirmation"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="flex ">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full py-2 text-white bg-primary hover:bg-primary-dark rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark"
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewPasswordPage;
