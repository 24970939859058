// DocumentApprovalPage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import { useAuth } from "../contexts/AuthContext";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { useToast } from "../contexts/ToastContext";
import { IoCloseCircle, IoCheckmarkCircleSharp } from "react-icons/io5";
import ApprovalRejectModal from "../components/Modal/ApprovalRejectModal";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import PageLoading from "../components/Diagrams/PageLoading";

const DocumentApprovalPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [documentApproval, setDocumentApproval] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const [staffs, setStaffs] = useState([]);
  const [students, setStudents] = useState([]);
  const [showRejectionForm, setShowRejectionForm] = useState(false);
  const [approvalId, setApprovalId] = useState(null);

  useEffect(() => {
    const fetchDocumentApproval = async () => {
      try {
        const response = await axios.get("admin-pending-document-approval", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseStaffs = await axios.get("admin-users", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseStudents = await axios.get("admin-students", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setDocumentApproval(responseData.data);

        const staffsArray = responseStaffs.data.data.data;
        setStaffs(staffsArray);

        const studentsArray = responseStudents.data.data;
        setStudents(studentsArray);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.message || error.response?.data || error
        );

        // Log the response status code and any error message to investigate further
        if (error.response) {
          console.error("Response status code:", error.response.status);
          console.error("Response data:", error.response.data);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentApproval();
  }, [accessToken]);

  const closeRejectionForm = () => {
    setShowRejectionForm(false);
  };

  const handleView = useCallback(
    (row) => {
      navigate(`/document-approval/${row.id}`);
    },
    [navigate]
  );

  const handleEdit = useCallback(
    (row) => {
      navigate(`/document-approval/update/${row.id}`);
    },
    [navigate]
  );

  const handleApproved = useCallback(
    async (documentApprovalId) => {
      try {
        const response = await axios.post(
          `admin-response-to-approval/${documentApprovalId}`,
          {
            status: "Approved",
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response && response.data && response.data.message) {
          toast.open(
            <SuccessToast
              successMsg={"Document Approved."}
              id={documentApprovalId}
            />,
            documentApprovalId,
            7000
          );
        } else {
          console.warn("Response does not contain expected message:", response);
        }

        // Update the UI after deletion
        const updated = documentApproval.filter(
          (documentApproval) => documentApproval.id !== documentApprovalId
        );
        setDocumentApproval(updated);
      } catch (error) {
        console.error("Error approving the document approval:", error);
        if (error.response) {
          console.error("Response status code:", error.response.status);
          console.error("Response data:", error.response.data);
        }
      }
    },
    [accessToken, documentApproval, setDocumentApproval, toast]
  );

  const handleGotoStaff = useCallback(
    (id) => {
      navigate(`/users/${id}`);
    },
    [navigate]
  );

  const handleGotoStudent = useCallback(
    (id) => {
      navigate(`/students/${id}`);
    },
    [navigate]
  );

  const handleRejectionModal = (id) => {
    setShowRejectionForm(true);
    setApprovalId(id);
  };

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema: Yup.object().shape({
      remarks: Yup.string().required("Remarks is required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await axios.post(
          `admin-response-to-approval/${approvalId}`,
          {
            status: "Rejected",
            remarks: values.remarks,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response && response.data && response.data.message) {
          toast.open(
            <SuccessToast successMsg={"Document Approved."} id={approvalId} />,
            approvalId,
            7000
          );

          setShowRejectionForm(false);
        } else {
          console.warn("Response does not contain expected message:", response);
        }

        // Update the UI after rejection
        const updated = documentApproval.filter(
          (documentApproval) => documentApproval.id !== approvalId
        );
        setDocumentApproval(updated);
      } catch (error) {
        console.error("Error rejecting the document approval:", error);
        if (error.response) {
          console.error("Response status code:", error.response.status);
          console.error("Response data:", error.response.data);
        }
      }
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "SN",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Student",
        accessor: "student_id",
        Cell: ({ value }) => {
          const student = students.find((student) => student.id === value);
          return student ? (
            <button className="hover:text-primary hover:underline">
              {`${student.first_name} ${
                student.middle_name !== null ? student.middle_name : ""
              } ${student.last_name}`}
            </button>
          ) : (
            "Unknown Student"
          );
        },
      },
      {
        Header: "Staff",
        accessor: "staff_id",
        Cell: ({ value }) => {
          const staff = staffs.find((staff) => staff.id === value);
          return staff ? (
            <button
              onClick={() => handleGotoStaff(staff.id)}
              className="hover:text-primary hover:underline"
            >
              {`${staff.first_name} ${
                staff.middle_name !== null ? staff.middle_name : ""
              } ${staff.last_name}`}
            </button>
          ) : (
            "Unknown Staff"
          );
        },
      },
      { Header: "Status", accessor: "status" },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleGotoStudent(row.original.student_id)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleApproved(row.original.id)}
            >
              <IoCheckmarkCircleSharp size={25} className="text-blue-600" />
            </button>
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleRejectionModal(row.original.id)}
            >
              <IoCloseCircle size={25} className="text-red-500" />
            </button>
          </div>
        ),
      },
    ],
    [handleGotoStudent, handleGotoStaff, handleApproved, students, staffs]
  );

  return (
    <>
      {showRejectionForm && (
        <ApprovalRejectModal
          formik={formik}
          showRejectionForm={showRejectionForm}
          closeRejectionForm={closeRejectionForm}
          isDarkmode={isDarkmode}
          Field={Field}
        />
      )}
      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Documents Approval Request
            </h2>

            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  GlobalFilterNumber={4}
                  data={Object.values(documentApproval)}
                  handleView={handleView}
                  handleEdit={handleEdit}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default DocumentApprovalPage;
