import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { FaLongArrowAltLeft } from "react-icons/fa";
import {
  CheckOutlined,
  CloseOutlined,
  FastBackwardOutlined,
} from "@ant-design/icons";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import { useToast } from "../../contexts/ToastContext";
import parse from "html-react-parser";
import PageLoading from "../../components/Diagrams/PageLoading";

const AdminLeaveApplicationDetailsPage = () => {
  const { Id } = useParams();
  const [leaveApplicationDetails, setLeaveApplicationDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchLeaveApplicationDetails = async () => {
      try {
        if (!Id) {
          console.error("Leave Application ID is not defined.");
          return;
        }

        const response = await axios.get(`all-leave-application/${Id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        console.log(responseData.data);
        setLeaveApplicationDetails(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching leave application details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLeaveApplicationDetails();
  }, [Id, accessToken]);

  const handleBack = () => {
    navigate(`/admin-leave-application`);
  };

  const handlePending = async () => {
    try {
      const response = await axios.get(`leave-application-pending/${Id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = response.data;
      toast.open(
        <SuccessToast successMsg={responseData.message} id={Id} />,
        Id,
        7000
      );

      navigate("/admin-leave-application");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access (e.g., redirect to login)
      } else {
        const errorMessage =
          error.response?.data?.message || "An error occurred.";
        toast.open(<ErrorToast errorMsg={errorMessage} id={Id} />, Id, 7000);
      }
    }
  };

  const handleApprove = async () => {
    try {
      const response = await axios.get(`leave-application-approved/${Id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = response.data;
      toast.open(
        <SuccessToast successMsg={responseData.message} id={Id} />,
        Id,
        7000
      );
      navigate("/admin-leave-application");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access (e.g., redirect to login)
      } else {
        const errorMessage =
          error.response?.data?.message || "An error occurred.";
        toast.open(<ErrorToast errorMsg={errorMessage} id={Id} />, Id, 7000);
      }
    }
  };

  const handleReject = async () => {
    try {
      const response = await axios.get(`leave-application-rejected/${Id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = response.data;
      toast.open(
        <SuccessToast successMsg={responseData.message} id={Id} />,
        Id,
        7000
      );
      navigate("/admin-leave-application");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred.";
      toast.open(<ErrorToast errorMsg={errorMessage} id={Id} />, Id, 7000);
    }
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Leave Application
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />

          {loading ? (
            <PageLoading />
          ) : (
            <div className=" p-4 rounded-lg">
              <table className="max-w-full border-collapse border rounded-lg border-gray-300 mt-4">
                <tbody className="rounded-lg">
                  {Object.keys(leaveApplicationDetails).map(
                    (key) =>
                      key !== "created_at" &&
                      key !== "updated_at" &&
                      key !== "user_id" &&
                      key !== "id" && (
                        <tr key={key}>
                          <td className="border p-2 font-semibold">
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </td>
                          <td
                            className={`w-full border p-2 ${
                              leaveApplicationDetails[key] === null
                                ? "italic"
                                : ""
                            }`}
                          >
                            {leaveApplicationDetails[key] === null
                              ? "null"
                              : key === "description"
                              ? parse(leaveApplicationDetails[key])
                              : leaveApplicationDetails[key]}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>

              <div className="mt-5 flex gap-x-3">
                {leaveApplicationDetails.status !== "Pending" && (
                  <button
                    onClick={handlePending}
                    className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-blue-600 text-white hover:bg-blue-800"
                    } `}
                  >
                    <FastBackwardOutlined />
                    Pending
                  </button>
                )}
                {leaveApplicationDetails.status !== "Approved" && (
                  <button
                    onClick={handleApprove}
                    className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-green-600 text-white hover:bg-green-800"
                    } `}
                  >
                    <CheckOutlined />
                    Approve
                  </button>
                )}
                {leaveApplicationDetails.status !== "Rejected" && (
                  <button
                    onClick={handleReject}
                    className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                      isDarkmode
                        ? "bg-gray-700 text-white hover:bg-gray-900"
                        : "bg-secondary text-white hover:bg-red-800"
                    } `}
                  >
                    <CloseOutlined />
                    Reject
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AdminLeaveApplicationDetailsPage;
