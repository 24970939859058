// CreateDefinedNotification.js
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useDropzone } from "react-dropzone";
import { AiOutlineDownload } from "react-icons/ai";
import { DeleteOutlined } from "@ant-design/icons";
import "@react-pdf-viewer/core/lib/styles/index.css";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useToast } from "../../contexts/ToastContext";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../../components/ToastMsg/ErrorToast";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";

const CreateDefinedNotification = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [dropFile, setDropFile] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/defined-notification`);
  };

  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
    title: Yup.string().required("Title is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("text", values.text);
      if (dropFile !== null && dropFile !== "") {
        formData.append("photopath", dropFile);
      }

      console.log(formData.photopath);

      await axios.post("predefined-notification/create", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      navigate("/defined-notification");

      toast.open(
        <SuccessToast successMsg={`Notification added `} />,
        null,
        5000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error("Error :", error.message || error);
      console.error("Server response:", error.response?.data);
      toast.open(<ErrorToast successMsg={error.message} />, 5000);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setDropFile(file);
      }
    },
  });
  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-800 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Add Defined Notification
            </h2>
            <button
              onClick={handleBack}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          <Formik
            initialValues={{
              title: "",
              text: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="mt-4">
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Title
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="text"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Text
                </label>
                <Field
                  type="text"
                  id="text"
                  name="text"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="text"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="text"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Add Image {`( Optional )`}
                </label>
                <div
                  className={`mb-4 border-2 border-dashed border-gray-300 rounded-md p-8 text-center cursor-pointer transition duration-300 ${
                    isDragActive && "border-green-500"
                  }`}
                >
                  <div
                    {...getRootProps()}
                    className="flex flex-col items-center justify-center"
                  >
                    <label
                      className={`block text-sm font-semibold mb-2 ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Drag & Drop Image
                    </label>
                    <input {...getInputProps()} />
                    <p
                      className={`block text-sm font-semibold mb-2 ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      {isDragActive
                        ? "Drop the Image file here ..."
                        : "Drag drop a Image here, or click to select a file"}
                    </p>
                    <AiOutlineDownload
                      size={64}
                      className={`mt-4 text-2xl ${
                        isDarkmode ? "text-white" : " text-primary "
                      }`}
                    />
                  </div>
                </div>
              </div>
              {dropFile && (
                <div className="mb-4">
                  <label
                    className={`block text-sm font-semibold ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  >
                    Selected Image
                  </label>
                  <div className="w-32 h-32 overflow-hidden rounded-md relative group">
                    <img
                      src={URL.createObjectURL(dropFile)}
                      alt={`Selected - ${dropFile.name}`}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <DeleteOutlined
                        className="text-secondary text-2xl cursor-pointer hover:scale-125"
                        onClick={() => setDropFile(null)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-6">
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  }`}
                >
                  Add Notification
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default CreateDefinedNotification;
