import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const UpdateResourseForm2 = ({ onSubmit, isDarkmode, resourceData }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
    subject_id: Yup.number().required("Subject is required"),
    filepath: Yup.string().required("File path is required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          name: resourceData.name,
          priority: resourceData.priority,
          subject_id: resourceData.subject_id,
          filepath: resourceData.filepath,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="mt-4">
          <div className="mb-4">
            <label
              htmlFor="subject_id"
              className={`hidden text-sm font-semibold ${
                isDarkmode ? "text-white" : "text-gray-700"
              }`}
            >
              Subject Id
            </label>
            <Field
              type="number"
              id="subject_id"
              name="subject_id"
              className={`mt-1 p-2 hidden w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                isDarkmode
                  ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                  : "border-gray-300 bg-white text-gray-700"
              }`}
              disabled
            />
            <ErrorMessage
              name="subject_id"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="priority"
              className={`block text-sm font-semibold ${
                isDarkmode ? "text-white" : "text-gray-700"
              }`}
            >
              Priority
            </label>
            <Field
              type="number"
              id="priority"
              name="priority"
              className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                isDarkmode
                  ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                  : "border-gray-300 bg-white text-gray-700"
              }`}
            />
            <ErrorMessage
              name="priority"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="name"
              className={`block text-sm font-semibold ${
                isDarkmode ? "text-white" : "text-gray-700"
              }`}
            >
              Resource Name
            </label>
            <Field
              type="text"
              id="name"
              name="name"
              className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                isDarkmode
                  ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                  : "border-gray-300 bg-white text-gray-700"
              }`}
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="filepath"
              className={`block text-sm font-semibold ${
                isDarkmode ? "text-white" : "text-gray-700"
              }`}
            >
              Video Link
            </label>
            <Field
              type="text"
              id="filepath"
              name="filepath"
              className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                isDarkmode
                  ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                  : "border-gray-300 bg-white text-gray-700"
              }`}
            />
            <ErrorMessage
              name="filepath"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className={`px-4 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              }`}
            >
              Update
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default UpdateResourseForm2;
