// AskLeavePage.js
import React, { useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { Editor } from "@tinymce/tinymce-react";
import ErrorToast from "../../components/ToastMsg/ErrorToast";

const AskLeavePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const editorRef = useRef(null);

  const handleEditorChange = (content, formikProps) => {
    formikProps.setFieldValue("description", content);
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post("staff-leave-application", values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const responseData = response.data;
      navigate(`/leave-application`);

      toast.open(
        <SuccessToast
          successMsg={responseData.message}
          id={responseData.data.id}
        />,
        responseData.data.id,
        7000
      );
    } catch (error) {
      console.error("Error:", error.message || error.response?.data || error);

      toast.open(<ErrorToast errorMsg={error.data.message} />, null, 7000);
    }
  };

  const handleBack = () => {
    navigate(`/leave-application`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <>
            <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
              <h2 className="font-bold mt-1 text-4xl text-black-700">
                Ask Leave
              </h2>
              <button
                onClick={handleBack}
                className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                  isDarkmode
                    ? "bg-gray-700 text-white hover:bg-gray-900"
                    : "bg-primary text-white hover:bg-blue-800"
                } `}
              >
                <FaLongArrowAltLeft />
                Back
              </button>
            </div>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            <Formik
              initialValues={{
                subject: "",
                description: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <Form className="mt-4">
                  <div className="mb-4">
                    <label
                      htmlFor="subject"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Subject
                    </label>
                    <Field
                      type="text"
                      id="subject"
                      name="subject"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="description"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Description
                    </label>
                    <Editor
                      apiKey="6055t5rnuxos6590v4iu105aom77houkrf9a3wt3ouif38k5"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue="<p></p>"
                      init={{
                        height: 400,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "code",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                      }}
                      onEditorChange={(content, editor) => {
                        handleEditorChange(content, formikProps);
                      }}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-4 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      }`}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        </div>
      </section>
    </div>
  );
};

export default AskLeavePage;
