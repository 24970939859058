import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import Avatar from "react-avatar";
import { useAuth } from "../contexts/AuthContext";
import DefaultUser from "../images/DefaultUser.jpg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import PageLoading from "../components/Diagrams/PageLoading";
import { useNavigate } from "react-router-dom";

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedNotifications, setExpandedNotifications] = useState([]);
  const [filter, setFilter] = useState("All");
  const { accessToken, isDarkmode } = useAuth();
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllNotifications = async () => {
      try {
        const response = await axios.get(`notification`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setNotifications(responseData.data);
        setFilteredNotifications(responseData.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAllNotifications();
  }, [accessToken]);

  const updateNotificationStatus = async (notificationId) => {
    try {
      const response = await axios.post(
        `notification/change-status/${notificationId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  const toggleNotification = async (notificationId) => {
    if (!expandedNotifications.includes(notificationId)) {
      // Notification is being expanded for the first time
      try {
        await updateNotificationStatus(notificationId);
      } catch (error) {
        console.error("Error updating notification status:", error);
        return;
      }
    }

    // Toggle the expanded state
    setExpandedNotifications((prevExpanded) =>
      prevExpanded.includes(notificationId)
        ? prevExpanded.filter((id) => id !== notificationId)
        : [...prevExpanded, notificationId]
    );
  };

  // Function to filter notifications based on status
  const filterNotifications = (status) => {
    let filteredNotifications;
    if (status === "All") {
      // Reset to original list
      filteredNotifications = notifications;
    } else if (status === "Read") {
      filteredNotifications = notifications.filter(
        (notification) => notification.status === "Read"
      );
    } else if (status === "Unread") {
      filteredNotifications = notifications.filter(
        (notification) => notification.status === "Unread"
      );
    }

    setFilteredNotifications(filteredNotifications);
    setFilter(status);
  };

  const handleNewNotification = () => {
    navigate("/notification/create");
  };

  const handleGotoDefinedNotification = () => {
    navigate("/defined-notification/");
  };

  return (
    <>
      <div className="flex ">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-800 text-white" : "bg-gray-100"
            }`}
          >
            <div className="flex justify-between mx-1 ">
              <h2
                className={`font-bold mt-1 text-4xl ${
                  isDarkmode ? "text-white" : "text-black"
                } `}
              >
                Notifications
              </h2>
              <div className="flex gap-x-2">
                <button
                  className={`px-4 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                  onClick={handleNewNotification}
                >
                  Custom Notification
                </button>
                <button
                  className={`px-4 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                  onClick={handleGotoDefinedNotification}
                >
                  Defined Notification
                </button>
              </div>
            </div>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            {loading ? (
              <PageLoading />
            ) : (
              <div className="m-4">
                <div className="flex items-center gap-x-2 mt-4 mb-2">
                  <button
                    className={`rounded  px-2 py-1 text-white hover:bg-primary transition-colors duration-300 ${
                      filter === "All" ? "bg-primary" : "bg-primary-dark"
                    }`}
                    onClick={() => filterNotifications("All")}
                  >
                    All
                  </button>
                  <button
                    className={`rounded  px-2 py-1 text-white hover:bg-primary transition-colors duration-300 ${
                      filter === "Unread" ? "bg-primary" : "bg-primary-dark"
                    }`}
                    onClick={() => filterNotifications("Unread")}
                  >
                    Unread
                  </button>
                  <button
                    className={`rounded px-2 py-1 text-white hover:bg-primary transition-colors duration-300 ${
                      filter === "Read" ? "bg-primary" : "bg-primary-dark "
                    }`}
                    onClick={() => filterNotifications("Read")}
                  >
                    Read
                  </button>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <tbody>
                      {filteredNotifications.map((notification) => (
                        <tr
                          key={notification.id}
                          className="border-b border-gray-400"
                        >
                          <td>
                            <Avatar
                              round
                              size="40"
                              src={
                                notification.user_profile
                                  ? `https://bitmap.bpnpdl.me/image/user/${notification.user_profile}`
                                  : DefaultUser
                              }
                              className="mr-4"
                            />
                          </td>
                          <td className="py-4 w-full">
                            <div className="flex justify-between items-center gap-x-1">
                              <h2
                                className={`w-full text-lg font-semibold mb-1 hover:cursor-pointer ${
                                  isDarkmode
                                    ? "text-white hover:text-primary"
                                    : "text-primary hover:text-primary-dark"
                                }`}
                                onClick={() =>
                                  toggleNotification(notification.id)
                                }
                              >
                                {notification.title}
                              </h2>
                              {expandedNotifications.includes(
                                notification.id
                              ) ? (
                                <FaChevronUp
                                  size={25}
                                  onClick={() =>
                                    toggleNotification(notification.id)
                                  }
                                />
                              ) : (
                                <FaChevronDown
                                  size={25}
                                  onClick={() =>
                                    toggleNotification(notification.id)
                                  }
                                />
                              )}
                            </div>
                            {expandedNotifications.includes(
                              notification.id
                            ) && (
                              <p
                                className={`${
                                  isDarkmode ? "text-white" : "text-gray-800"
                                } mb-2`}
                              >
                                {notification.text}
                              </p>
                            )}
                            <span
                              className={`text-xs font-bold ${
                                isDarkmode ? "text-white" : "text-gray-600"
                              }`}
                            >
                              {notification.time} {notification.date}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default NotificationPage;
