import React from "react";

const SentToStaffModal = ({ onSent, onHide, notificationId }) => {
  const handleSent = (e) => {
    e.preventDefault();
    onSent(notificationId);
    onHide();
  };

  return (
    <div className="fixed z-50 left-0 top-0 right-0 bottom-0 bg-opacity-50 backdrop-blur-sm bg-gray-400">
      <div className="flex h-full justify-center items-center">
        <div className="bg-white p-4 rounded-lg">
          <form onSubmit={handleSent}>
            <p className="text-2xl">
              Are you sure you want to send notification to all staffs?
            </p>
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="bg-blue-600 text-white px-4 py-2 mx-2 rounded-lg cursor-pointer"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={onHide}
                className="bg-red-600 text-white px-4 py-2 mx-2 rounded-lg cursor-pointer"
              >
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SentToStaffModal;
