// CreateCoursePage.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import PageLoading from "../../components/Diagrams/PageLoading";

const CreateCoursePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [countPriority, setCountPriority] = useState("");

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get("/priority-courses", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        const parsedCountPriority = parseInt(responseData.data, 10);
        setCountPriority(isNaN(parsedCountPriority) ? "" : parsedCountPriority);
      } catch (error) {
        console.error(
          "Error fetching course data:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchCourseData();
  }, [accessToken]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to create a new course
      const response = await axios.post("/courses/create", values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the course details page
      const newCourse = response.data.data;

      navigate(`/courses`);

      toast.open(
        <SuccessToast
          successMsg={`Course with name ${newCourse.name} added `}
          id={newCourse.id}
        />,
        newCourse.id,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error creating course:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/courses`);
  };

  // Only render the form when countPriority is available
  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {countPriority !== "" ? ( // Check if countPriority is available
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Add Course
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  name: "",
                  priority: countPriority,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="mt-4">
                  <div className="mb-4">
                    <div className="mb-4">
                      <label
                        htmlFor="priority"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Priority
                      </label>
                      <Field
                        type="number"
                        id="priority"
                        name="priority"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="priority"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <label
                      htmlFor="name"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Course Name
                    </label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-4 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      }`}
                    >
                      Add Course
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default CreateCoursePage;
