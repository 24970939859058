import React from "react";

const StaffRejectModal = ({
  formik,
  showRejectionForm,
  closeRejectionForm,
}) => {
  return (
    <div className="fixed z-50 left-0 top-0 right-0 bottom-0 bg-opacity-50 backdrop-blur-sm bg-gray-700">
      <div className="flex h-full justify-center items-center">
        <div className="bg-white p-4 rounded-lg">
          <form
            onSubmit={formik.handleSubmit}
            className={`rejection-form ${
              showRejectionForm ? "visible" : "hidden"
            }`}
          >
            <div>
              <label htmlFor="remarks">Remarks:</label>
              <textarea
                type="text"
                id="remarks"
                name="remarks"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.remarks}
                className="w-full p-2 border rounded h-32"
              />
              {formik.touched.remarks && formik.errors.remarks ? (
                <div className="text-red-500">{formik.errors.remarks}</div>
              ) : null}

              <div className="mt-4 flex justify-between">
                <button
                  type="submit"
                  className="bg-primary text-white py-2 px-4 rounded hover:bg-blue-800 focus:outline-none focus:ring focus:border-blue-300"
                >
                  Submit
                </button>

                <button
                  type="button"
                  onClick={closeRejectionForm}
                  className="bg-secondary text-white py-2 px-4 rounded hover:bg-red-800 focus:outline-none focus:ring focus:border-gray-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StaffRejectModal;
