// CoursePage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../api/axios";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import DataTable from "../components/Diagrams/DataTable";
import DeleteModal from "../components/DeleteModel/DeleteModal";
import { useAuth } from "../contexts/AuthContext";
import { FaEye, FaEdit, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import { useToast } from "../contexts/ToastContext";
import PageLoading from "../components/Diagrams/PageLoading";

const CoursePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [courses, setCourses] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCourse, setDeleteCourse] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get("admin-courses", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setCourses(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching course data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCourseData();
  }, [accessToken]);

  const handleManage = useCallback(
    (row) => {
      navigate(`/courses/${row.id}/subjects`);
    },
    [navigate]
  );

  const handleView = useCallback(
    (row) => {
      navigate(`/courses/${row.id}`);
    },
    [navigate]
  );

  const handleEdit = useCallback(
    (row) => {
      navigate(`/courses/update/${row.id}`);
    },
    [navigate]
  );

  const handleDeleteModal = useCallback((row) => {
    setDeleteCourse(row.id);
    setDeleteModal(true);
  }, []);

  const toggleStatus = useCallback(
    async (courseId) => {
      try {
        // Make an API request to update the status
        await axios.get(`toggle-course-status/${courseId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setCourses((prevCourses) => {
          return prevCourses.map((course) => {
            if (course.id === courseId) {
              return {
                ...course,
                status: course.status === "show" ? "hide" : "show",
              };
            }
            return course;
          });
        });

        toast.open(
          <SuccessToast successMsg={`Course status changed`} id={courseId} />,
          courseId,
          7000
        );
      } catch (error) {
        // Handle error, e.g., show an error toast
        console.error(
          `Error updating course status:`,
          error.message || error.response?.data || error
        );

        // Show an error message
        toast.open(
          <ErrorToast errorMsg={`Error updating course status`} />,
          null,
          7000
        );
      }
    },
    [accessToken, setCourses, toast]
  );

  const handleDelete = async (courseId) => {
    try {
      console.log("Deleting Course:", courseId);
      await axios.delete(`courses/delete/${courseId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(`Course with ID ${courseId} deleted successfully`);

      toast.open(
        <SuccessToast
          successMsg={`Course with ID ${courseId} deleted successfully`}
          id={courseId}
        />,
        courseId,
        7000
      );

      // Update the UI after deletion
      const updated = courses.filter((courses) => courses.id !== courseId);
      setCourses(updated);
    } catch (error) {
      console.error("Error deleting the course:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setDeleteCourse(null);
      setDeleteModal(false);
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Order", accessor: "priority" },
      { Header: "Name", accessor: "name" },
      {
        Header: "Hide/Show",
        Cell: ({ row }) => (
          <button
            className={`w-10 h-5 rounded-full ${
              isDarkmode ? "bg-gray-500 " : "bg-gray-200"
            } flex items-center transition duration-300 focus:outline-none shadow`}
            onClick={() => toggleStatus(row.original.id)}
          >
            <div
              id="switch-toggle"
              className={`w-6 h-6 relative rounded-full transition duration-500 transform p-1 text-white flex items-center justify-center ${
                row.original.status === "show"
                  ? "bg-green-500 translate-x-full"
                  : "bg-red-500 -translate-x-2"
              }`}
            >
              {row.original.status === "show" ? (
                <FaEye size={15} color="#FFF" />
              ) : (
                <FaEyeSlash size={15} color="#FFF" />
              )}
            </div>
          </button>
        ),
      },
      {
        Header: "Manage Resources",
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <button
              className={`px-2 py-1 rounded-lg shadow-md hover:scale-110 ${
                isDarkmode
                  ? "bg-gray-500 text-white hover:bg-gray-600"
                  : "bg-primary text-white hover:bg-blue-600"
              }`}
              onClick={() => handleManage(row.original)}
            >
              Manage
            </button>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              className="hover:scale-110 px-2"
              onClick={() => handleView(row.original)}
            >
              <FaEye size={25} className="text-green-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleEdit(row.original)}
            >
              <FaEdit size={25} className="text-blue-600" />
            </button>
            <button
              className="hover:scale-110"
              onClick={() => handleDeleteModal(row.original)}
            >
              <MdDelete size={25} s className="text-red-500" />
            </button>
          </div>
        ),
      },
    ],
    [
      handleView,
      handleEdit,
      handleDeleteModal,
      isDarkmode,
      handleManage,
      toggleStatus,
    ]
  );

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onDelete={() => handleDelete(deleteCourse)}
          onHide={() => setDeleteModal(false)}
          deleteId={deleteCourse || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <h2 className="font-bold mt-1 text-4xl text-black-700">Courses</h2>

            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                <DataTable
                  columns={columns}
                  data={Object.values(courses)}
                  addButtonLink="/courses/create"
                  addButtonLabel="Add New Course"
                  editButtonLink="/EditUser"
                  viewButtonLink="/ViewUser"
                  deleteButtonLink="/DeleteUser"
                  handleView={handleView}
                  handleEdit={handleEdit}
                  handleDelete={handleDeleteModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default CoursePage;
