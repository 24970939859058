import { Modal, Form, Input, Button } from "antd";
import React, { useState } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../contexts/AuthContext";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../ToastMsg/SuccessToast";
import ErrorToast from "../ToastMsg/ErrorToast";

const ChangePasswordModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { accessToken } = useAuth();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      await axios.post(`change-password`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Password updated successfully!");
      toast.open(
        <SuccessToast successMsg={`Password updated successfully!`} />,
        null,
        4000
      );
      onClose();
    } catch (error) {
      console.error("Error updating password:", error);
      toast.open(
        <ErrorToast
          errorMsg={error.response?.data?.message || "Error updating password!"}
        />,
        null,
        4000
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Change Password"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-700 text-white"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={handleSubmit}
          loading={loading}
          className="bg-blue-500 hover:bg-blue-700 text-white"
        >
          Submit
        </Button>,
      ]}
      className="rounded-lg g-opacity-50 backdrop-blur-sm"
      centered
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="old_password"
          label="Old Password"
          rules={[
            {
              required: true,
              message: "Please input your old password!",
            },
          ]}
        >
          <Input.Password className="rounded-md" />
        </Form.Item>
        <Form.Item
          name="new_password"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input.Password className="rounded-md" />
        </Form.Item>
        <Form.Item
          name="new_password_confirmation"
          label="Confirm New Password"
          dependencies={["new_password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password className="rounded-md" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
