// UpdateBasicInfoForm.js
import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSpring, animated } from "react-spring";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const UpdateBasicInfoForm = ({
  initialValues,
  onSubmit,
  isDarkmode,
  onSkip,
  photopath,
}) => {
  const [previewImage, setPreviewImage] = useState(null);

  let pp = "";
  if (photopath !== null) {
    pp = `https://bitmap.pramodchhetri.com.np/image/user/${photopath}`;
  }

  useEffect(() => {
    setPreviewImage(pp);
  }, [pp]);

  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    middle_name: Yup.string().max(
      255,
      "Middle Name must be at most 255 characters"
    ),
    last_name: Yup.string()
      .required("Last Name is required")
      .max(255, "Last Name must be at most 255 characters"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required")
      .max(255, "Email must be at most 255 characters"),
    password: Yup.string().min(6, "Password must be at least 6 characters"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    phone_no: Yup.string().max(
      20,
      "Phone Number must be at most 20 characters"
    ),
    photopath: Yup.mixed()
      .nullable()
      .test("fileSize", "File size is too large", (value) => {
        if (!value) return true;
        return value && value.size <= 2048 * 1024; // 2048 KB
      })
      .test("fileType", "Invalid file format", (value) => {
        if (!value) return true;
        return ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          value.type
        );
      }),
  });

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];

    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setFieldValue("photopath", file);
    } else {
      setPreviewImage(null);
      setFieldValue("photopath", null);
    }
  };

  const handlePreviewClick = () => {
    setPreviewModalOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewModalOpen(false);
  };

  const handleDeleteClick = (setFieldValue) => {
    setPreviewImage(null);
    setFieldValue("photopath", null);
  };

  const modalAnimation = useSpring({
    opacity: isPreviewModalOpen ? 1 : 0,
    transform: isPreviewModalOpen ? "translateY(0)" : "translateY(-100%)",
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ setFieldValue }) => (
          <Form className="mt-4 flex flex-wrap">
            <div className="relative group rounded-full overflow-hidden w-48 h-48 mt-6 mb-12 mx-auto bg-gray-200 hover:border-blue-300 hover:border-2 ">
              <input
                type="file"
                id="photopath"
                name="photopath"
                accept="image/*"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                onChange={(event) => handleFileChange(event, setFieldValue)}
              />
              {previewImage ? (
                <div className="w-full h-full relative flex ">
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="object-cover w-full h-full group-hover:opacity-75 transition-opacity duration-300 cursor-pointer"
                    onClick={handlePreviewClick}
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <EyeOutlined
                      className="text-primary text-2xl mr-2 cursor-pointer hover:scale-125"
                      onClick={handlePreviewClick}
                    />
                    <DeleteOutlined
                      className="text-secondary text-2xl cursor-pointer hover:scale-125"
                      onClick={() => handleDeleteClick(setFieldValue)}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center w-full h-full hover:border-blue-300 hover:border-2 ">
                  <PlusOutlined className="text-4xl text-gray-500" />
                  <div className="mt-2 text-gray-500">Upload Photo</div>
                </div>
              )}
            </div>

            {/* Error message */}
            <ErrorMessage
              name="photopath"
              component="div"
              className="text-red-500 text-sm mt-2"
            />

            <div className="w-full flex">
              <div className="mb-4 w-1/3 flex-1 gap-x-2 ">
                <label
                  htmlFor="first_name"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  First Name
                </label>
                <Field
                  type="text"
                  id="first_name"
                  name="first_name"
                  className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex-1 w-1/3 px-2">
                <label
                  htmlFor="middle_name"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Middle Name
                </label>
                <Field
                  type="text"
                  id="middle_name"
                  name="middle_name"
                  className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="middle_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex-1 w-1/3 pl-2">
                <label
                  htmlFor="last_name"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Last Name
                </label>
                <Field
                  type="text"
                  id="last_name"
                  name="last_name"
                  className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div className="flex w-full gap-x-4 items-center justify-center">
              <div className="mb-4 w-1/2">
                <label
                  htmlFor="email"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Email
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex-1 w-1/2">
                <label
                  htmlFor="gender"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Gender
                </label>
                <div className="flex items-center mt-1">
                  <Field
                    type="radio"
                    id="Male"
                    name="gender"
                    value="male"
                    className={`mr-2 ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  />
                  <label htmlFor="male">Male</label>
                  <Field
                    type="radio"
                    id="female"
                    name="gender"
                    value="Female"
                    className={`ml-4 mr-2 ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  />
                  <label htmlFor="female">Female</label>
                  <Field
                    type="radio"
                    id="other"
                    name="gender"
                    value="Other"
                    className={`ml-4 ${
                      isDarkmode ? "text-white" : "text-gray-700"
                    }`}
                  />
                  <label htmlFor="other">Other</label>
                </div>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div className="w-full flex gap-x-2">
              <div className="mb-4 w-1/2">
                <label
                  htmlFor="password"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Password
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 w-1/2">
                <label
                  htmlFor="password_confirmation"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Confirm Password
                </label>
                <Field
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="password_confirmation"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div className="w-full flex gap-x-2">
              <div className="mb-4 w-1/3">
                <label
                  htmlFor="dob"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Date of Birth
                </label>
                <Field
                  type="date"
                  id="dob"
                  name="dob"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="dob"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 w-1/3">
                <label
                  htmlFor="address"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Address
                </label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 w-1/3">
                <label
                  htmlFor="phone_no"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Phone Number
                </label>
                <Field
                  type="text"
                  id="phone_no"
                  name="phone_no"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="phone_no"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <div className="flex mt-6 gap-x-2">
              <button
                type="submit"
                className={`mt-6 px-6 py-3 rounded-full ${
                  isDarkmode
                    ? "bg-gray-700 text-white hover:bg-gray-900"
                    : "bg-primary text-white hover:bg-blue-800"
                }`}
              >
                Update Basic Information
              </button>
              <button
                className={`mt-6 px-6 py-3 rounded-full  ${
                  isDarkmode
                    ? "bg-gray-700 text-white hover:bg-gray-900"
                    : "bg-secondary text-white hover:bg-red-900"
                }`}
                onClick={() => {
                  onSkip(2);
                }}
              >
                Skip
              </button>
            </div>
            {isPreviewModalOpen && (
              <animated.div
                style={modalAnimation}
                className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-80"
              >
                <div className="relative bg-white rounded-lg p-4 w-96 h-96  shadow-lg">
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="object-cover w-full h-full rounded-lg mb-4 cursor-pointer"
                    onClick={handleClosePreview}
                  />
                  <button
                    className="absolute top-2 right-2 text-gray-700 text-xl cursor-pointer"
                    onClick={handleClosePreview}
                  >
                    <CloseOutlined />
                  </button>
                </div>
              </animated.div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateBasicInfoForm;
