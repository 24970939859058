import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useToast } from "../../contexts/ToastContext";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateTestDatePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const { testdateId } = useParams();
  const [testDateDetails, setTestDateDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchTestDateDetails = async () => {
      try {
        const response = await axios.get(`/test-date/${testdateId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setTestDateDetails(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching test date details:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTestDateDetails();
  }, [testdateId, accessToken]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    date: Yup.date().required("Date is required"),
    time: Yup.string().required("Time is required"),
    location: Yup.string().required("Location is required"),
  });

  const handleSubmit = async (values) => {
    try {
      // Make an API request to update the test date
      await axios.post(`test-date/update/${testdateId}`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      toast.open(
        <SuccessToast
          successMsg={`TestDate of id ${testdateId} updated.`}
          id={testdateId}
        />,
        testdateId,
        7000
      );
      // Handle success, e.g., show a success toast or navigate to the test dates page
      navigate(`/testdate`);

      // Optional: You can show a success toast or confirmation message
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error updating test date:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/testdate`);
  };

  // Only render the form when testDateDetails is available
  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {loading ? (
            <PageLoading />
          ) : (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Update Test Date
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  title: testDateDetails.title || "",
                  date: testDateDetails.date || "",
                  time: testDateDetails.time || "",
                  location: testDateDetails.location || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="mt-4">
                  <div className="mb-4">
                    <label
                      htmlFor="title"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Title
                    </label>
                    <Field
                      type="text"
                      id="title"
                      name="title"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="date"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Date
                    </label>
                    <Field
                      type="date"
                      id="date"
                      name="date"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="time"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Time
                    </label>
                    <Field
                      type="time"
                      id="time"
                      name="time"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="time"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="location"
                      className={`block text-sm font-semibold ${
                        isDarkmode ? "text-white" : "text-gray-700"
                      }`}
                    >
                      Location
                    </label>
                    <Field
                      type="text"
                      id="location"
                      name="location"
                      className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                        isDarkmode
                          ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                          : "border-gray-300 bg-white text-gray-700"
                      }`}
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={`px-4 py-2 rounded-lg shadow-md ${
                        isDarkmode
                          ? "bg-gray-700 text-white hover:bg-gray-900"
                          : "bg-primary text-white hover:bg-blue-800"
                      }`}
                    >
                      Update Test Date
                    </button>
                  </div>
                </Form>
              </Formik>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateTestDatePage;
