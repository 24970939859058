// CreateGuidePage.js
import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { Editor } from "@tinymce/tinymce-react";
import PageLoading from "../../components/Diagrams/PageLoading";

const CreateGuidePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [countPriority, setCountPriority] = useState("");
  const editorRef = useRef(null);

  const handleEditorChange = (content, formikProps) => {
    formikProps.setFieldValue("description", content);
  };

  useEffect(() => {
    const fetchGuideData = async () => {
      try {
        const response = await axios.get("priority-guide", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        const parsedCountPriority = parseInt(responseData.data, 10);
        setCountPriority(isNaN(parsedCountPriority) ? "" : parsedCountPriority);
      } catch (error) {
        console.error(
          "Error fetching guide data:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchGuideData();
  }, [accessToken]);

  const validationSchema = Yup.object().shape({
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post("/guide/create", values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const newGuide = response.data.data;
      navigate(`/guide`);

      toast.open(
        <SuccessToast
          successMsg={`Guide with priority ${newGuide.priority} added`}
          id={newGuide.id}
        />,
        newGuide.id,
        7000
      );
    } catch (error) {
      console.error(
        "Error creating guide:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/guide`);
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {countPriority !== "" ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Add Guide
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  priority: countPriority,
                  title: "",
                  description: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formikProps) => (
                  <Form className="mt-4">
                    <div className="mb-4">
                      <label
                        htmlFor="priority"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Priority
                      </label>
                      <Field
                        type="number"
                        id="priority"
                        name="priority"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                        disabled
                      />
                      <ErrorMessage
                        name="priority"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="title"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Title
                      </label>
                      <Field
                        type="text"
                        id="title"
                        name="title"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="description"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Description
                      </label>
                      <Editor
                        apiKey="6055t5rnuxos6590v4iu105aom77houkrf9a3wt3ouif38k5"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue="<p></p>"
                        init={{
                          height: 400,
                          menubar: false,
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | bold italic forecolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        }}
                        onEditorChange={(content, editor) => {
                          handleEditorChange(content, formikProps);
                        }}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mt-6">
                      <button
                        type="submit"
                        className={`px-4 py-2 rounded-lg shadow-md ${
                          isDarkmode
                            ? "bg-gray-700 text-white hover:bg-gray-900"
                            : "bg-primary text-white hover:bg-blue-800"
                        }`}
                      >
                        Add Guide
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default CreateGuidePage;
