import React, { useEffect, useRef, useState } from "react";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import { useAuth } from "../contexts/AuthContext";
import Chart from "chart.js/auto";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined, LoginOutlined } from "@ant-design/icons";
import { FaPersonWalking } from "react-icons/fa6";
import { useToast } from "../contexts/ToastContext";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import ClockInOutModal from "../components/ClockInOutModal/ClockInModal";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import ClockOutModal from "../components/ClockInOutModal/ClockOutModal";
import PageLoading from "../components/Diagrams/PageLoading";

const Dashboard = () => {
  const { isDarkmode, accessToken, userRole } = useAuth();
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [userCount, setUserCount] = useState("");
  const [classShiftsCount, setClassShiftsCount] = useState("");
  const [pendingAppointmentsCount, setPendingAppointmentsCount] = useState("");
  const [lastMonthUserData, setLastMonthUserData] = useState({});
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const [showLateEntryForm, setShowLateEntryForm] = useState(false);
  const [showEarlyExitForm, setShowEarlyExitForm] = useState(false);
  const [alreadyClockIn, setAlreadyClockIn] = useState(false);
  const [alreadyClockOut, setAlreadyClockOut] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/profile", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setUserData(response.data.data);
        setAlreadyClockIn(response.data.data.clock_in_status);
        setAlreadyClockOut(response.data.data.clock_out_status);
      } catch (error) {
        console.error(
          "Error fetching user data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchUserData();
    }
  }, [accessToken]);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get("get-students-count", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setUserCount(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching User count data:",
          error.message || error.response?.data || error
        );
      }
    };

    const fetchClassShiftCount = async () => {
      try {
        const response = await axios.get("get-class-shifts-count", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setClassShiftsCount(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching class shift count data:",
          error.message || error.response?.data || error
        );
      }
    };

    const fetchPendingAppointmentsCount = async () => {
      try {
        const response = await axios.get("get-pending-appontments-count", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setPendingAppointmentsCount(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching pending appointment count data:",
          error.message || error.response?.data || error
        );
      }
    };

    const fetchLastMonthUserData = async () => {
      try {
        const response = await axios.get("get-last-month-users", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        console.log(responseData.data);
        setLastMonthUserData(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching pending appointment count data:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchLastMonthUserData();
    fetchUserCount();
    fetchPendingAppointmentsCount();
    fetchClassShiftCount();
  }, [accessToken]);

  const data = {
    datasets: [
      {
        label: "Number of User Registered",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        data: lastMonthUserData,
      },
    ],
  };

  const script1 = document.createElement("script1");
  script1.src = "https://nepalipatro.com.np/np-widgets/nepalipatro.js";
  script1.id = "wiz1";
  script1.async = true;

  const script2 = document.createElement("script2");
  script2.src = "https://nepalipatro.com.np/np-widgets/nepalipatro.js";
  script2.id = "wiz2";
  script2.async = true;

  useEffect(() => {
    // Cleanup when the component is unmounted
    document.head.appendChild(script2);
    document.head.appendChild(script1);

    return () => {
      // Cleanup for scripts
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  });

  useEffect(() => {
    // Initialize Chart
    const myChart = new Chart(chartRef.current, {
      type: "line",
      data: data,
    });

    // Cleanup for Chart
    return () => {
      myChart.destroy();
    };
  });

  const gotoStudents = () => {
    navigate("/students");
  };

  const gotoClassShift = () => {
    navigate("/class-shift");
  };

  const gotoStaffAttendanceToday = () => {
    navigate("/staff-attendance-today");
  };

  const gotoAdminLeaveApplication = () => {
    navigate("/admin-leave-application");
  };

  const gotoAppointment = () => {
    navigate("/appointment");
  };

  const gotoDocumentApproval = () => {
    navigate("/document-approval");
  };

  const handleClockIn = () => {
    if (alreadyClockIn === true) {
      toast.open(
        <ErrorToast errorMsg={`Already Clock In`} id={userData.id} />,
        userData.id,
        7000
      );
      return;
    }
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentFormattedTime = `${currentHours}:${currentMinutes}`;

    if (userData.clock_in_time < currentFormattedTime) {
      setShowLateEntryForm(true);
    } else {
      axios.post("staff-attendance-clock-in", null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setAlreadyClockIn(true);

      toast.open(
        <SuccessToast successMsg={`Clock In Successfully`} id={userData.id} />,
        userData.id,
        7000
      );
    }
  };

  const closeLateEntryForm = () => {
    setShowLateEntryForm(false);
  };

  const closeEarlyExitForm = () => {
    setShowEarlyExitForm(false);
  };

  const formik1 = useFormik({
    initialValues: {
      late_clock_in_reason: "",
    },
    validationSchema: Yup.object().shape({
      late_clock_in_reason: Yup.string().required(
        "Late clock-in reason is required"
      ),
    }),
    onSubmit: async (values) => {
      console.log(values);
      try {
        await axios.post("staff-attendance-clock-in", values, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setAlreadyClockIn(true);
        toast.open(
          <SuccessToast
            successMsg={`Clock In Successfully`}
            id={userData.id}
          />,
          userData.id,
          7000
        );
      } catch (error) {
        console.error(
          "Error submitting late clock-in reason:",
          error.message || error.response?.data || error
        );
        toast.open(
          <ErrorToast errorMsg={error.message} id={userData.id} />,
          userData.id,
          7000
        );
      } finally {
        setShowLateEntryForm(false);
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      early_clock_out_reason: "",
    },
    validationSchema: Yup.object().shape({
      early_clock_out_reason: Yup.string().required(
        "Early clock-out reason is required"
      ),
    }),
    onSubmit: async (values) => {
      console.log(values);
      try {
        await axios.post("staff-attendance-clock-out", values, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setAlreadyClockOut(true);
        toast.open(
          <SuccessToast
            successMsg={`Clock Out Successfully`}
            id={userData.id}
          />,
          userData.id,
          7000
        );
      } catch (error) {
        console.error(
          "Error submitting clock Out reason:",
          error.message || error.response?.data || error
        );
        toast.open(
          <ErrorToast errorMsg={error.message} id={userData.id} />,
          userData.id,
          7000
        );
      } finally {
        setShowEarlyExitForm(false);
      }
    },
  });

  const handleClockOut = () => {
    if (alreadyClockIn === false) {
      toast.open(
        <ErrorToast errorMsg={`Clock In First!`} id={userData.id} />,
        userData.id,
        7000
      );
      return;
    }
    if (alreadyClockOut === true) {
      toast.open(
        <ErrorToast errorMsg={`Already Clock Out`} id={userData.id} />,
        userData.id,
        7000
      );
      return;
    }
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentFormattedTime = `${currentHours}:${currentMinutes}`;

    if (userData.clock_out_time > currentFormattedTime) {
      setShowEarlyExitForm(true);
    } else {
      axios.post("staff-attendance-clock-out", null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setAlreadyClockOut(true);
      toast.open(
        <SuccessToast successMsg={`Clock Out Successfully`} id={userData.id} />,
        userData.id,
        7000
      );
    }
  };

  const handleLeaveApplication = () => {
    navigate("/leave-application/ask-leave");
  };

  return (
    <>
      {showLateEntryForm && (
        <ClockInOutModal
          formik={formik1}
          showLateEntryForm={showLateEntryForm}
          closeLateEntryForm={closeLateEntryForm}
          isDarkmode={isDarkmode}
          Field={Field}
        />
      )}
      {showEarlyExitForm && (
        <ClockOutModal
          formik={formik2}
          showEarlyExitForm={showEarlyExitForm}
          closeEarlyExitForm={closeEarlyExitForm}
          isDarkmode={isDarkmode}
          Field={Field}
        />
      )}
      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col ">
          <AdminNavbar />

          {loading ? (
            <div
              className={`flex w-full h-full justify-center items-center ${
                isDarkmode ? "bg-gray-500" : "bg-gray-100"
              }`}
            >
              <PageLoading />
            </div>
          ) : (
            <div
              className={`flex-1  ${
                isDarkmode ? "bg-gray-800 text-white" : "bg-gray-100"
              }`}
            >
              <div className="flex ">
                {/* Chart taking up 2/3 of the width */}
                <div className="w-3/4 m-5">
                  <h2 className="font-bold mt-1 text-4xl text-black-700">
                    Welcome to Bitmap IT Solution
                  </h2>

                  <hr
                    className={`h-0.5 mt-2 border-0 ${
                      isDarkmode ? "bg-gray-900" : "bg-red-500"
                    }`}
                  />
                  <div className="m-5 max-w-full ">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                      <div className="bg-primary text-white p-6 rounded-lg">
                        <button
                          className="hover:underline"
                          onClick={gotoStudents}
                        >
                          <p className="font-bold text-lg">Bitmap Community</p>
                        </button>
                        <p className="font-bold text-5xl">{userCount}</p>
                      </div>

                      <div className="bg-red-600 text-white p-6 rounded-lg">
                        <button
                          className="hover:underline"
                          onClick={gotoClassShift}
                        >
                          <p className="font-bold text-lg">Class Shift</p>
                        </button>
                        <p className="font-bold text-5xl">{classShiftsCount}</p>
                      </div>

                      <div className="bg-green-600 text-white p-6 rounded-lg">
                        <button
                          className="hover:underline"
                          onClick={gotoAppointment}
                        >
                          <p className="font-bold text-lg">
                            Pending Appointments
                          </p>
                        </button>
                        <p className="font-bold text-5xl">
                          {pendingAppointmentsCount}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mx-2 max-w-full">
                    <div className="bg-white p-8 rounded-lg shadow-md">
                      <canvas ref={chartRef}></canvas>
                    </div>
                  </div>
                </div>

                {/* Widgets taking up 1/3 of the width */}
                <div className="w-1/4">
                  {userRole === "Staff" && (
                    <div className="flex flex-col gap-y-3 m-5">
                      <button
                        onClick={handleClockIn}
                        className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-primary to-blue-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-blue-300"
                      >
                        <LoginOutlined />
                        <span>Clock In</span>
                      </button>
                      <button
                        onClick={handleClockOut}
                        className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-green-300"
                      >
                        <LogoutOutlined />
                        <span>Clock Out</span>
                      </button>
                      <button
                        onClick={handleLeaveApplication}
                        className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-orange-500 to-orange-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-orange-300"
                      >
                        <FaPersonWalking />
                        <span>Ask Leave</span>
                      </button>
                    </div>
                  )}
                  {userRole === "Admin" && (
                    <div className="flex flex-col gap-y-3 m-5">
                      <button
                        onClick={gotoStaffAttendanceToday}
                        className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-primary to-blue-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-blue-300"
                      >
                        <LoginOutlined />
                        <span>Today's Attendance</span>
                      </button>
                      <button
                        onClick={gotoAdminLeaveApplication}
                        className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-green-300"
                      >
                        <LogoutOutlined />
                        <span>Leave Application</span>
                      </button>
                      <button
                        onClick={gotoDocumentApproval}
                        className="flex items-center gap-x-2 px-6 py-3 bg-gradient-to-r from-orange-500 to-orange-700 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring focus:border-orange-300"
                      >
                        <FaPersonWalking />
                        <span>Document Approval</span>
                      </button>
                    </div>
                  )}
                  <div className=" mr-2">
                    <div
                      id="np_widget_wiz1"
                      widget="day-sm"
                      className="w-full my-2"
                    ></div>
                    <div
                      id="np_widget_wiz2"
                      widget="holidays"
                      className="h-5"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default Dashboard;
