// UpdateStudentDocumentForm.js
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const UpdateStudentDocumentForm = ({
  onSubmit,
  isDarkmode,
  onBack,
  studentDocs,
}) => {
  const navigate = useNavigate();

  const handleSkip = () => {
    navigate("/students");
  };

  const validationSchema = Yup.object().shape({});

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={Object.keys(studentDocs).reduce((acc, key) => {
          const documentInfo = studentDocs[key];
          const documentName = documentInfo.name || "";
          acc[documentName] = "";
          return acc;
        }, {})}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form className="mt-4 flex flex-wrap" encType="multipart/form-data">
          {Object.keys(studentDocs).map((key) => {
            const documentInfo = studentDocs[key];

            const fileName = documentInfo.name.replace(/_/g, " ");

            return (
              <div className="w-full" key={documentInfo.name}>
                <label
                  htmlFor={documentInfo.name}
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  {fileName}
                </label>
                <Field
                  type="file"
                  id={documentInfo.name}
                  name={documentInfo.name}
                  className={`mt-1 p-2 block w-full rounded-md focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name={documentInfo.name}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            );
          })}

          <div className="flex mt-6 gap-x-2">
            <button
              className={`mt-6 px-6 py-3 rounded-full  ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-green-600 text-white hover:bg-green-800"
              }`}
              onClick={() => {
                onBack(2);
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className={`mt-6 px-6 py-3 rounded-full ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              }`}
            >
              Update Documents
            </button>
            <button
              className={`mt-6 px-6 py-3 rounded-full  ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-secondary text-white hover:bg-red-900"
              }`}
              onClick={handleSkip}
            >
              Skip
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default UpdateStudentDocumentForm;
