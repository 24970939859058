// CreateScorePage.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";

const CreateScorePage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  // Define the state for storing course data
  const [courseData, setCourseData] = useState([]);

  // Fetch course data from /courses
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get("/courses", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setCourseData(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching course data:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchCourseData();
  }, [accessToken]);

  // Define the validation schema
  const validationSchema = Yup.object().shape({
    user_id: Yup.number()
      .required("User ID is required")
      .positive("User ID must be a positive number"),
    course_name: Yup.string().required("Course is required"),
    listening: Yup.number()
      .required("Listening score is required")
      .min(0, "Score must be at least 0"),
    reading: Yup.number()
      .required("Reading score is required")
      .min(0, "Score must be at least 0"),
    speaking: Yup.number()
      .required("Speaking score is required")
      .min(0, "Score must be at least 0"),
    writing: Yup.number()
      .required("Writing score is required")
      .min(0, "Score must be at least 0"),
    overall_score: Yup.number()
      .required("Overall score is required")
      .min(0, "Score must be at least 0"),
    test_date: Yup.date().required("Test date is required"),
  });

  // Handle form submission
  const handleSubmit = async (values) => {
    try {
      // Make an API request to create a new score
      const response = await axios.post("/score/create", values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Handle success, e.g., show a success toast or navigate to the scores page
      const newScore = response.data.data;

      navigate(`/score`);

      toast.open(
        <SuccessToast successMsg={`Score  added`} id={newScore.id} />,
        newScore.id,
        7000
      );
    } catch (error) {
      // Handle error, e.g., show an error toast
      console.error(
        "Error creating score:",
        error.message || error.response?.data || error
      );
    }
  };

  // Render the form
  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
            <h2 className="font-bold mt-1 text-4xl text-black-700">
              Add Score
            </h2>
            <button
              onClick={() => navigate(`/scores`)}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                isDarkmode
                  ? "bg-gray-700 text-white hover:bg-gray-900"
                  : "bg-primary text-white hover:bg-blue-800"
              } `}
            >
              <FaLongArrowAltLeft />
              Back
            </button>
          </div>
          <hr
            className={`h-0.5 mt-1 border-0 ${
              isDarkmode ? "bg-gray-900" : "bg-red-500"
            }`}
          />
          <Formik
            initialValues={{
              user_id: "",
              course_name: "",
              listening: "",
              reading: "",
              speaking: "",
              writing: "",
              overall_score: "",
              test_date: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="mt-4">
              {/* User ID */}
              <div className="mb-4">
                <label
                  htmlFor="user_id"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  User ID
                </label>
                <Field
                  type="number"
                  id="user_id"
                  name="user_id"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="user_id"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Course (as a select field) */}
              <div className="mb-4">
                <label
                  htmlFor="course_name"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Course
                </label>
                <Field
                  as="select"
                  id="course_name"
                  name="course_name"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                >
                  <option value="" disabled>
                    Select a course
                  </option>
                  {courseData.map((course) => (
                    <option key={course.id} value={course.name}>
                      {course.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="course_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Listening */}
              <div className="mb-4">
                <label
                  htmlFor="listening"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Listening
                </label>
                <Field
                  type="number"
                  id="listening"
                  name="listening"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="listening"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Speaking */}
              <div className="mb-4">
                <label
                  htmlFor="speaking"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Speaking
                </label>
                <Field
                  type="number"
                  id="speaking"
                  name="speaking"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="speaking"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Writing */}
              <div className="mb-4">
                <label
                  htmlFor="writing"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Writing
                </label>
                <Field
                  type="number"
                  id="writing"
                  name="writing"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="writing"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Reading */}
              <div className="mb-4">
                <label
                  htmlFor="reading"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Reading
                </label>
                <Field
                  type="number"
                  id="reading"
                  name="reading"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="reading"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Overall Score */}
              <div className="mb-4">
                <label
                  htmlFor="overall_score"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Overall Score
                </label>
                <Field
                  type="number"
                  id="overall_score"
                  name="overall_score"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="overall_score"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Test Date */}
              <div className="mb-4">
                <label
                  htmlFor="test_date"
                  className={`block text-sm font-semibold ${
                    isDarkmode ? "text-white" : "text-gray-700"
                  }`}
                >
                  Test Date
                </label>
                <Field
                  type="date"
                  id="test_date"
                  name="test_date"
                  className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                    isDarkmode
                      ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                      : "border-gray-300 bg-white text-gray-700"
                  }`}
                />
                <ErrorMessage
                  name="test_date"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  className={`px-4 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  }`}
                >
                  Add Score
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default CreateScorePage;
