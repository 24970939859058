// Import necessary dependencies and components
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import { useNavigate, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useToast } from "../../contexts/ToastContext";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useDropzone } from "react-dropzone";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import { AiOutlineDownload } from "react-icons/ai";
import { DeleteOutlined } from "@ant-design/icons";
import PageLoading from "../../components/Diagrams/PageLoading";

const UpdateBannerPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { bannerId } = useParams();

  const [bannerData, setBannerData] = useState(null);
  const [droppedFile, setDroppedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`/banner/${bannerId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setBannerData(response.data.data);
      } catch (error) {
        console.error(
          "Error fetching banner data:",
          error.message || error.response?.data || error
        );
      }
    };

    fetchBannerData();
  }, [accessToken, bannerId]);

  const validationSchema = Yup.object().shape({
    priority: Yup.number()
      .required("Priority is required")
      .positive("Priority must be a positive number"),
  });

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("priority", values.priority);
      formData.append("photopath", droppedFile);

      const response = await axios.post(
        `/banner/update/${bannerId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedBanner = response.data.data;

      navigate(`/banner`);

      toast.open(
        <SuccessToast successMsg={`Banner updated`} id={updatedBanner.id} />,
        updatedBanner.id,
        7000
      );

      setDroppedFile(null); // Reset selected image file
    } catch (error) {
      console.error(
        "Error updating banner:",
        error.message || error.response?.data || error
      );
    }
  };

  const handleBack = () => {
    navigate(`/banner`);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file && file.type.startsWith("image/")) {
        setDroppedFile(file);

        const reader = new FileReader();
        reader.onload = (e) => {
          setPreviewImage(e.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        setDroppedFile(null);
        setPreviewImage(null);
      }
    },
  });

  return (
    <div className="flex">
      <AdminSidebar />
      <section className="flex-1 flex flex-col overflow-hidden">
        <AdminNavbar />
        <div
          className={`flex-1 p-3 overflow-hidden ${
            isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
          }`}
        >
          {bannerData ? (
            <>
              <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
                <h2 className="font-bold mt-1 text-4xl text-black-700">
                  Update Banner
                </h2>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
              <hr
                className={`h-0.5 mt-1 border-0 ${
                  isDarkmode ? "bg-gray-900" : "bg-red-500"
                }`}
              />
              <Formik
                initialValues={{
                  priority: bannerData.priority || "",
                  photopath: null,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form className="mt-4">
                    <div className="mb-4">
                      <label
                        htmlFor="priority"
                        className={`block text-sm font-semibold ${
                          isDarkmode ? "text-white" : "text-gray-700"
                        }`}
                      >
                        Priority
                      </label>
                      <Field
                        type="number"
                        id="priority"
                        name="priority"
                        className={`mt-1 p-2 block w-full rounded-md  focus:outline-none focus:ring focus:border-blue-500 ${
                          isDarkmode
                            ? "border-gray-700 bg-gray-700 text-white placeholder-white"
                            : "border-gray-300 bg-white text-gray-700"
                        }`}
                      />
                      <ErrorMessage
                        name="priority"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div
                      className={`mb-4 border-2 border-dashed border-gray-300 rounded-md p-8 text-center cursor-pointer transition duration-300 ${
                        isDragActive && "border-green-500"
                      }`}
                    >
                      <div
                        {...getRootProps()}
                        className="flex flex-col items-center justify-center"
                      >
                        <label
                          className={`block text-sm font-semibold mb-2 ${
                            isDarkmode ? "text-white" : "text-gray-700"
                          }`}
                        >
                          Drag & Drop Images
                        </label>
                        <input {...getInputProps()} />
                        <p
                          className={`block text-sm font-semibold mb-2 ${
                            isDarkmode ? "text-white" : "text-gray-700"
                          }`}
                        >
                          {isDragActive
                            ? "Drop the file here ..."
                            : "Drag drop an image here, or click to select an image"}
                        </p>
                        <AiOutlineDownload
                          size={64}
                          className={`mt-4 text-2xl ${
                            isDarkmode ? "text-white" : " text-primary "
                          }`}
                        />
                      </div>
                    </div>

                    {previewImage ? (
                      <div className="mb-4">
                        <label
                          className={`block text-sm font-semibold ${
                            isDarkmode ? "text-white" : "text-gray-700"
                          }`}
                        >
                          Image Preview
                        </label>
                        <div className="max-w-32 max-h-32 overflow-hidden rounded-md relative group">
                          <img
                            src={previewImage}
                            alt="Preview"
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <DeleteOutlined
                              className="text-secondary text-2xl cursor-pointer hover:scale-125"
                              onClick={() => setPreviewImage(null)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-4">
                        <label
                          className={`block text-sm font-semibold ${
                            isDarkmode ? "text-white" : "text-gray-700"
                          }`}
                        >
                          Previous Image
                        </label>
                        <div className="max-w-32 max-h-32 overflow-hidden rounded-md">
                          <img
                            src={`https://bitmap.pramodchhetri.com.np/image/banner/${bannerData.photopath}`}
                            alt="Preview"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </div>
                    )}

                    <div className="mt-6">
                      <button
                        type="submit"
                        className={`px-4 py-2 rounded-lg shadow-md ${
                          isDarkmode
                            ? "bg-gray-700 text-white hover:bg-gray-900"
                            : "bg-primary text-white hover:bg-blue-800"
                        }`}
                      >
                        Update Banner
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <PageLoading />
          )}
        </div>
      </section>
    </div>
  );
};

export default UpdateBannerPage;
