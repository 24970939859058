// StudentNotificationPage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "../../api/axios";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import AdminNavbar from "../../components/Navbar/AdminNavbar";
import DataTable from "../../components/Diagrams/DataTable";
import SentToSpecificStudentModal from "../../components/Modal/SentToSpecificStudentModal";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import SuccessToast from "../../components/ToastMsg/SuccessToast";
import { useToast } from "../../contexts/ToastContext";
import PageLoading from "../../components/Diagrams/PageLoading";
import { FaLongArrowAltLeft } from "react-icons/fa";
import SpecificStudentNotificationForm from "../../components/Form/SpecificStudentNotificationForm";

const StudentNotificationPage = () => {
  const { isDarkmode, accessToken } = useAuth();
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(true);
  const [sentToStaffModal, setSentToStaffModal] = useState(false);
  const [sentNotification, setSentNotification] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { studentId } = useParams();
  const [notificationType, setNotificationType] = useState(false);

  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        const response = await axios.get("/predefined-notification", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;
        setNotifications(responseData.data);
      } catch (error) {
        console.error(
          "Error fetching notification data:",
          error.message || error.response?.data || error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchNotificationData();
  }, [accessToken]);

  const handleSentToStaffModal = useCallback((row) => {
    setSentNotification(row.id);
    setSentToStaffModal(true);
  }, []);

  const handleBack = () => {
    navigate(`/notification`);
  };

  const toggleType = () => {
    setNotificationType(!notificationType);
  };

  const handleSentToStaff = async (notificationId) => {
    try {
      await axios.get(`student/${studentId}/notification/${notificationId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      toast.open(
        <SuccessToast
          successMsg={`Notification sent to student successfully!`}
          id={notificationId}
        />,
        notificationId,
        7000
      );
    } catch (error) {
      console.error("Error :", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "SN",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      { Header: "Title", accessor: "title" },
      {
        Header: "Text",
        accessor: "text",
        Cell: ({ row }) => (
          <div className="truncate line-clamp-3">
            <p className="whitespace-normal">{row.original.text}</p>
          </div>
        ),
      },
      { Header: "Photo", accessor: "photopath" },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="flex">
            <button
              onClick={() => handleSentToStaffModal(row.original)}
              className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md bg-primary text-white hover:bg-blue-800 `}
            >
              Send Notification
            </button>
          </div>
        ),
      },
    ],
    [handleSentToStaffModal]
  );

  return (
    <>
      {sentToStaffModal && (
        <SentToSpecificStudentModal
          onSent={() => handleSentToStaff(sentNotification)}
          onHide={() => setSentToStaffModal(false)}
          notificationId={sentNotification || null}
        />
      )}

      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <div className="mt-1 mr-4 mb-3 flex justify-between items-center">
              <h2 className="font-bold mt-1 text-4xl text-black-700">
                Notifications
              </h2>
              <div className="flex gap-x-2">
                <button
                  onClick={toggleType}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  {notificationType ? "Custom" : "Pre-defined"}
                </button>
                <button
                  onClick={handleBack}
                  className={`px-4 flex items-center justify-center gap-x-2 py-2 rounded-lg shadow-md ${
                    isDarkmode
                      ? "bg-gray-700 text-white hover:bg-gray-900"
                      : "bg-primary text-white hover:bg-blue-800"
                  } `}
                >
                  <FaLongArrowAltLeft />
                  Back
                </button>
              </div>
            </div>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />

            {loading ? (
              <PageLoading />
            ) : (
              <div className="py-1 sm:overflow-x-auto lg:overflow-hidden">
                {notificationType ? (
                  <DataTable
                    GlobalFilterNumber={4}
                    columns={columns}
                    data={Object.values(notifications)}
                    photoUrl="image/notifications"
                  />
                ) : (
                  <SpecificStudentNotificationForm
                    isDarkmode={isDarkmode}
                    accessToken={accessToken}
                    studentId={studentId}
                  />
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default StudentNotificationPage;
