import React, { useEffect, useState } from "react";
import AdminSidebar from "../components/Sidebar/AdminSidebar";
import AdminNavbar from "../components/Navbar/AdminNavbar";
import { useAuth } from "../contexts/AuthContext";
import axios from "../api/axios";
import { EyeOutlined, CloseOutlined } from "@ant-design/icons";
import DefaultUser from "../images/DefaultUser.jpg";
import { useSpring, animated } from "react-spring";
import { useToast } from "../contexts/ToastContext";
import ErrorToast from "../components/ToastMsg/ErrorToast";
import SuccessToast from "../components/ToastMsg/SuccessToast";
import ChangePasswordModal from "../components/Modal/ChangePasswordModal";
import ChangeProfilePicModal from "../components/Modal/ChangeProfilePicModal";

const ProfilePage = () => {
  const toast = useToast();
  const { isDarkmode, accessToken } = useAuth();
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changePictureModal, setChangePictureModal] = useState(false);
  const [previewImage, setPreviewImage] = useState(DefaultUser);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [basicEditMode, setBasicEditMode] = useState(false);
  const [additionalEditMode, setAdditionalEditMode] = useState(false);
  const [basicInfo, setBasicInfo] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    phone_no: "",
    address: "",
    dob: "",
    gender: "",
  });
  const [additionalInfo, setAdditionalInfo] = useState({
    qualification: "",
    father_name: "",
    mother_name: "",
    interested_country: "",
    interested_course: "",
    secondary_phone: "",
    secondary_address: "",
    reference: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/profile`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = response.data.data;

        if (responseData.photopath) {
          setPreviewImage(
            `https://bitmap.pramodchhetri.com.np/image/user/${responseData.photopath}`
          );
        }

        setBasicInfo({
          first_name: responseData.first_name || "",
          last_name: responseData.last_name || "",
          middle_name: responseData.middle_name || "",
          email: responseData.email || "",
          phone_no: responseData.phone_no || "",
          address: responseData.address || "",
          dob: responseData.dob || "",
          gender: responseData.gender || "",
        });

        setAdditionalInfo({
          qualification: responseData.qualification || "",
          father_name: responseData.father_name || "",
          mother_name: responseData.mother_name || "",
          interested_country: responseData.interested_country || "",
          interested_course: responseData.interested_course || "",
          secondary_phone: responseData.secondary_phone || "",
          secondary_address: responseData.secondary_address || "",
          reference: responseData.reference || "",
        });
      } catch (error) {
        console.error(
          `Error fetching data :`,
          error.message ||
            (error.response?.data && error.response.data.message) ||
            error
        );
      }
    };

    fetchUserData();
  }, [accessToken]);

  const handlePreviewClick = () => {
    setPreviewModalOpen(true);
    setBasicEditMode(false);
    setAdditionalEditMode(false);
  };

  const handleClosePreview = () => {
    setPreviewModalOpen(false);
  };

  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;
    setBasicInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleAdditionalInfoChange = (e) => {
    const { name, value } = e.target;
    setAdditionalInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleEditBasicInfo = () => {
    setBasicEditMode(true);
  };

  const toggleChangePasswordModal = () => {
    setChangePasswordModal(!changePasswordModal);
  };

  const toggleChangePictureModal = () => {
    setChangePictureModal(!changePasswordModal);
  };

  const handleEditAdditionalInfo = () => {
    setAdditionalEditMode(true);
  };

  const handleChangeInProfilePic = (newImageUrl) => {
    setPreviewImage(
      `https://bitmap.pramodchhetri.com.np/image/user/${newImageUrl}`
    );
  };

  const handleSaveBasicInfo = async () => {
    try {
      await axios.post(`profile-update`, basicInfo, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Basic info updated successfully!");
      toast.open(
        <SuccessToast successMsg={`Basic info updated successfully!`} />,
        null,
        7000
      );
      setBasicEditMode(false);
    } catch (error) {
      console.error("Error updating basic info:", error);
      toast.open(
        <ErrorToast errorMsg={`Error updating basic info!`} />,
        null,
        7000
      );
    }
  };

  const handleSaveAdditionalInfo = async () => {
    try {
      await axios.post(`/dashboard-additional-info-update`, additionalInfo, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Additional info updated successfully!");
      toast.open(
        <SuccessToast successMsg={`Additional info updated successfully!`} />,
        null,
        7000
      );
      setAdditionalEditMode(false);
    } catch (error) {
      console.error("Error updating additional info:", error);
      toast.open(
        <ErrorToast errorMsg={`Error updating additional info!`} />,
        null,
        7000
      );
    }
  };

  const modalAnimation = useSpring({
    opacity: isPreviewModalOpen ? 1 : 0,
    transform: isPreviewModalOpen ? "translateY(0)" : "translateY(-100%)",
  });

  return (
    <>
      {changePasswordModal && (
        <ChangePasswordModal
          visible={changePasswordModal}
          onClose={() => setChangePasswordModal(false)}
        />
      )}
      {changePictureModal && (
        <ChangeProfilePicModal
          visible={changePictureModal}
          onClose={() => setChangePictureModal(false)}
          handleChangeInProfilePic={handleChangeInProfilePic}
        />
      )}
      <div className="flex">
        <AdminSidebar />
        <section className="flex-1 flex flex-col overflow-hidden">
          <AdminNavbar />
          <div
            className={`flex-1 p-3 overflow-hidden ${
              isDarkmode ? "bg-gray-500 text-white" : "bg-gray-100"
            }`}
          >
            <h2 className="font-bold mt-1 text-4xl text-black-700">Profile</h2>
            <hr
              className={`h-0.5 mt-1 border-0 ${
                isDarkmode ? "bg-gray-900" : "bg-red-500"
              }`}
            />
            <div
              className={` shadow-md rounded-md p-6 my-6 ${
                isDarkmode ? "bg-gray-600" : "bg-white"
              }`}
            >
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold">Profile Picture</h3>
                <div className="flex gap-x-2">
                  <button
                    onClick={toggleChangePictureModal}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Edit Picture
                  </button>
                  <button
                    onClick={toggleChangePasswordModal}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Change Password
                  </button>
                </div>
              </div>
              <div className="relative w-48 h-48 rounded-full overflow-hidden mb-4">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="object-cover w-full h-full"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <EyeOutlined
                    onClick={handlePreviewClick}
                    className="text-green-600 text-2xl mr-2 cursor-pointer hover:scale-125"
                  />
                </div>
              </div>
            </div>
            <div
              className={`${
                isDarkmode ? "bg-gray-600" : "bg-white"
              } shadow-md rounded-md p-6 mb-6 `}
            >
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold">Basic Information</h3>
                {basicEditMode ? (
                  <button
                    onClick={handleSaveBasicInfo}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={handleEditBasicInfo}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Edit
                  </button>
                )}
              </div>
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium ">
                      First Name
                    </label>
                    {basicEditMode ? (
                      <input
                        type="text"
                        name="first_name"
                        value={basicInfo.first_name}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.first_name ? basicInfo.first_name : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Last Name
                    </label>
                    {basicEditMode ? (
                      <input
                        type="text"
                        name="last_name"
                        value={basicInfo.last_name}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.last_name ? basicInfo.last_name : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Middle Name
                    </label>
                    {basicEditMode ? (
                      <input
                        type="text"
                        name="middle_name"
                        value={basicInfo.middle_name}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.middle_name
                          ? basicInfo.middle_name
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">Email</label>
                    {basicEditMode ? (
                      <input
                        type="email"
                        name="email"
                        value={basicInfo.email}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.email ? basicInfo.email : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Phone Number
                    </label>
                    {basicEditMode ? (
                      <input
                        type="text"
                        name="phone_no"
                        value={basicInfo.phone_no}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.phone_no ? basicInfo.phone_no : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Address</label>
                    {basicEditMode ? (
                      <input
                        type="text"
                        name="address"
                        value={basicInfo.address}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.address ? basicInfo.address : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Date of Birth
                    </label>
                    {basicEditMode ? (
                      <input
                        type="date"
                        name="dob"
                        value={basicInfo.dob}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.dob ? basicInfo.dob : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Gender</label>
                    {basicEditMode ? (
                      <select
                        name="gender"
                        value={basicInfo.gender}
                        onChange={handleBasicInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {basicInfo.gender ? basicInfo.gender : " --- "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                isDarkmode ? "bg-gray-600" : "bg-white"
              } shadow-md rounded-md p-6 mb-6`}
            >
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold">
                  Additional Information
                </h3>
                {additionalEditMode ? (
                  <button
                    onClick={handleSaveAdditionalInfo}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={handleEditAdditionalInfo}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Edit
                  </button>
                )}
              </div>
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium ">
                      Qualification
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="qualification"
                        value={additionalInfo.qualification}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {additionalInfo.qualification
                          ? additionalInfo.qualification
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Father's Name
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="father_name"
                        value={additionalInfo.father_name}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {additionalInfo.father_name
                          ? additionalInfo.father_name
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Mother's Name
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="mother_name"
                        value={additionalInfo.mother_name}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {additionalInfo.mother_name
                          ? additionalInfo.mother_name
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Interested Country
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="interested_country"
                        value={additionalInfo.interested_country}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {additionalInfo.interested_country
                          ? additionalInfo.interested_country
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Interested Course
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="interested_course"
                        value={additionalInfo.interested_course}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {additionalInfo.interested_course
                          ? additionalInfo.interested_course
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Secondary Phone
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="secondary_phone"
                        value={additionalInfo.secondary_phone}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {additionalInfo.secondary_phone
                          ? additionalInfo.secondary_phone
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Secondary Address
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="secondary_address"
                        value={additionalInfo.secondary_address}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                        {additionalInfo.secondary_address
                          ? additionalInfo.secondary_address
                          : " --- "}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium ">
                      Reference
                    </label>
                    {additionalEditMode ? (
                      <input
                        type="text"
                        name="reference"
                        value={additionalInfo.reference}
                        onChange={handleAdditionalInfoChange}
                        className={`mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
                          isDarkmode ? "bg-gray-700 text-white" : "text-black"
                        }`}
                      />
                    ) : (
                      <div className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ">
                        {additionalInfo.reference
                          ? additionalInfo.reference
                          : " --- "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {isPreviewModalOpen && (
        <animated.div
          style={modalAnimation}
          className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-80"
        >
          <div className="relative bg-white rounded-lg p-4 w-96 h-96 shadow-lg">
            <img
              src={previewImage}
              alt="Preview"
              className="object-cover w-full h-full rounded-lg mb-4 cursor-pointer"
            />
            <button
              className="absolute top-2 right-2 text-gray-700 hover:text-red-600"
              onClick={handleClosePreview}
            >
              <CloseOutlined />
            </button>
          </div>
        </animated.div>
      )}
    </>
  );
};

export default ProfilePage;
