import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import axios from "../../api/axios";
import Avatar from "react-avatar";
import { useAuth } from "../../contexts/AuthContext";
import DefaultUser from "../../images/DefaultUser.jpg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NotificationSidebar = ({ placement, onClose, open, isDarkmode }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedNotifications, setExpandedNotifications] = useState([]);
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  const gotoAllNotification = () => {
    navigate("/notification");
  };

  useEffect(() => {
    const fetchAllNotifications = async () => {
      try {
        const response = await axios.get(`admin-sidebar-notification`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = await response.data;

        setNotifications(responseData.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAllNotifications();
  }, [accessToken]);

  const updateNotificationStatus = async (notificationId) => {
    try {
      const response = await axios.post(
        `notification/change-status/${notificationId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  const toggleNotification = async (notificationId) => {
    if (!expandedNotifications.includes(notificationId)) {
      // Notification is being expanded for the first time
      try {
        await updateNotificationStatus(notificationId);
      } catch (error) {
        console.error("Error updating notification status:", error);
        return;
      }
    }

    // Toggle the expanded state
    setExpandedNotifications((prevExpanded) =>
      prevExpanded.includes(notificationId)
        ? prevExpanded.filter((id) => id !== notificationId)
        : [...prevExpanded, notificationId]
    );
  };

  return (
    <>
      <Drawer
        title={
          <div className="flex items-center justify-between mt-2">
            <span
              className="text-2xl"
              style={{ color: isDarkmode ? "white" : "black" }}
            >
              Notification
            </span>
            <span
              className={` flex justify-end items-center  text-sm underline bold cursor-pointer ${
                isDarkmode
                  ? "text-white hover:text-blue-400"
                  : "text-black hover:text-primary"
              }`}
              onClick={gotoAllNotification}
            >
              See All
            </span>
          </div>
        }
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        style={{ backgroundColor: isDarkmode ? "#333" : "#f0f2f5" }}
        styles={{
          header: {
            borderBottom: `2px solid  ${isDarkmode ? "white" : "black"}`,
            margin: `0 1rem`,
          },
        }}
      >
        {loading && <p>Loading notifications...</p>}

        {!loading && notifications.length > 0 ? (
          <table className="w-full">
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.id} className="border-b border-gray-400">
                  <td>
                    {notification.user_profile ? (
                      <Avatar
                        round
                        size="40"
                        src={`https://bitmap.bpnpdl.me/image/user/${notification.user_profile}`}
                        className="mr-4"
                      />
                    ) : (
                      <Avatar
                        round
                        size="40"
                        src={DefaultUser}
                        className="mr-4"
                      />
                    )}
                  </td>
                  <td className="py-4">
                    <div className="flex justify-between items-center gap-x-1">
                      <h2
                        className={`text-lg font-semibold w-full items-start justify-start mb-1  hover:cursor-pointer ${
                          isDarkmode
                            ? "text-white hover:text-primary"
                            : "text-primary hover:text-primary-dark"
                        }`}
                        onClick={() => toggleNotification(notification.id)}
                      >
                        {notification.title}
                      </h2>
                      {expandedNotifications.includes(notification.id) ? (
                        <FaChevronUp
                          size={25}
                          className={isDarkmode ? "text-white" : "text-black"}
                          onClick={() => toggleNotification(notification.id)}
                        />
                      ) : (
                        <FaChevronDown
                          size={25}
                          className={isDarkmode ? "text-white" : "text-black"}
                          onClick={() => toggleNotification(notification.id)}
                        />
                      )}
                    </div>
                    {expandedNotifications.includes(notification.id) && (
                      <p
                        className={` ${
                          isDarkmode ? "text-white" : "text-gray-800"
                        } mb-2`}
                      >
                        {notification.text}
                      </p>
                    )}
                    <span
                      className={`text-xs bold ${
                        isDarkmode ? "text-white" : "text-black"
                      }`}
                    >
                      {notification.time}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p
            className={`text-center py-4 ${
              isDarkmode ? "text-white" : "text-black"
            } `}
          >
            No notifications to show!
          </p>
        )}
      </Drawer>
    </>
  );
};

export default NotificationSidebar;
